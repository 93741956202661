import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Container, createStyles, Dialog, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { langContext } from '../contexts/LangContext';
import { Languages } from './enums/Languages';
import { HelpSections } from './enums/HelpSections';
import HelpIcon from '@material-ui/icons/Help';


interface GHProps {
    open: boolean,
    section: HelpSections,
    setOpen: (open: boolean) => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '1em',
            minWidth: '15rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        closeBtn: {
            position: 'absolute',
            right: '0em',
            top: '0em'
        },
        textBoxTitle: {
            minWidth: '100%',
            textAlign: 'center',
            marginTop: '1em',
        },
        textBox: {
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '1em',
        },
        textBox2: {
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '3em',
        },
        imgBox: {
            marginTop: '1em',
            textAlign: 'center'
        },
        img: {
            maxWidth: '70%',
            border: '1px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '.5em'
        },
        iconTitle: {
            marginTop: '.25em',
            fontSize: '3rem',
            color: theme.palette.extra1.main
        },
    }),
);

function GenericHelpDialog(props: GHProps) {
    const classes = useStyles();
    const { lang } = useContext(langContext);

    return (
        <Dialog open={props.open} onClose={() => { props.setOpen(false); }}>
            <Container className={classes.root}>
                <IconButton className={classes.closeBtn} onClick={() => { props.setOpen(false) }}><CloseIcon /></IconButton>
                <HelpIcon className={classes.iconTitle}></HelpIcon>

                {props.section === HelpSections.SETTS_LANGUAGE && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Selecciona Tu Idioma</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Aqu&iacute; puedes elegir el idioma del sitio y la interface. Por ahora, est&aacute; disponible en Ingl&eacute;s y Espa&ntilde;ol.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_lang_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_LANGUAGE && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Select Your Language</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>You can select the language of the site and interface here. It's available in English and Spanish for now.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_lang_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_NOTIF_TYPES && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Tipos de Notificaci&oacute;n</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Activa o desactiva las notificaciones por correo.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_notif_types_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_NOTIF_TYPES && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Notification Types</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Enable or disable e-mail notifications.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_notif_types_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_EMAIL_NOTIFS && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Notificaciones por correo</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Selecciona si quieres recibir correos cada vez que baje el precio de uno de los productos que rastreas, sin importar la cantidad. O si prefieres, selecciona recibir correos solo para los productos en los que has definido un precio meta y que este es alcanzado.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_email_notifs_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_EMAIL_NOTIFS && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Email Notifications</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Choose if you want to receive e-mails every time a price drops on one of the products you're tracking, no matter by how much. Or if you prefer, choose to receive e-mails only for the products where you defined a target price, and the target price is reached.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_email_notifs_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_EMAIL_FREQ && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Frecuencia de Correos</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Selecciona si quieres recibir correos cada vez que detectemos una ca&iacute;da de precio en uno de tus productos, o si prefieres recibir solo un correo al d&iacute;a, con todos los cambios de precio que detectamos en las &uacute;ltimas 24 horas.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_email_freq_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_EMAIL_FREQ && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Email Frequency</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Choose if you want to receive e-mails every time we detect a price drop on one of your products, or if you prefer, you can choose to receive one e-mail per day, with all the price drops we detected in the last 24 hours.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_email_freq_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_WHEN_EMAIL && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Cuando recibir el correo</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Si has seleccionado la opci&oacute;n de solo un correo al d&iacute;a, define aqu&iacute; tu zona horaria y a qu&eacute; hora te gustar&iacute;a recibirlo.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_email_when_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_WHEN_EMAIL && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>When to Receive Email</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>If you chose to receive only one e-mail per day, choose your time zone and what time you'd like to receive it.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_email_when_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_OTHER_OPTS && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Otras opciones</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Aqu&iacute; puedes desactivar todos los productos prioritarios.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_other_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SETTS_OTHER_OPTS && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Other Options</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Here you can clear all your priority products.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/setts_other_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_STORE && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Tienda</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Selecciona la tienda de Amazon en la que quieres buscar productos para rastrear.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_store_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_STORE && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Store</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Select the Amazon store where you want to search for products to track.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_store_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_CATEGORY && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Categor&iacute;a</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Si prefieres, puedes seleccionar la categor&iacute;a de Amazon en la cual realizar tu b&uacute;squeda, o simplemente d&eacute;jalo en 'todas' para ver m&aacute;s resultados.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_category_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_CATEGORY && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Category</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>If you prefer, you can choose which Amazon category to search. Or simple choose 'All' to view more results.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_category_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_FILTERS && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Filtros</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Aqu&iacute; puedes filtrar tus resultados para mostrar solo aquellos que tienen env&iacute;o gratuito por Amazon Prime o env&iacute;o gratuito por cuenta del vendedor.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_filters_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_FILTERS && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Filters</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Here you can filter your results by showing only those that have free shipping provided by Amazon Prime, or free shipping provided by the vendor.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_filters_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_SEARCH && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Buscar o URL</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Introduce aqu&iacute; palabras clave de b&uacute;squeda, o si prefieres, puedes pegar la direcci&oacute;n URL de un producto de Amazon para la tienda seleccionada.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_search_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_SEARCH && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Search or URL</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Enter search keywords, or if you prefer, you can paste the URL of a product for the selected Amazon store.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_search_en.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_SORTBY && lang === Languages.ES_MX &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Ordenar Por</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Selecciona c&oacute;mo quieres ordenar los resultados de la b&uacute;squeda. Si no est&aacute;s seguro, te recomendamos ordenar por 'Relevancia'.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_sortby_es.jpeg'></img>
                        </Box>
                    </div>
                }

                {props.section === HelpSections.SEARCH_SORTBY && lang === Languages.EN_US &&
                    <div>
                        <Box className={classes.textBoxTitle}>
                            <Typography variant='h6'>Sort By</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                            <Typography variant='body1'>Choose how you want to sort the search results. If you're not sure what to choose, we recommend that your sort by 'Relevance'.</Typography>
                        </Box>
                        <Box className={classes.imgBox}>
                            <img className={classes.img} src='/img/help/search_sortby_en.jpeg'></img>
                        </Box>
                    </div>
                }
            </Container>
        </Dialog >
    )
}

export default GenericHelpDialog
