import { AppBar, Box, Button, createStyles, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Snackbar, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import APIRequests from '../components/APIRequests';
import BroSnack from '../components/BroSnack';
import Constants from '../components/Constants';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import { langContext } from '../contexts/LangContext';
import theme from '../CTheme';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import ChangePass from '../dto/request/ChangePass';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountWrapper: {
            padding: '1em',
        },
        boxWrapper: {
            display: 'flex',
            flexDirection: 'column',
            margin: '1em 0em 1em 1.5em'
        },
        chpInput: {
            marginBottom: '1em'
        },
        loader: {
            position: 'relative',
            right: '.6em',
            bottom: '.1em'
        },
        snackWarn: {
            backgroundColor: theme.palette.warning.main,
            borderColor: theme.palette.warning.dark,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '.5em',
            padding: '.5em',
            color: theme.palette.common.white,
            display: 'flex'
        },
        snackSuccess: {
            backgroundColor: theme.palette.success.main,
            borderColor: theme.palette.success.dark,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '.5em',
            padding: '.5em',
            color: theme.palette.common.white,
            display: 'flex'
        },
        snackIcon: {
            color: theme.palette.common.white,
            marginLeft: '.25em',
            marginRight: '.5em'
        },
        deleteWarn: {
            marginTop: '1em',
            marginBottom: '3em',
            // fontWeight:'bold',
            border: '1px solid',
            padding: '.5em',
            borderRadius: '.5em',
            borderColor: theme.palette.error.main,
            backgroundColor: theme.palette.error.light,
        }
    }),
);

function MyAccount() {
    const history = useHistory();
    const classes = useStyles();
    const { setAuthData, auth } = useContext(authContext);
    const { lang, setLang } = useContext(langContext);

    const [changingPassword, setChangingPassword] = useState<boolean>(false);
    const [deletingAcct, setDeletingAcct] = useState<boolean>(false);
    const [deleteWarned, setDeleteWarned] = useState<boolean>(false);
    const [sbMsg, setSbMsg] = useState<string | undefined>("");
    const [sbOpen, setSbOpen] = useState<boolean>(false);
    const [sbSuccess, setSbSuccess] = useState<boolean>(false);
    const [currPass, setCurrPass] = useState<string>("");
    const [newPass, setNewPass] = useState<string>("");
    const [newPass2, setNewPass2] = useState<string>("");
    const [sbDuration, setSbDuration] = useState<number>(6000);

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode === 503) {
            logout();
        }
    };

    const handleChPassError = (res: AResponse<any>, auth: AuthType) => {
        if (res.intCode === 502) {
            setSbMsg(lm.get(lang, Labels.MACC_CURR_PASS_INCORRECT));
            setSbOpen(true);
        } else if (res.intCode === 510) {
            setSbMsg(lm.get(lang, Labels.MACC_NEW_PASS_DONT_MATCH));
            setSbOpen(true);
        } else if (res.intCode === 511) {
            setSbMsg(res.message);
            setSbOpen(true);
        } else {
            setSbMsg(lm.get(lang, Labels.MACC_PROBLEM_UPDATING_PASS));
            setSbOpen(true);
        }
    };

    const changePass = () => {
        if (changingPassword) return;
        let chPass = new ChangePass();
        chPass.currentPass = currPass;
        chPass.newPass = newPass;
        chPass.newPassConfirm = newPass2;

        const req = new ARequest<ChangePass>(auth.token);
        req.lang = lang;
        req.data = chPass;

        setChangingPassword(true);
        APIRequests.post(Constants.BASE_URL + "api/user/changePassword", req, function (data: any) {
            const r: AResponse<string> = data;
            setChangingPassword(false);
            if (r.isError) {
                setSbSuccess(false);
                handleError(r);
                handleChPassError(r, auth);
            } else {
                setSbSuccess(true);
                setSbMsg(lm.get(lang, Labels.MACC_PASS_UPDATED));
                setSbOpen(true);
                setCurrPass("");
                setNewPass("");
                setNewPass2("");
            }
        });
    };

    const deleteAccount = () => {
        if (deletingAcct) return;

        if (!deleteWarned) {
            setDeleteWarned(true);
        } else {
            const req = new ARequest<string>(auth.token);
            req.lang = lang;
            req.data = "";
            setDeletingAcct(true);
            APIRequests.post(Constants.BASE_URL + "api/user/delete", req, function (data: any) {
                const r: AResponse<string> = data;
                if (r.isError) {
                    setSbSuccess(false);
                    handleError(r);
                    handleChPassError(r, auth);
                } else {
                    setSbSuccess(true);
                    setSbMsg(lm.get(lang, Labels.MACC_ACCOUNT_DELETED));
                    setSbOpen(true);
                    setSbDuration(15000);
                    setTimeout(() => {
                        setAuthData(new AuthType(false));
                        history.push("/");
                    }, 8000);
                }
            });
        }
    };

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar variant='dense'>
                    <IconButton edge="start" color="inherit" onClick={() => { history.push("/home") }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif" }} variant="h6">
                        {lm.get(lang, Labels.MACC_MY_ACCOUNT)}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box className={classes.accountWrapper}>
                <Typography variant="h6">{lm.get(lang, Labels.MACC_EMAIL)}</Typography>
                <Box className={classes.boxWrapper}>
                    <Typography variant="body1">{auth.email.toLocaleLowerCase()}</Typography>
                </Box>

                {!auth.thirdParty &&
                    <Typography variant="h6">{lm.get(lang, Labels.MACC_CHANGE_PASS)}</Typography>
                }
                {!auth.thirdParty &&
                    <Box className={classes.boxWrapper}>
                        <TextField
                            value={currPass}
                            className={classes.chpInput}
                            id="currPassTxt"
                            label={lm.get(lang, Labels.MACC_CURRENT_PASS)}
                            variant="outlined"
                            type="password"
                            onChange={(event) => { setCurrPass(event.target.value); }}
                        />

                        <TextField
                            value={newPass}
                            className={classes.chpInput}
                            id="newPassTxt"
                            label={lm.get(lang, Labels.MACC_NEW_PASS)}
                            variant="outlined"
                            type="password"
                            onChange={(event) => { setNewPass(event.target.value); }}
                        />

                        <TextField
                            value={newPass2}
                            className={classes.chpInput}
                            id="newPass2Txt"
                            label={lm.get(lang, Labels.MACC_NEW_PASS_CONFIRM)}
                            variant="outlined"
                            type="password"
                            onChange={(event) => { setNewPass2(event.target.value); }}
                        />

                        <Button
                            className={classes.chpInput}
                            key="changePassBtn"
                            variant="contained"
                            color="primary"
                            disabled={changingPassword}
                            onClick={changePass}
                        >
                            {(!changingPassword ? lm.get(lang, Labels.MACC_SUBMIT_PASS) : <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} />)}
                        </Button>
                    </Box>
                }

                <Typography variant="h6">{lm.get(lang, Labels.MACC_DELETE_ACCT)}</Typography>
                {deleteWarned && <Typography className={classes.deleteWarn} variant="body1">{lm.get(lang, Labels.MACC_ACCOUNT_DELETE_WARNING)}</Typography>}
                <Box className={classes.boxWrapper}>
                    <Button
                        className={classes.chpInput}
                        key="deleteAcctBtn"
                        variant="contained"
                        color="secondary"
                        disabled={deletingAcct}
                        onClick={deleteAccount}
                    >
                        {(!deletingAcct ? lm.get(lang, Labels.MACC_CLICK_TO_DELETE_ACCT) : <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} />)}
                    </Button>
                </Box>
            </Box>
            <BroSnack
                autoHideDuration={sbDuration}
                open={sbOpen}
                message={sbMsg}
                snackType={sbSuccess ? 'success' : 'warning'}
                onSnackClose={() => { setSbOpen(false) }}
            />
        </div>
    )
}

export default MyAccount
