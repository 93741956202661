import SearchIndex from "../dto/SearchIndex";
import MarketplaceSelection from "../dto/user/MarketplaceSelection";

export default class MarketplaceManager {

    public static getMarketplaces() : MarketplaceSelection[]{
        let usa:MarketplaceSelection = {
            locale: 'United States',
            marketplaceKey: 'USA',
            host:'',
            en: 'United States',
            es: 'Estados Unidos',
            selected: true,
            isOdd:false
        };

        let spain = {
            locale: 'Spain',
            marketplaceKey: 'SPAIN',
            en: 'Spain',
            es: 'España',
            host:'',
            selected: true,
            isOdd:false
        };

        let mexico = {
            locale: 'Mexico',
            marketplaceKey: 'MEXICO',
            en: 'Mexico',
            es: 'México',
            host:'',
            selected: true,
            isOdd:false
        }

        return [usa, mexico, spain];
    }

    public static getCategories(mpKey: string) : SearchIndex[]{
        switch(mpKey){
            case 'USA':
                return [
                    {
                        "searchIndex": "All",
                        "displayName": "All"
                    },
                    {
                        "searchIndex": "AmazonVideo",
                        "displayName": "Prime Video"
                    },
                    {
                        "searchIndex": "Apparel",
                        "displayName": "Clothing & Accessories"
                    },
                    {
                        "searchIndex": "Appliances",
                        "displayName": "Appliances"
                    },
                    {
                        "searchIndex": "ArtsAndCrafts",
                        "displayName": "Arts, Crafts & Sewing"
                    },
                    {
                        "searchIndex": "Automotive",
                        "displayName": "Automotive Parts & Accessories"
                    },
                    {
                        "searchIndex": "Baby",
                        "displayName": "Baby"
                    },
                    {
                        "searchIndex": "Beauty",
                        "displayName": "Beauty & Personal Care"
                    },
                    {
                        "searchIndex": "Books",
                        "displayName": "Books"
                    },
                    {
                        "searchIndex": "Classical",
                        "displayName": "Classical"
                    },
                    {
                        "searchIndex": "Collectibles",
                        "displayName": "Collectibles & Fine Art"
                    },
                    {
                        "searchIndex": "Computers",
                        "displayName": "Computers"
                    },
                    {
                        "searchIndex": "DigitalMusic",
                        "displayName": "Digital Music"
                    },
                    {
                        "searchIndex": "DigitalEducationalResources",
                        "displayName": "Digital Educational Resources"
                    },
                    {
                        "searchIndex": "Electronics",
                        "displayName": "Electronics"
                    },
                    {
                        "searchIndex": "EverythingElse",
                        "displayName": "Everything Else"
                    },
                    {
                        "searchIndex": "Fashion",
                        "displayName": "Clothing, Shoes & Jewelry"
                    },
                    {
                        "searchIndex": "FashionBaby",
                        "displayName": "Clothing, Shoes & Jewelry Baby"
                    },
                    {
                        "searchIndex": "FashionBoys",
                        "displayName": "Clothing, Shoes & Jewelry Boys"
                    },
                    {
                        "searchIndex": "FashionGirls",
                        "displayName": "Clothing, Shoes & Jewelry Girls"
                    },
                    {
                        "searchIndex": "FashionMen",
                        "displayName": "Clothing, Shoes & Jewelry Men"
                    },
                    {
                        "searchIndex": "FashionWomen",
                        "displayName": "Clothing, Shoes & Jewelry Women"
                    },
                    {
                        "searchIndex": "GardenAndOutdoor",
                        "displayName": "Garden & Outdoor"
                    },
                    {
                        "searchIndex": "GiftCards",
                        "displayName": "Gift Cards"
                    },
                    {
                        "searchIndex": "GroceryAndGourmetFood",
                        "displayName": "Grocery & Gourmet Food"
                    },
                    {
                        "searchIndex": "Handmade",
                        "displayName": "Handmade"
                    },
                    {
                        "searchIndex": "HealthPersonalCare",
                        "displayName": "Health, Household & Baby Care"
                    },
                    {
                        "searchIndex": "HomeAndKitchen",
                        "displayName": "Home & Kitchen"
                    },
                    {
                        "searchIndex": "Industrial",
                        "displayName": "Industrial & Scientific"
                    },
                    {
                        "searchIndex": "Jewelry",
                        "displayName": "Jewelry"
                    },
                    {
                        "searchIndex": "KindleStore",
                        "displayName": "Kindle Store"
                    },
                    {
                        "searchIndex": "LocalServices",
                        "displayName": "Home & Business Services"
                    },
                    {
                        "searchIndex": "Luggage",
                        "displayName": "Luggage & Travel Gear"
                    },
                    {
                        "searchIndex": "LuxuryBeauty",
                        "displayName": "Luxury Beauty"
                    },
                    {
                        "searchIndex": "Magazines",
                        "displayName": "Magazine Subscriptions"
                    },
                    {
                        "searchIndex": "MobileAndAccessories",
                        "displayName": "Cell Phones & Accessories"
                    },
                    {
                        "searchIndex": "MobileApps",
                        "displayName": "Apps & Games"
                    },
                    {
                        "searchIndex": "MoviesAndTV",
                        "displayName": "Movies & TV"
                    },
                    {
                        "searchIndex": "Music",
                        "displayName": "CDs & Vinyl"
                    },
                    {
                        "searchIndex": "MusicalInstruments",
                        "displayName": "Musical Instruments"
                    },
                    {
                        "searchIndex": "OfficeProducts",
                        "displayName": "Office Products"
                    },
                    {
                        "searchIndex": "PetSupplies",
                        "displayName": "Pet Supplies"
                    },
                    {
                        "searchIndex": "Photo",
                        "displayName": "Camera & Photo"
                    },
                    {
                        "searchIndex": "Shoes",
                        "displayName": "Shoes"
                    },
                    {
                        "searchIndex": "Software",
                        "displayName": "Software"
                    },
                    {
                        "searchIndex": "SportsAndOutdoors",
                        "displayName": "Sports & Outdoors"
                    },
                    {
                        "searchIndex": "ToolsAndHomeImprovement",
                        "displayName": "Tools & Home Improvement"
                    },
                    {
                        "searchIndex": "ToysAndGames",
                        "displayName": "Toys & Games"
                    },
                    {
                        "searchIndex": "VHS",
                        "displayName": "VHS"
                    },
                    {
                        "searchIndex": "VideoGames",
                        "displayName": "Video Games"
                    },
                    {
                        "searchIndex": "Watches",
                        "displayName": "Watches"
                    }
                ];
            case 'SPAIN':
                return [
                    {
                        "searchIndex": "All",
                        "displayName": "Todas"
                    },
                    {
                        "searchIndex": "Apparel",
                        "displayName": "Ropa y accesorios"
                    },
                    {
                        "searchIndex": "Appliances",
                        "displayName": "Grandes electrodomésticos"
                    },
                    {
                        "searchIndex": "Automotive",
                        "displayName": "Coche y moto"
                    },
                    {
                        "searchIndex": "Baby",
                        "displayName": "Bebé"
                    },
                    {
                        "searchIndex": "Beauty",
                        "displayName": "Belleza"
                    },
                    {
                        "searchIndex": "Books",
                        "displayName": "Libros"
                    },
                    {
                        "searchIndex": "Computers",
                        "displayName": "Informática"
                    },
                    {
                        "searchIndex": "DigitalMusic",
                        "displayName": "Música Digital"
                    },
                    {
                        "searchIndex": "Electronics",
                        "displayName": "Electrónica"
                    },
                    {
                        "searchIndex": "EverythingElse",
                        "displayName": "Otros Productos"
                    },
                    {
                        "searchIndex": "Fashion",
                        "displayName": "Moda"
                    },
                    {
                        "searchIndex": "ForeignBooks",
                        "displayName": "Libros en idiomas extranjeros"
                    },
                    {
                        "searchIndex": "GardenAndOutdoor",
                        "displayName": "Jardín"
                    },
                    {
                        "searchIndex": "GiftCards",
                        "displayName": "Cheques regalo"
                    },
                    {
                        "searchIndex": "GroceryAndGourmetFood",
                        "displayName": "Alimentación y bebidas"
                    },
                    {
                        "searchIndex": "Handmade",
                        "displayName": "Handmade"
                    },
                    {
                        "searchIndex": "HealthPersonalCare",
                        "displayName": "Salud y cuidado personal"
                    },
                    {
                        "searchIndex": "HomeAndKitchen",
                        "displayName": "Hogar y cocina"
                    },
                    {
                        "searchIndex": "Industrial",
                        "displayName": "Industria y ciencia"
                    },
                    {
                        "searchIndex": "Jewelry",
                        "displayName": "Joyería"
                    },
                    {
                        "searchIndex": "KindleStore",
                        "displayName": "Tienda Kindle"
                    },
                    {
                        "searchIndex": "Lighting",
                        "displayName": "Iluminación"
                    },
                    {
                        "searchIndex": "Luggage",
                        "displayName": "Equipaje"
                    },
                    {
                        "searchIndex": "MobileApps",
                        "displayName": "Appstore para Android"
                    },
                    {
                        "searchIndex": "MoviesAndTV",
                        "displayName": "Películas y TV"
                    },
                    {
                        "searchIndex": "Music",
                        "displayName": "Música: CDs y vinilos"
                    },
                    {
                        "searchIndex": "MusicalInstruments",
                        "displayName": "Instrumentos musicales"
                    },
                    {
                        "searchIndex": "OfficeProducts",
                        "displayName": "Oficina y papelería"
                    },
                    {
                        "searchIndex": "PetSupplies",
                        "displayName": "Productos para mascotas"
                    },
                    {
                        "searchIndex": "Shoes",
                        "displayName": "Zapatos y complementos"
                    },
                    {
                        "searchIndex": "Software",
                        "displayName": "Software"
                    },
                    {
                        "searchIndex": "SportsAndOutdoors",
                        "displayName": "Deportes y aire libre"
                    },
                    {
                        "searchIndex": "ToolsAndHomeImprovement",
                        "displayName": "Bricolaje y herramientas"
                    },
                    {
                        "searchIndex": "ToysAndGames",
                        "displayName": "Juguetes y juegos"
                    },
                    {
                        "searchIndex": "Vehicles",
                        "displayName": "Coche - renting"
                    },
                    {
                        "searchIndex": "VideoGames",
                        "displayName": "Videojuegos"
                    },
                    {
                        "searchIndex": "Watches",
                        "displayName": "Relojes"
                    }
                ];
            case 'MEXICO':
                return [
                    {
                        "searchIndex": "All",
                        "displayName": "Todas"
                    },
                    {
                        "searchIndex": "Automotive",
                        "displayName": "Auto"
                    },
                    {
                        "searchIndex": "Baby",
                        "displayName": "Bebé"
                    },
                    {
                        "searchIndex": "Books",
                        "displayName": "Libros"
                    },
                    {
                        "searchIndex": "Electronics",
                        "displayName": "Electrónicos"
                    },
                    {
                        "searchIndex": "Fashion",
                        "displayName": "Ropa, Zapatos y Accesorios"
                    },
                    {
                        "searchIndex": "FashionBaby",
                        "displayName": "Ropa, Zapatos y Accesorios Bebé"
                    },
                    {
                        "searchIndex": "FashionBoys",
                        "displayName": "Ropa, Zapatos y Accesorios Niños"
                    },
                    {
                        "searchIndex": "FashionGirls",
                        "displayName": "Ropa, Zapatos y Accesorios Niñas"
                    },
                    {
                        "searchIndex": "FashionMen",
                        "displayName": "Ropa, Zapatos y Accesorios Hombres"
                    },
                    {
                        "searchIndex": "FashionWomen",
                        "displayName": "Ropa, Zapatos y Accesorios Mujeres"
                    },
                    {
                        "searchIndex": "GroceryAndGourmetFood",
                        "displayName": "Alimentos y Bebidas"
                    },
                    {
                        "searchIndex": "Handmade",
                        "displayName": "Productos Handmade"
                    },
                    {
                        "searchIndex": "HealthPersonalCare",
                        "displayName": "Salud, Belleza y Cuidado Personal"
                    },
                    {
                        "searchIndex": "HomeAndKitchen",
                        "displayName": "Hogar y Cocina"
                    },
                    {
                        "searchIndex": "IndustrialAndScientific",
                        "displayName": "Industria y ciencia"
                    },
                    {
                        "searchIndex": "KindleStore",
                        "displayName": "Tienda Kindle"
                    },
                    {
                        "searchIndex": "MoviesAndTV",
                        "displayName": "Películas y Series de TV"
                    },
                    {
                        "searchIndex": "Music",
                        "displayName": "Música"
                    },
                    {
                        "searchIndex": "MusicalInstruments",
                        "displayName": "Instrumentos musicales"
                    },
                    {
                        "searchIndex": "OfficeProducts",
                        "displayName": "Oficina y Papelería"
                    },
                    {
                        "searchIndex": "PetSupplies",
                        "displayName": "Mascotas"
                    },
                    {
                        "searchIndex": "Software",
                        "displayName": "Software"
                    },
                    {
                        "searchIndex": "SportsAndOutdoors",
                        "displayName": "Deportes y Aire Libre"
                    },
                    {
                        "searchIndex": "ToolsAndHomeImprovement",
                        "displayName": "Herramientas y Mejoras del Hogar"
                    },
                    {
                        "searchIndex": "ToysAndGames",
                        "displayName": "Juegos y juguetes"
                    },
                    {
                        "searchIndex": "VideoGames",
                        "displayName": "Videojuegos"
                    },
                    {
                        "searchIndex": "Watches",
                        "displayName": "Relojes"
                    }
                ];
            default: return [];
        }
    }
}