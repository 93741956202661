import React, { useContext, useEffect, useState } from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import Landing from './screens/Landing';
import SignIn from './screens/SignIn';
import Home from './screens/Home';
import PrivateRoute from './components/PrivateRoute';
import SignUp from './screens/SignUp';
import MySites from './screens/MySites';
import MyProducts from './screens/MyProducts';
import NotificationSettings from './screens/NotificationSettings';
import Verify from './screens/Verify';
import ResetPassword from './screens/ResetPassword';
import SimpleNotice from './screens/SimpleNotice';
import ResetPassword2 from './screens/ResetPassword2';
import MyAccount from './screens/MyAccount';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import Page404 from './screens/Page404';
import BroSnack from './components/BroSnack';
import ARequest from './dto/ARequest';
import { authContext } from './contexts/AuthContext';
import APIRequests from './components/APIRequests';
import Constants from './components/Constants';
import AResponse from './dto/AResponse';
import lm from './components/LabelManager';
import { Labels } from './components/enums/Labels';
import EncProvider from './contexts/EncContext';
import { langContext } from './contexts/LangContext';
import AmzDisclaimerRedirect from './screens/AmzDisclaimerRedirect';
import OneMoment from './screens/OneMoment';
import About from './screens/About';
import Help from './screens/Help';
import FAQ from './screens/FAQ';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsOfUse from './screens/TermsOfUse';
import DeleteInstructions from './screens/DeleteInstructions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wideLayout: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.extra1.dark
    },
    mobileLayout: {
      display: 'flex',
    },
    wideLayoutInnerL: {
      maxWidth: '40%',
      minWidth: '40%',
      // minHeight:window.innerHeight+'px',
      // border: '1px solid',
      // borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[24]
    },
    wideLayoutInnerS: {
      maxWidth: '70%',
      minWidth: '70%',
      // minHeight:window.innerHeight+'px',
      // border: '1px solid',
      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.grey[300],
      boxShadow: theme.shadows[24]
    },
    mobileLayoutInner: {
      // minHeight:window.screen.height +'px',
      maxWidth: '100%',
      minWidth: '100%',
      backgroundColor: theme.palette.background.default
    }
  }),
);

function App() {
  const classes = useStyles();
  const [wide, setWide] = useState<boolean>(window.innerWidth > window.innerHeight);
  const [smallWide, setSmallWide] = useState<boolean>(window.innerWidth <= 1000);
  const [mHeight, setMHeight] = useState<number>(wide ? window.innerHeight : window.screen.height);
  const [appSnackOpen, setAppSnackOpen] = useState<boolean>(false);
  const { setAuthData, auth } = useContext(authContext);
  const {lang} = useContext(langContext);

  const windowResizeHandler = () => {
    setWide(window.innerWidth > window.innerHeight);
    setSmallWide(window.innerWidth <= 1000);
    setMHeight(wide ? window.innerHeight : window.screen.height);
  };

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };


  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);

    APIRequests.post(Constants.BASE_URL + "api/ui/version", undefined, function (data: any) {

      const r: AResponse<number> = data;
      if (r.isError) {
      } else {
        let v: number = r.data;
        if (v > Constants.UI_VERSION) {
          setAppSnackOpen(true);
        }
      }
    });

    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, []);



  return (
    <div className={wide ? classes.wideLayout : classes.mobileLayout}>
      <div style={{ minHeight: mHeight + 'px' }} className={wide && !smallWide ? classes.wideLayoutInnerL : (wide ? classes.wideLayoutInnerS : classes.mobileLayoutInner)}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/reset" component={ResetPassword2} />
            <Route exact path="/password-reset-notice" component={SimpleNotice} />
            <Route exact path="/verify-notice" component={SimpleNotice} />
            <Route exact path="/one-moment" component={OneMoment} />
            <Route exact path="/404" component={Page404} />
            <Route exact path="/error" component={Page404} />
            <Route path="/verify" component={Verify} />            
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/how-to-delete-account" component={DeleteInstructions} />
            <PrivateRoute exact path="/buy" component={AmzDisclaimerRedirect} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/about" component={About} />
            <PrivateRoute exact path="/help" component={Help} />            
            {/* <PrivateRoute exact path="/my-sites" component={MySites} />
            <PrivateRoute exact path="/my-stores" component={MySites} /> */}
            <PrivateRoute exact path="/my-products" component={MyProducts} />
            <PrivateRoute exact path="/my-account" component={MyAccount} />
            <PrivateRoute exact path="/settings" component={NotificationSettings} />          
            <Route component={Page404} />
          </Switch>
        </BrowserRouter>
      </div>
      <BroSnack
        autoHideDuration={20000}
        message={lm.get(lang, Labels.APP_NEW_VERSION_AVAILABLE)}
        snackType='info'
        open={appSnackOpen}
        onSnackClose={() => { setAppSnackOpen(false) }}
        showButton={true}
        buttonText={lm.get(lang, Labels.APP_GET_NEW_VERSION)}
        buttonAction={refreshCacheAndReload}
      ></BroSnack>
    </div>
  );
}

export default App;
