import { Box, Button, createStyles, Divider, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, Notes, OpenInNew } from '@material-ui/icons';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import lm from '../components/LabelManager';
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import { langContext } from '../contexts/LangContext';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import ProductAlwaysNotify from '../dto/request/ProductAlwaysNotify';
import ProductPriceTarget from '../dto/request/ProductPriceTarget';
import ProductTrack from '../dto/request/ProductTrack';
import ProductUntrack from '../dto/request/ProductUntrack';
import NotificationInfo from '../dto/response/NotificationInfo';
import ProductInfo from '../dto/response/ProductInfo';
import APIRequests from './APIRequests';
import Constants from './Constants';
import BuyProduct from './BuyProduct';
import { Labels } from './enums/Labels';
import ProductDetailsDialog from './ProductDetailsDialog';
import ProductPriceHistoryDialog from './ProductPriceHistoryDialog';
import ScaleLoader from "react-spinners/ScaleLoader";
import theme from '../CTheme';
import DeleteIcon from '@material-ui/icons/Delete';
import PuffLoader from "react-spinners/PuffLoader";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CacheManager from './CacheManager';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.grey[300],
            borderRadius: '1em',
            paddingBottom: '.3em',
            marginTop: '.8em',
        },
        productInfoRoot: {
            backgroundColor: theme.palette.common.white,
            border: '1px solid black',
            borderColor: theme.palette.primary.main,
            borderRadius: '1em',
            padding: '.5em 1em .25em .5em',
            position: 'relative'
        },
        header: {
            marginBottom: '1em'
        },
        titleBox: {
            position: 'relative'
        },
        title: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '.9em',
            textTransform: 'capitalize',
            marginRight: '2rem',
            cursor: 'pointer'
        },
        importantIconBox: {
            position: 'absolute',
            right: '-.25rem',
            top: '-.25rem',
        },
        importantIconNone: {
            color: theme.palette.grey[400]
        },
        importantIconAll: {
            color: theme.palette.extra2.main
        },
        deleteIconBox: {
            display: 'inline-block',
            marginRight: '1em',
            maxWidth: '1em',
            maxHeight: '1em',
            minWidth: '1em',
            minHeight: '1em',
        },
        deleteIcon: {
            fontSize: '1.2rem',
            color: theme.palette.grey[500]
        },
        date: {
            display: 'inline-block',
            fontSize: '.8em',
            textAlign: 'right',
            fontStyle: 'italic',
            position: 'relative',
            right: '1em',
            marginTop: '.2em'
        },
        imageWrapper: {
            maxWidth: '25%',
            minWidth: '25%',
            marginRight: '4%',
            cursor: 'pointer'
        },
        image: {
            maxWidth: '100%',
            maxHeight: '15em',
        },
        infoWrapper: {
            maxWidth: '70%',
            minWidth: '70%'
        },
        targetPriceHeader: {
            marginTop: '1em',
            fontSize: '.6em',
            color: theme.palette.primary.main
        },
        targetPrice: {
            fontSize: '.8em',
        },
        priceDropHeader: {
            marginTop: '1em',
            fontSize: '.6em',
            color: theme.palette.text.primary
        },
        priceDrop: {
            fontSize: '1em',
        },
        newPriceBox: {
            display: 'inline-block'
        },
        newPriceHeader: {
            fontSize: '.6em',
            marginTop: '.5em',
            color: theme.palette.secondary.dark
        },
        newPrice: {
            fontSize: '1em',
        },
        binding: {
            fontSize: '.6em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.text.disabled,
            textTransform: 'capitalize'
        },
        contributors: {
            fontSize: '.6em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.text.disabled,
            textTransform: 'capitalize'
        },
        buttonBox1: {
            marginTop: '.8em',
            marginBottom: '0'
        },
        buyButton: {
            marginLeft: '.25em',
            marginRight: '.25em',
            height: '2.1em',
            fontSize: '.8em',
            marginBottom: '.5em'
        },
        detailsButton: {
            marginLeft: '.25em',
            marginRight: '.25em',
            height: '2.1em',
            fontSize: '.8em',
            marginBottom: '.5em'
        },
        footer: {
            position: 'relative',
            textAlign: 'right'
        },
        footerFlag: {
            position: 'absolute',
            left: '1em',
            top: '2px',
            width: '1.1em',
            height: '1.1em',
            borderColor: theme.palette.common.white,
            backgroundColor: theme.palette.common.white,
            padding: '0em 2px',
            borderRadius: '.2em'
        },
        primeBox: {
            display: 'inline-block',
            marginLeft: '1em',
            maxWidth: '30%'
        },
        prime: {
            display: 'inline-block',
            fontSize: '.9em'
        },
        primeCheck: {
            display: 'inline-block',
            fontSize: '1.1em',
            position: 'relative',
            top: '.2em',
            marginLeft: '.25em'
        },
        targetPriceReachedBox: {
            alignItems: 'center',
            marginTop: '.5em',
            display: 'flex',
            flexDirection: 'row'
        },
        targetPriceReachedIcon: {
            marginRight: '.1em',
            display: 'inline-block',
            fontSize: '1rem'
        },
        notifIconBox: {
            position: 'absolute',
            top: '-.6em',
            left: '-.25em',
            backgroundColor: 'transparent',
            display: 'flex'
        },
        notifNewIcon: {
            fontSize: '2rem',
            transform: 'rotate(-25deg)',
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.common.white,
            borderRadius: '.5em',
            marginRight: '.2em'
        },
        notifTargetIcon: {
            fontSize: '1.75rem',
            transform: 'rotate(-25deg)',
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.common.white,
            borderRadius: '.5em',
            marginRight: '.2em'
        }
    }),
);

interface NotificationCardProps {
    notifInfo: NotificationInfo,
    mobile: boolean,
    updateProdCache: (prodInfo: ProductInfo, deleteProdCache:boolean) => void,
    deleteNotifFromCache: (notifInfo: NotificationInfo) => void,
    setOpenBellTutorial: (open: boolean) => void,
}

function NotificationCard(props: NotificationCardProps) {
    const { setAuthData, auth } = useContext(authContext);
    const { lang } = useContext(langContext);
    const history = useHistory();
    const classes = useStyles();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const [priceHistoryOpen, setPriceHistoryOpen] = useState<boolean>(false);
    const [tracking, setTracking] = useState<boolean>(props.notifInfo.productInfo.trackInfo.tracking);
    const [trackingLoad, setTrackingLoad] = useState<boolean>(false);
    const [trackDate, setTrackDate] = useState<Date>(props.notifInfo.productInfo.trackInfo.dateAdded);
    const [targetPrice, setTargetPrice] = useState<number>(props.notifInfo.productInfo.trackInfo.targetPrice);
    const [editingTargetPrice, setEditingTargetPrice] = useState<boolean>(false);
    const [alwaysNotify, setAlwaysNotify] = useState<boolean>(props.notifInfo.productInfo.alwaysNotify);
    const [loadingAlwaysNotify, setLoadingAlwaysNotify] = useState<boolean>(false);
    const [buyLoading, setBuyLoading] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode == 503) {
            logout();
        }
    };

    const trackHandler = (prodInfo: ProductInfo) => async () => {
        setTracking(true);
        setTrackingLoad(true);
        setTrackDate(new Date());

        let captchaRes: string = "";
        try {
            captchaRes = await executeRecaptcha('track');
        } catch { };

        const req = new ARequest<ProductTrack>(auth.token);
        const pt = new ProductTrack();
        pt.asin = prodInfo.asin;
        pt.currency = prodInfo.currency;
        pt.marketplaceKey = prodInfo.marketplaceKey;
        pt.priceAmount = prodInfo.priceAmount;
        pt.captchaToken = captchaRes;
        pt.prime = prodInfo.prime;
        req.data = pt;

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/track', req, function (data) {
            setTrackingLoad(false);
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setTracking(false);
            } else {                
                setTracking(r.data);
                prodInfo.trackInfo.tracking = true;
                props.updateProdCache(prodInfo, true);                
            }
        });
    };

    const untrackHandler = (prodInfo: ProductInfo) => () => {
        setTracking(false);
        const req = new ARequest<ProductUntrack>(auth.token);
        const pu = new ProductUntrack();
        pu.asin = prodInfo.asin;
        pu.marketplaceKey = prodInfo.marketplaceKey;
        req.data = pu;

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/untrack', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setTracking(true);
            } else {
                setTracking(r.data);
                prodInfo.trackInfo.tracking = false;
                props.updateProdCache(prodInfo, true);
            }
        });
    };

    const deleteHandler = (notifInfo: NotificationInfo) => {
        setDeleting(true);
        const req = new ARequest<string>(auth.token);
        req.data = notifInfo.id;

        APIRequests.post(Constants.BASE_URL + 'api/user/notifications/delete', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setDeleting(false);
            } else {
                props.deleteNotifFromCache(notifInfo);
            }
        });
    };

    const setTrackPriceHandler = (prodInfo: ProductInfo) => {
        const req = new ARequest<ProductPriceTarget>(auth.token);

        let oldTargetPrice = prodInfo.trackInfo.targetPrice;

        const ppt = new ProductPriceTarget();
        ppt.asin = prodInfo.asin;
        ppt.marketplaceKey = prodInfo.marketplaceKey;
        ppt.targetPrice = targetPrice;
        req.data = ppt;

        prodInfo.trackInfo.targetPrice = targetPrice;
        props.updateProdCache(prodInfo, false);
        
        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/setTargetPrice', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                prodInfo.trackInfo.targetPrice = oldTargetPrice;
                props.updateProdCache(prodInfo, false);
            } else {
                if (!r.data) {
                    setTargetPrice(0);
                    setEditingTargetPrice(true);
                }                
            }
        });
    }

    const alwaysNotifyHandler = (prodInfo: ProductInfo, an: boolean) => {
        if (loadingAlwaysNotify) return;
        props.setOpenBellTutorial(true);
        setLoadingAlwaysNotify(true);
        const req = new ARequest<ProductAlwaysNotify>(auth.token);
        const pt = new ProductAlwaysNotify();
        pt.asin = prodInfo.asin;
        pt.marketplaceKey = prodInfo.marketplaceKey;
        pt.alwaysNotify = an;
        req.data = pt;
        const prev = alwaysNotify;
        setAlwaysNotify(an);
        prodInfo.alwaysNotify = an;
        props.updateProdCache(prodInfo, false);

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/setAlwaysNotify', req, function (data) {
            const r: AResponse<boolean> = data;
            setLoadingAlwaysNotify(false);
            if (r.isError) {
                handleError(r);
                setAlwaysNotify(prev);
                prodInfo.alwaysNotify = prev;
                props.updateProdCache(prodInfo, false);
            }
        });
    };

    const getFlagName = (mpKey: string): string | undefined => {
        switch (mpKey) {
            case 'MEXICO':
                return 'mx';
            case 'USA':
                return 'us';
            case 'SPAIN':
                return 'sp';
            case 'UK':
                return 'uk';
            default:
                return undefined;
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.productInfoRoot}>
                <Box display='flex'>
                    <Box className={classes.imageWrapper}>
                        <img onClick={() => { setDetailsOpen(true) }} className={classes.image} src={ProductInfo.getLargestPrimary(props.notifInfo.productInfo).url} />
                    </Box>
                    <Box className={classes.infoWrapper}>
                        <Box className={classes.titleBox}>
                            <Typography onClick={() => { setDetailsOpen(true) }} className={classes.title}>{props.notifInfo.productInfo.title}</Typography>
                            {tracking &&
                                <Box className={classes.importantIconBox}>
                                    {alwaysNotify ?
                                        <IconButton onClick={() => { alwaysNotifyHandler(props.notifInfo.productInfo, false) }} style={{ padding: '0' }}>
                                            <NotificationImportantIcon className={classes.importantIconAll} />
                                        </IconButton>
                                        :
                                        <IconButton onClick={() => { alwaysNotifyHandler(props.notifInfo.productInfo, true) }} style={{ padding: '0' }}>
                                            <NotificationImportantIcon className={classes.importantIconNone} />
                                        </IconButton>
                                    }
                                </Box>
                            }
                        </Box>
                        <Divider />
                        <Box>
                            <Typography className={classes.binding}>{props.notifInfo.productInfo.binding}</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.contributors}>{ProductInfo.getFormattedContributors(props.notifInfo.productInfo)}</Typography>
                        </Box>
                        {
                            props.notifInfo.productInfo.trackInfo.targetPrice > 0 ?
                                <Box className={classes.targetPriceHeader}>{lm.get(lang, Labels.NC_YOUR_TARGET_PRICE)}</Box> :
                                <Box className={classes.targetPriceHeader}>{lm.get(lang, Labels.NC_NO_TARGET_SET)}</Box>
                        }

                        {
                            props.notifInfo.productInfo.trackInfo.targetPrice > 0 &&
                            <Box>
                                <Typography className={classes.targetPrice}>
                                    {props.notifInfo.productInfo.trackInfo.targetPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + props.notifInfo.currency}

                                </Typography>
                            </Box>
                        }

                        <Box className={classes.priceDropHeader}>{lm.get(lang, Labels.NC_PRICE_DROP)}</Box>
                        <Box><Typography className={classes.priceDrop}>{props.notifInfo.priceChange.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + props.notifInfo.currency}</Typography></Box>
                        <Box className={classes.newPriceHeader}>

                            {props.notifInfo.targetReached ?
                                <Box className={classes.targetPriceReachedBox}>
                                    <NewReleasesIcon className={classes.targetPriceReachedIcon} color='primary' fontSize='small' />
                                    <div>{lm.get(lang, Labels.NC_TARGET_REACHED)}</div>
                                </Box>
                                :
                                lm.get(lang, Labels.NC_NEW_PRICE)
                            }
                        </Box>
                        <Box className={classes.newPriceBox}><Typography className={classes.newPrice}>{props.notifInfo.currentPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + props.notifInfo.currency}</Typography></Box>
                        <Box className={classes.primeBox}>
                            <Typography className={classes.prime}>{lm.get(lang, Labels.C_PRIME)}</Typography>
                            {props.notifInfo.productInfo.prime ? (
                                <CheckBox className={classes.primeCheck} color='secondary' />
                            ) : (
                                <CheckBoxOutlineBlank className={classes.primeCheck} color='secondary' />
                            )}
                        </Box>
                        <Box className={classes.buttonBox1}>
                            <Button
                                className={classes.buyButton}
                                variant='contained'
                                color='secondary'
                                fullWidth={props.mobile}
                                endIcon={!buyLoading && <OpenInNew />}
                                disabled={buyLoading}
                                onClick={() => {
                                    BuyProduct.click(buyLoading, setBuyLoading, props.notifInfo.productInfo, auth.token, lang);
                                }}
                            >
                                {buyLoading ? <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} /> : lm.get(lang, Labels.C_BUY_ON_AMZ)}
                            </Button>
                            <Button
                                className={classes.detailsButton}
                                variant='contained'
                                color='primary'
                                fullWidth={props.mobile}
                                endIcon={<Notes />}
                                onClick={() => { setDetailsOpen(true); }}
                            >{lm.get(lang, Labels.C_VIEW_DETAILS)}</Button>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.notifIconBox}>
                    {!props.notifInfo.seen && <FiberNewIcon className={classes.notifNewIcon}></FiberNewIcon>}
                    {props.notifInfo.targetReached && <NewReleasesIcon className={classes.notifTargetIcon}></NewReleasesIcon>}
                </Box>
            </Box>
            <Box className={classes.footer}>
                {getFlagName(props.notifInfo.productInfo.marketplaceKey) && <img className={classes.footerFlag} src={'/svg/' + getFlagName(props.notifInfo.productInfo.marketplaceKey) + '.svg'} />}
                <Box className={classes.date}>{moment(props.notifInfo.notificationDate).format('HH:mm DD-MMM-YY').toLocaleUpperCase()}</Box>
                <Box className={classes.deleteIconBox}>
                    {deleting ?
                        <PuffLoader css="position:relative;right:.25em;bottom:2px" size='1rem'></PuffLoader>
                        :
                        <IconButton onClick={() => { deleteHandler(props.notifInfo) }} style={{ padding: '0' }}>
                            <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                    }

                </Box>
            </Box>
            { priceHistoryOpen &&
                <ProductPriceHistoryDialog
                    open={priceHistoryOpen}
                    setPriceHistoryOpen={setPriceHistoryOpen}
                    prodInfo={props.notifInfo.productInfo}
                // key={props.prodInfo.asin + '_pph'}
                />
            }
            { detailsOpen &&
                <ProductDetailsDialog
                    open={detailsOpen}
                    prodInfo={props.notifInfo.productInfo}
                    setDetailsOpen={setDetailsOpen}
                    setPriceHistoryOpen={setPriceHistoryOpen}
                    tracking={tracking}

                    trackHandler={trackHandler}
                    untrackHandler={untrackHandler}
                    trackDate={trackDate}
                    editingTargetPrice={editingTargetPrice}
                    setEditingTargetPrice={setEditingTargetPrice}
                    setTargetPrice={setTargetPrice}
                    setTrackPriceHandler={setTrackPriceHandler}
                    targetPrice={targetPrice}
                    trackingLoad={trackingLoad}
                />
            }

        </Box>
    )
}

export default NotificationCard
