import { Box, Button, createStyles, Dialog, DialogTitle, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ProductInfo from '../dto/response/ProductInfo';
import lm from './LabelManager';
import { Labels } from './enums/Labels';
import { authContext } from '../contexts/AuthContext';
import DatedPrice from '../dto/DatedPrice';
import ARequest from '../dto/ARequest';
import APIRequests from './APIRequests';
import Constants from './Constants';
import AResponse from '../dto/AResponse';
import AuthType from '../contexts/AuthType';
import { useHistory } from 'react-router-dom';
import ProductPriceHistorySlice from '../dto/request/ProductPriceHistorySlice';
import BounceLoader from "react-spinners/BounceLoader";
import theme from '../CTheme';
import { langContext } from '../contexts/LangContext';
import ProductId from '../dto/request/ProductId';
import PPHAggr from '../dto/response/PPHAggr';

interface ProductPriceHistoryDialogProps {
    prodInfo: ProductInfo,
    open: boolean,
    setPriceHistoryOpen: (open: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        propPriceHistoryBtn: {
            backgroundColor: theme.palette.primary.main,
            padding: '0em .3em',
            borderRadius: '.25em',
            boxShadow: theme.shadows[2],
            marginLeft: '.5em'
        },
        propPriceHistoryBtnIcon: {
            color: '#FFF'
        },
        prodPriceHistoryTableContainer: {
            maxHeight: '25em',
            padding: '0em 1em 1em 1em',
            maxWidth: '90%'
        },
        prodPriceHistoryTitle: {
            paddingBottom: '0em'
        },
        prodPriceHistorySubtitle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '1em',
            padding: '.25em 1.75em',
            minHeight: '1em'
        },
        prodPriceHistoryNone: {
            fontSize: '1em',
            padding: '.25em 1.75em',
            margin: '3em 0',
        },
        prodDetailsCloseBtn: {
            position: 'absolute',
            right: '0em',
            top: '0em'
        },
        prodPPTableContent: {
            padding:'.5em .2em'
        },
        loadingSpinner: {
            display: 'flex',
            justifyContent: 'center'
        },
        moreBtn: {
            height: '2em'
        },
        aggrPaper: {
            padding: '.5em',
            margin: '1em 1.5em 1em 1.5em',
            fontSize: '.8em'
        },
        aggrDataPoint: {
            marginBottom: '.5em',
            display: 'flex',
            flexDirection: 'row'
        },
        aggrTitle: {
            fontWeight: 'bold',
            flexGrow: 5
        },
        aggrData: {
            textAlign: 'right',
            flexGrow: 1
        },
        currencySpan: {
            fontSize: '.5em'
        }
    }),
);

function ProductPriceHistoryDialog(props: ProductPriceHistoryDialogProps) {
    const classes = useStyles();
    const history = useHistory();
    const { setAuthData, auth } = useContext(authContext);
    const { lang } = useContext(langContext);
    const [priceHistory, setPriceHistory] = useState<DatedPrice[]>([]);
    const [priceHistoryPage, setPriceHistoryPage] = useState<number>(0);
    const [currentPageLoaded, setCurrentPageLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAggr, setLoadingAggr] = useState<boolean>(false);
    const [aggrData, setAggrData] = useState<PPHAggr>();

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode === 503) {
            logout();
        }
    };

    const getHistorySlice = () => {
        setLoading(true);
        let hSlice: ProductPriceHistorySlice = new ProductPriceHistorySlice();
        hSlice.asin = props.prodInfo.asin;
        hSlice.marketplaceKey = props.prodInfo.marketplaceKey;
        hSlice.pageNumber = priceHistoryPage;

        const req = new ARequest<ProductPriceHistorySlice>(auth.token);
        req.lang = lang;
        req.data = hSlice;

        APIRequests.post(Constants.BASE_URL + "api/tracker/products/getPriceHistory", req, function (data: any) {
            const r: AResponse<DatedPrice[]> = data;
            setLoading(false);
            if (r.isError) {
                handleError(r);
            } else {
                setPriceHistory(hist => hist.concat(r.data));
                setCurrentPageLoaded(true);
            }
        });
    };

    const getAggrData = () => {
        setLoadingAggr(true);
        let pId = new ProductId();
        pId.asin = props.prodInfo.asin;
        pId.marketplaceKey = props.prodInfo.marketplaceKey;

        const req = new ARequest<ProductId>(auth.token);
        req.lang = lang;
        req.data = pId;

        APIRequests.post(Constants.BASE_URL + "api/tracker/products/getPriceHistoryAggr", req, function (data: any) {
            const r: AResponse<PPHAggr> = data;
            setLoadingAggr(false);
            if (r.isError) {
                handleError(r);
            } else {
                setAggrData(r.data);
            }
        });
    }

    useEffect(() => {
        if (props.open && !currentPageLoaded)
            getHistorySlice();
    }, [props.open, currentPageLoaded]);

    useEffect(() => {
        if (props.open && !aggrData)
            getAggrData();
    }, [props.open]);

    return (
        <Dialog open={props.open} id={props.prodInfo.asin + '-pricehistory'}
            onClose={() => { props.setPriceHistoryOpen(false) }}
        >
            <IconButton className={classes.prodDetailsCloseBtn} onClick={() => { props.setPriceHistoryOpen(false) }}><Close /></IconButton>
            <DialogTitle className={classes.prodPriceHistoryTitle}>{lm.get(lang, Labels.PH_PRICE_HISTORY)}</DialogTitle>
            <Typography className={classes.prodPriceHistorySubtitle}>{props.prodInfo.title}</Typography>

            {currentPageLoaded && priceHistory.length == 0 && <Typography className={classes.prodPriceHistoryNone} variant='body1'>{lm.get(lang, Labels.PH_NO_HISTORY_YET)}</Typography>}

            {!loadingAggr && aggrData && aggrData.lowest30Days > 0 &&
                <Paper className={classes.aggrPaper} variant='outlined'>
                    <Box className={classes.aggrDataPoint}>
                        <Box className={classes.aggrTitle}>
                            {lm.get(lang, Labels.PH_LOW_30D)}
                        </Box>
                        <Box className={classes.aggrData}>
                            $ {aggrData?.lowest30Days.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className={classes.currencySpan}>{aggrData?.currency}</span>
                        </Box>
                    </Box>
                    <Box className={classes.aggrDataPoint}>
                        <Box className={classes.aggrTitle}>
                            {lm.get(lang, Labels.PH_HIGH_30D)}
                        </Box>
                        <Box className={classes.aggrData}>
                            $ {aggrData?.highest30Days.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className={classes.currencySpan}>{aggrData?.currency}</span>
                        </Box>
                    </Box>
                    <Box className={classes.aggrDataPoint}>
                        <Box className={classes.aggrTitle}>
                            {lm.get(lang, Labels.PH_LOW_YEAR)}
                        </Box>
                        <Box className={classes.aggrData}>
                            $ {aggrData?.lowestYear.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className={classes.currencySpan}>{aggrData?.currency}</span>
                        </Box>
                    </Box>
                    <Box className={classes.aggrDataPoint} style={{ margin: "0" }}>
                        <Box className={classes.aggrTitle}>
                            {lm.get(lang, Labels.PH_HIGH_YEAR)}
                        </Box>
                        <Box className={classes.aggrData}>
                            $ {aggrData?.highestYear.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className={classes.currencySpan}>{aggrData?.currency}</span>
                        </Box>
                    </Box>
                </Paper>
            }

            {(loading || (currentPageLoaded && priceHistory.length > 0)) &&
                <TableContainer className={classes.prodPriceHistoryTableContainer} component={Box}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{lm.get(lang, Labels.PH_DATE)}</TableCell>
                                <TableCell align="right">{lm.get(lang, Labels.PH_PRICE)}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {priceHistory.map((row) => (
                                <TableRow key={row.date.toString()}>
                                    <TableCell component="th" scope="row" className={classes.prodPPTableContent}>
                                        {moment(row.date).format('DD/MM/YYYY HH:mm')}
                                    </TableCell>
                                    <TableCell align="right" className={classes.prodPPTableContent}>$ {row.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className={classes.currencySpan}>{row.currency}</span></TableCell>
                                </TableRow>
                            ))}
                            {loading &&
                                <TableRow>
                                    <TableCell align='center' colSpan={2}>
                                        <div className={classes.loadingSpinner}><BounceLoader size='30' color={theme.palette.primary.main} /></div>
                                    </TableCell>
                                </TableRow>
                            }
                            {!loading && priceHistory.length > 0 && priceHistory.length % 20 == 0 &&
                                <TableRow>
                                    <TableCell align='center' colSpan={2}>
                                        <Button
                                            className={classes.moreBtn}
                                            variant='contained'
                                            color='primary'
                                            onClick={() => { setPriceHistoryPage(priceHistoryPage + 1); setCurrentPageLoaded(false); }}
                                        >
                                            {lm.get(lang, Labels.PH_MORE)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Dialog>
    )
}

export default ProductPriceHistoryDialog
