import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Constants from '../components/Constants';
import AResponse from '../dto/AResponse';
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { makeStyles, Theme, createStyles, Container, Box, Typography } from '@material-ui/core';
import { Labels } from '../components/enums/Labels'
import lm from '../components/LabelManager';
import { langContext } from '../contexts/LangContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            border: '3px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '1em',
            paddingTop: '3em'
        },
        headerBox: {
            marginTop: '0em'
        },
        header: {
            textAlign: 'center'
        },
        alienBox: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        alien: {
            maxWidth: '7em',
            maxHeight: '7em'
        },
        buttonBox: {
            marginTop: '1em',
            textAlign: 'center'
        },
        orBox: {
            marginTop: '3em',
            textAlign: 'center'
        }
    }),
);


function Verify() {
    const classes = useStyles();
    const history = useHistory();
    const [verifying, setVerifying] = useState<boolean>(true);
    const [verified, setVerified] = useState<boolean>(false);
    const { lang } = useContext(langContext);
    const search = useLocation().search;
    const vCode = new URLSearchParams(search).get('verificationCode');

    useEffect(() => {
        let token = $("meta[name='_csrf']").attr("content");
        let headerName = $("meta[name='_csrf_header']").attr("content");

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader(headerName!, token!);
            }
        });

        $.ajax({
            url: Constants.BASE_URL + 'api/user/verify/' + vCode,
            method: 'POST',
            processData: false,
            contentType: 'application/json'
        }).done(function (data) {

            setVerifying(false);
            const r: AResponse<String> = data;
            if (r.isError) {
                alert(r.message);
            } else {
                setVerified(true);
            }

            setTimeout(() => {
                history.replace('/sign-in');
            }, 5000);
        });
    }, [vCode])
    return (
        <Container className={classes.root}>
            <Box className={classes.alienBox}>
                <img className={classes.alien} src={'/img/aliens/150/happy4-150px.png'}></img>
            </Box>
            <Box className={classes.headerBox}>
                {verifying && <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.VERIFY_VERIFYING)}</Typography>}
                {!verifying && verified && <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.VERIFY_VERIFIED)}</Typography>}
                {!verifying && !verified && <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.VERIFY_PROBLEM)}</Typography>}
            </Box>
        </Container>
    )
}

export default Verify
