import { AppBar, Box, ButtonBase, Container, IconButton, LinearProgress, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import React, { useContext, useEffect, useState } from "react";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import APIRequests from '../components/APIRequests';
import BellIconTutorial from '../components/BellIconTutorial';
import CacheManager from '../components/CacheManager';
import Constants from '../components/Constants';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import Menu from '../components/Menu';
import NotificationCard from '../components/NotificationCard';
import { authContext } from "../contexts/AuthContext";
import AuthType from "../contexts/AuthType";
import { encContext } from '../contexts/EncContext';
import { langContext } from '../contexts/LangContext';
import theme from '../CTheme';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import NotificationInfo from '../dto/response/NotificationInfo';
import ProductInfo from '../dto/response/ProductInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: '0',
        },
        title: {
            fontFamily: "'Comfortaa', sans-serif",
            fontSize: '1.5em',
            flexGrow: 1,
        },
        titleBox: {
            position: 'relative',
            top: '.2em'
        },
        titleImg: {
            maxWidth: '15rem'
        },
        betaImg: {
            maxWidth: '2rem',
            position: 'absolute',
            right: '-2.5rem',
            bottom: '.1rem',
            borderRadius: '.3em',
        },
        logoBox: {
            maxWidth: '4em',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
            top: '.4em',
            right: '.5em',
            transform: 'rotate(10deg)'
        },
        logoImg: {
            maxWidth: '100%'
        },
        productCardWrapper: {
            padding: '0px',
        },
        linearProgress: {
            margin: '2em 0em'
        },
        listWrapper: {

        },
        firstLoadingWrapper: {
            margin: '2.5em 1em',
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        },
        firstLoadingAlien:{
            maxWidth:'150px'
        },
        firstLoadingText: {
            fontSize: '2em',
            fontWeight: 'normal',
            fontFamily: "'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            textAlign:'center'
        },
        firstLoadingSpinnerWrapper: {
            marginTop: '5em',
            display: 'flex',
            justifyContent: 'center'
        },
        noNotifications: {
            marginTop: '2em'
        },
        noNotifsBtnBox: {
            marginTop: '1em',
            display: 'flex',
            flexDirection: 'column',
        },
        noNotifsBtn: {
            marginBottom: '1em'
        },
        searchBtnBase: {
            position: 'fixed',
            bottom: '2em',
            right: '2em',
        },
        searchBtnBaseWideL: {
            position: 'fixed',
            bottom: '2em',
            right: '32%',
        },
        searchBtnBaseWideS: {
            position: 'fixed',
            bottom: '2em',
            right: '19%',
        },
        searchButtonInner: {
            width: '3em',
            height: '3em',
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '3em',
            boxShadow: theme.shadows[3],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

        },
        checkForNotifsBtn: {
            marginTop: '1em'
        }
    }),
);

const Home = () => {
    const [wide, setWide] = useState<boolean>(window.innerWidth > window.innerHeight);
    const [smallWide, setSmallWide] = useState<boolean>(window.innerWidth <= 1000);

    const windowResizeHandler = () => {
        setWide(window.innerWidth > window.innerHeight);
        setSmallWide(window.innerWidth <= 1000);
    };

    useEffect(() => {
        window.addEventListener('resize', windowResizeHandler);

        let timeInt = setInterval(() => {
            setCurrentTime((new Date()).getTime());
        }, 10000);
        return () => {
            clearInterval(timeInt);
            window.removeEventListener('resize', windowResizeHandler);
        };
    }, []);

    const classes = useStyles();
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { setAuthData, auth } = useContext(authContext);
    const { getKey } = useContext(encContext);
    const { lang } = useContext(langContext);
    const [notifs, setNotifs] = useState<NotificationInfo[]>([]);
    const [notifsHasMore, setNotifsHasMore] = React.useState<boolean>(false);
    const [notifsPage, setNotifsPage] = React.useState<number>(0);
    const [loadingNotifs, setLoadingNotifs] = React.useState<boolean>(true);
    const [loadingTA, setLoadingTA] = React.useState<boolean>(true);
    const [trackingAnything, setTrackingAnything] = React.useState<boolean>(false);
    const [showCheckForNewNotifs, setShowCheckForNotifs] = React.useState<boolean>(false);
    const [currentTime, setCurrentTime] = React.useState<number>((new Date()).getTime());
    const [idb, setDB] = React.useState<IDBDatabase | undefined>();
    const [idbSupport, setIDBSupport] = React.useState<boolean | undefined>();
    const [bellTutorialOpen, setBellTutorialOpen] = React.useState<boolean>(false);
    const [alienImgVariant, setAlienImageVariant] = React.useState<number>(1);

    useEffect(() => {
        if (window.indexedDB) {
            setIDBSupport(true);
            CacheManager.initDB().then(db => { setDB(db) });
        } else {
            setIDBSupport(false);
        }

        setAlienImageVariant(1 + Math.round((Math.random() * 7)));
    }, []);

    useEffect(() => {
        if (idb) {
            CacheManager.getNotifCache(idb, getKey).catch((x) => {
                setShowCheckForNotifs(true);
            });
        }
    }, [currentTime]);

    useEffect((() => {
        if (idb && idbSupport === true) {
            CacheManager.getNotifCache(idb, getKey).then(currentHN => {
                if (currentHN?.notifications.length == 0) {
                    throw "Cache is empty";
                }

                if (currentHN) {
                    if ((currentTime - currentHN.lastUpdate) / 1000 / 60 > CacheManager.notifsCacheValidityInMinutes) {
                        throw "Cache is outdated";
                    }
                }

                if (currentHN) {
                    setNotifs(currentHN.notifications);
                    setNotifsPage(currentHN.notifsPage);
                    setTrackingAnything(currentHN.trackingAnything);
                    setLoadingNotifs(false);
                    setLoadingTA(false);
                    setNotifsHasMore(true);
                } else {
                    loadFromWeb();
                }
            }).catch((x) => {
                CacheManager.deleteNotifCache(idb).then(() => {
                    loadFromWeb();
                });
            });
        }

        if (idbSupport === false) {
            loadFromWeb();
        }
    }), [idb, idbSupport]);

    const loadFromWeb = () => {
        setLoadingNotifs(true);
        setLoadingTA(true);
        setNotifsHasMore(true);
        loadNotifs();
        loadTrackingAnything();
    }

    useEffect(() => {
        if (idb) {
            CacheManager.getNotifCache(idb, getKey).then(currentHN => {
                CacheManager.updateNotifCache(idb, getKey, currentHN, notifs, notifsPage, trackingAnything);
            }).catch(() => {
                CacheManager.updateNotifCache(idb, getKey, undefined, notifs, notifsPage, trackingAnything);
            });
        }
    }, [notifs, notifsPage, trackingAnything, idb]);

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode === 503) {
            logout();
        }
    };

    const toggleDrawer = (toggleVal: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setMenuOpen(toggleVal);
    };

    const refreshNotifs = () => {
        CacheManager.deleteNotifCache(idb).then(() => {
            setNotifs([]);
            setNotifsPage(0);
            setNotifsHasMore(true);
            setShowCheckForNotifs(false);
        });
    };

    const loadNotifs = () => {
        if (loadingNotifs) return;
        setLoadingNotifs(true);
        const req = new ARequest<number>(auth.token);
        req.data = notifsPage;
        setNotifsPage(page => page + 1);
        APIRequests.post(Constants.BASE_URL + "api/tracker/products/notifications", req, function (data: any) {
            setLoadingNotifs(false);
            const r: AResponse<NotificationInfo[]> = data;
            if (r.isError) {
                handleError(r);
            } else {
                if (r.data.length > 0) {
                    setNotifsHasMore(true);
                } else {
                    setNotifsHasMore(false);
                }

                if (r.data.length > 0) {
                    setNotifs(prods => prods.concat(r.data));
                }
            }
        });
    };

    const loadTrackingAnything = () => {
        const req = new ARequest<string>(auth.token);
        req.data = '';

        setLoadingTA(true);
        APIRequests.post(Constants.BASE_URL + "api/tracker/products/trackingAnything", req, function (data: any) {
            setLoadingNotifs(false);
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
            } else {
                setTrackingAnything(r.data);
            }
            setLoadingTA(false);
        });
    };

    // useEffect(() => {
    //     loadNotifs();
    //     loadTrackingAnything();
    // }, [auth]);

    const getFirstLoadMsgVariant = () => {
        let ix = alienImgVariant % 4;
        switch(ix){
            case 0: return lm.get(lang, Labels.HOME_FIRST_LOAD1);
            case 1: return lm.get(lang, Labels.HOME_FIRST_LOAD2);
            case 2: return lm.get(lang, Labels.HOME_FIRST_LOAD3);
            case 3: return lm.get(lang, Labels.HOME_FIRST_LOAD4);
            default: return lm.get(lang, Labels.HOME_FIRST_LOAD1);
        }
    };

    const infiniteRef = useInfiniteScroll<HTMLDivElement>({
        hasNextPage: notifsHasMore,
        loading: loadingNotifs,
        onLoadMore: loadNotifs,
        scrollContainer: "window",
        threshold: 2000
    });

    const inputProgress = (<LinearProgress className={classes.linearProgress} />);

    const firstTimeProgress = (
        <div className={classes.firstLoadingWrapper}>
            <img className={classes.firstLoadingAlien} src={'/img/aliens/300/excited'+alienImgVariant+'-300px.png'}></img>
            <div className={classes.firstLoadingText}>{getFirstLoadMsgVariant()}</div>
            <div className={classes.firstLoadingSpinnerWrapper}><BounceLoader color={theme.palette.primary.main} /></div>
        </div>
    );

    const updateProdInfoCache = (pi: ProductInfo, deleteProdCache: boolean) => {
        CacheManager.updateProdInfoCacheInNotifs(idb, getKey, pi);
        if(deleteProdCache)        
            CacheManager.deleteMyProdsCache(idb);
        else
            CacheManager.updateProdInfoCacheInMyProds(idb, getKey, false, pi);
    };

    const deleteNotifFromCache = (ni: NotificationInfo) => {
        setNotifs(notifs => notifs.filter(x => x.id != ni.id));
        CacheManager.deleteNotifFromCache(idb, getKey, ni);
    };

    const notifList = (
        <Container className={classes.productCardWrapper} id='productCardWrapper' key='productCardWrapperKey'>
            {!loadingTA && notifs.length > 0 && showCheckForNewNotifs &&
                <Button
                    className={classes.checkForNotifsBtn}
                    variant='contained'
                    size='small'
                    fullWidth
                    color='primary'
                    onClick={refreshNotifs}
                >{lm.get(lang, Labels.HOME_CHECK_FOR_NEW_NOTIFS)}</Button>
            }
            <div ref={infiniteRef}>
                {notifs.map((x: NotificationInfo) => (
                    <NotificationCard
                        key={x.notificationDate.toString() + x.productInfo.asin}
                        notifInfo={x}
                        mobile={!wide}
                        updateProdCache={updateProdInfoCache}
                        deleteNotifFromCache={deleteNotifFromCache}
                        setOpenBellTutorial={setBellTutorialOpen}
                    />
                ))}
                
                {(loadingNotifs || loadingTA) && notifs.length <= 0 && firstTimeProgress}
                {loadingNotifs && notifs.length > 0 && inputProgress}
            </div>
        </Container>
    );


    const noNotificationsNoTrack = (
        <Container className={classes.noNotifications}>
            <Typography variant='h5'>{lm.get(lang, Labels.HOME_NO_NOTIFS_NO_TRACK)}</Typography>
            <Box className={classes.noNotifsBtnBox}>
                {/* <Button onClick={() => { history.push("/my-sites") }} className={classes.noNotifsBtn} fullWidth color='primary' variant='contained'>
                    {lm.get(lang, Labels.HOME_SELECT_YOUR_AMZ_STORES)}
                </Button> */}
                <Button onClick={() => { history.push("/settings") }} className={classes.noNotifsBtn} fullWidth color='primary' variant='contained'>
                    {lm.get(lang, Labels.HOME_SET_NOTIF_SETTS)}
                </Button>
                <Button onClick={() => { history.push("/my-products?a=search") }} className={classes.noNotifsBtn} fullWidth color='secondary' variant='contained'>
                    {lm.get(lang, Labels.HOME_START_TRK_PRODS)}
                </Button>
            </Box>
        </Container>
    );

    const noNotificationsTrack = (
        <Container className={classes.noNotifications}>
            <Typography variant='h5'>{lm.get(lang, Labels.HOME_NO_NOTIFS_TRACK)}</Typography>
            <Box className={classes.noNotifsBtnBox}>
                <Button
                    onClick={() => { history.push("/my-products?a=search") }}
                    className={classes.noNotifsBtn}
                    fullWidth
                    color='secondary'
                    variant='contained'
                >
                    {lm.get(lang, Labels.HOME_TRACK_MORE_PRODS)}
                </Button>
            </Box>
        </Container>
    );

    const noMoreNotifs = (
        <Container style={{ textAlign: 'center' }}>
            <Typography variant='h6'>{lm.get(lang, Labels.HOME_NO_MORE_NOTIFS)}</Typography>
        </Container>
    );



    return (
        <div className={classes.root}>
            <AppBar position="sticky" >
                <Toolbar variant='dense'>
                    <IconButton edge="start" className={classes.menuButton}
                        color="inherit" aria-label="menu"
                        onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Box className={classes.titleBox}>
                        <img className={classes.titleImg} src='/img/TitleWhite.png' />
                        <img className={classes.betaImg} src='/img/betatape.png'></img>
                    </Box>
                    <Box className={classes.logoBox}>
                        <img className={classes.logoImg} src='/img/Alien192.png' />
                    </Box>
                </Toolbar>
            </AppBar>
            <Menu open={menuOpen} toggleDrawer={toggleDrawer}></Menu>
            <Container className={classes.listWrapper}>
                {notifList}
                {/* {notifs.length > 0 && !notifsHasMore && noMoreNotifs} */}
                {!loadingTA && !loadingNotifs && notifs.length <= 0 && !notifsHasMore && !trackingAnything && noNotificationsNoTrack}
                {!loadingTA && !loadingNotifs && notifs.length <= 0 && !notifsHasMore && trackingAnything && noNotificationsTrack}
            </Container>

            <ButtonBase
                className={wide && !smallWide ? classes.searchBtnBaseWideL : wide ? classes.searchBtnBaseWideS : classes.searchBtnBase}
                centerRipple
                onClick={() => { history.push('my-products?a=search') }}
            >
                <div className={classes.searchButtonInner}>
                    <ZoomInIcon />
                </div>
            </ButtonBase>
            <BellIconTutorial
                open={bellTutorialOpen}
                setOpen={setBellTutorialOpen}
                showDontShowAgain={true}
            ></BellIconTutorial>
        </div>
    );
};

export default Home;