import React, { createContext, ReactNode, useEffect, useState } from 'react';
import Constants from '../components/Constants';
import AuthType from './AuthType';

interface IProps {
  children: ReactNode;
}

interface IAuthContext {
  auth: AuthType;
  setAuthData: (data: AuthType) => void;
}

export const authContext = createContext<IAuthContext>({ auth: new AuthType(), setAuthData: () => { } });

const AuthProvider = ({ children }: IProps) => {
  const [auth, setAuth] = useState(new AuthType());

  const setAuthData = (data: AuthType) => {
    setAuth(data);
  };

  useEffect(() => {    
    let jsonS = localStorage.getItem(Constants.TKN_CACHE);
    try{
      if(jsonS == null){
        let emptyAuth = new AuthType();
        emptyAuth.loading = false;     
        setAuth(emptyAuth);   
      } else {
        let authO = JSON.parse(jsonS) as AuthType;
        if(authO.token == null || authO.token.trim().length == 0){
          authO.loading = false;          
        }
        setAuth(authO);
      }
    }catch{
      let emptyAuth = new AuthType();
      emptyAuth.loading = false;
      setAuth(emptyAuth);
    }
  }, []);
  //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false. 
  //This function will be executed every time component is mounted (every time the user refresh the page);

  useEffect(() => {    
    localStorage.setItem(Constants.TKN_CACHE, JSON.stringify(auth));
  }, [auth]);
  // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

  return (
    <authContext.Provider value={{ auth, setAuthData }}>
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;