import { AppBar, Box, Button, Checkbox, createStyles, FormControl, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, MenuItem, Radio, Select, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import APIRequests from '../components/APIRequests';
import Constants from '../components/Constants';
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import theme from '../CTheme';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import NotificationPrefs from '../dto/user/NotificationPrefs';
import lm from '../components/LabelManager';
import { Labels } from '../components/enums/Labels';
import { langContext } from '../contexts/LangContext';
import NotifTime from '../dto/request/NotifTime';
import BroSnack from '../components/BroSnack';
import CacheManager from '../components/CacheManager';
import { encContext } from '../contexts/EncContext';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import WithHelp from '../components/WithHelp';
import GenericHelpDialog from '../components/GenericHelpDialog';
import { HelpSections } from '../components/enums/HelpSections';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notificationsWrapper: {
            padding: '1em'
        },
        emailNotificationsBox: {

        },
        emailNotificationsLabel: {
            display: 'inline-block'
        },
        loader: {
            position: 'relative',
            right: '.6em',
            bottom: '.1em'
        },
        dropDown: {
            width: '90%',
            marginBottom: '1.5em'
        },
        whBottomMargin: {
            marginBottom: '1.5em'
        },
        contentBox: {
            marginTop: '1.6em',
            marginLeft: '1em'
        },
        contentBox2: {
            marginTop: '1.6em',
            marginLeft: '1em',
            marginBottom: '1.5em'
        }
    }),
);

function NotificationSettings() {
    const history = useHistory();
    const classes = useStyles();
    const { setAuthData, auth } = useContext(authContext);
    const { lang, setLang } = useContext(langContext);
    const [receiveEmails, setReceiveEmails] = useState<boolean>(false);
    const [receiveSMS, setReceiveSMS] = useState<boolean>(false);
    const [receivePriceDrop, setReceivePriceDrop] = useState<boolean>(false);
    const [freqEveryTime, setFreqEveryTime] = useState<boolean>(true);
    const [smsEnabled, setSmsEnabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [timeZone, setTimeZone] = useState<number>(0);
    const [emailTime, setEmailTime] = useState<number>(18);
    const [loadingClearImm, setLoadingClearImm] = useState<boolean>(false);
    const [sbMsg, setSbMsg] = useState<string | undefined>("");
    const [sbOpen, setSbOpen] = useState<boolean>(false);
    const [sbType, setSbType] = useState<'success' | 'warning'>('success');
    const [idb, setDB] = React.useState<IDBDatabase | undefined>();
    const [idbSupport, setIDBSupport] = React.useState<boolean | undefined>();
    const [selectedLang, setSelectedLang] = useState<number>(lang);
    const [prevSelectedLang, setPrevSelectedLang] = useState<number>(lang);
    const { getKey } = useContext(encContext);
    const [helpOpen, setHelpOpen] = useState<boolean>(false);
    const [helpSection, setHelpSection] = useState<HelpSections>(HelpSections.EMPTY);

    useEffect(() => {
        if (window.indexedDB) {
            setIDBSupport(true);
            CacheManager.initDB().then(db => { setDB(db) });
        } else {
            setIDBSupport(false);
        }
    }, []);

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode == 503) {
            logout();
        }
    };

    useEffect(() => {
        const req = new ARequest<string>(auth.token);
        APIRequests.post(Constants.BASE_URL + "api/user/notifications/getPrefs", req, function (data: any) {
            const r: AResponse<NotificationPrefs> = data;
            setLoading(false);
            if (r.isError) {
                handleError(r);
                setReceiveEmails(false);
                setReceiveSMS(false);
                setReceivePriceDrop(false);
                setFreqEveryTime(false);
            } else {
                setReceiveEmails(r.data.email);
                setReceiveSMS(r.data.sms);
                setReceivePriceDrop(r.data.priceDrop);
                setFreqEveryTime(r.data.freqEveryTime);
                setTimeZone(r.data.timeZone);
                setEmailTime(r.data.emailTime);
            }
        });
    }, [auth]);

    const emailClickHandler = () => {
        if (loading) return;
        setReceiveEmails(!receiveEmails);
        const req = new ARequest<boolean>(auth.token);
        req.data = !receiveEmails;
        APIRequests.post(Constants.BASE_URL + "api/user/notifications/email", req, function (data: any) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setReceiveEmails(!receiveEmails);
            } else {
                setReceiveEmails(r.data);
            }
        });
    }

    const smsClickHandler = () => {
        if (loading) return;
        setReceiveSMS(!receiveSMS);

        const req = new ARequest<boolean>(auth.token);
        req.data = !receiveSMS;
        APIRequests.post(Constants.BASE_URL + "api/user/notifications/sms", req, function (data: any) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setReceiveSMS(!receiveSMS);
            } else {
                setReceiveSMS(r.data);
            }
        });
    }

    const priceDropClickHandler = (all: boolean) => {
        if (loading) return;
        if (!receiveEmails && !receiveSMS) return;
        setReceivePriceDrop(all);

        const req = new ARequest<boolean>(auth.token);
        req.data = all;
        APIRequests.post(Constants.BASE_URL + "api/user/notifications/priceDrop", req, function (data: any) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setReceivePriceDrop(!receivePriceDrop);
            } else {
                setReceivePriceDrop(r.data);
            }
        });
    }

    const freqClickHandler = (all: boolean) => {
        if (loading) return;
        if (!receiveEmails && !receiveSMS) return;
        setFreqEveryTime(all);

        const req = new ARequest<boolean>(auth.token);
        req.data = all;
        APIRequests.post(Constants.BASE_URL + "api/user/notifications/freqEveryTime", req, function (data: any) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setFreqEveryTime(!freqEveryTime);
            } else {
                setFreqEveryTime(r.data);
            }
        });
    }

    const handleTimeZoneChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let val = event.target.value as number;
        setTimeZone(val);
        updateEmailTime(val, emailTime);
    };

    const handleEmailTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let val = event.target.value as number;
        setEmailTime(val);
        updateEmailTime(timeZone, val);
    };

    const updateEmailTime = (timeZone: number, emailTime: number) => {
        const req = new ARequest<NotifTime>(auth.token);
        req.data = new NotifTime();
        req.data.emailTime = emailTime;
        req.data.timeZone = timeZone;

        APIRequests.post(Constants.BASE_URL + "api/user/notifications/time", req, function (data: any) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
            }
        });
    };

    const clearImmediateNotifs = () => {
        if (loadingClearImm) return;
        setLoadingClearImm(true);
        const req = new ARequest<string>(auth.token);
        req.data = "";

        APIRequests.post(Constants.BASE_URL + "api/tracker/products/clearAlwaysNotify", req, function (data: any) {
            setLoadingClearImm(false);
            const r: AResponse<boolean> = data;
            if (r.isError) {
                setSbMsg(lm.get(lang, Labels.NSETTS_ERROR_CLEARED_IMM_NOTIFS));
                setSbType('warning');
                handleError(r);
            } else {
                setSbMsg(lm.get(lang, Labels.NSETTS_SUCCESS_CLEARED_IMM_NOTIFS));
                setSbType('success');
                CacheManager.clearImmediateNotifsInCache(idb, getKey);
            }
            setSbOpen(true);
        });
    };

    useEffect(() => {
        if (selectedLang === prevSelectedLang) return;
        const req = new ARequest<number>(auth.token);
        req.lang = lang;
        req.data = selectedLang;

        APIRequests.post(Constants.BASE_URL + "api/user/setLang", req, function (data: any) {
            const r: AResponse<string> = data;
            if (r.isError) {
                handleError(r);
            } else {
                setLang(selectedLang);
                setPrevSelectedLang(selectedLang);
            }
        });
    }, [auth, selectedLang]);

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar variant='dense'>
                    <IconButton edge="start" color="inherit" onClick={() => { history.push("/home") }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif" }} variant="h6">
                        {lm.get(lang, Labels.NSETTS_NOTIF_SETTINGS)}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box className={classes.notificationsWrapper}>
                <WithHelp position='endOfElement' section={HelpSections.SETTS_LANGUAGE} setSection={setHelpSection} setOpenHelp={setHelpOpen}>
                    <Typography variant="h6">{lm.get(lang, Labels.MACC_LANGUAGE)}</Typography>
                </WithHelp>
                <Box className={classes.contentBox}>
                    <FormControl className={classes.dropDown} variant="outlined">
                        <TextField
                            select
                            label={lm.get(lang, Labels.MACC_SELECT_LANGUAGE)}
                            variant='outlined'
                            size='small'
                            id="select-lang"
                            value={selectedLang}
                            onChange={
                                (event) => {
                                    setSelectedLang(parseInt(event.target.value, 10));
                                }
                            }
                        >
                            <MenuItem key="lang-enus" value={1}>{lm.get(lang, Labels.MACC_ENGLISH)}</MenuItem>
                            <MenuItem key="lang-esmx" value={2}>{lm.get(lang, Labels.MACC_SPANISH)}</MenuItem>
                        </TextField>
                    </FormControl>
                </Box>
                <WithHelp position='endOfElement' section={HelpSections.SETTS_NOTIF_TYPES} setSection={setHelpSection} setOpenHelp={setHelpOpen}>
                    <Typography variant="h6">{lm.get(lang, Labels.NSETTS_NOTIF_TYPES)}</Typography>
                </WithHelp>
                <List>
                    <ListItem button key="emailNotifications" onClick={emailClickHandler} >
                        <ListItemText primary={lm.get(lang, Labels.NSETTS_EMAIL_NOTIFS)} />
                        <ListItemSecondaryAction>
                            {loading ? (
                                <div className={classes.loader}>
                                    <PuffLoader size='2em' color={theme.palette.secondary.main}></PuffLoader>
                                </div>
                            ) : (
                                <IconButton edge="end" onClick={emailClickHandler}>
                                    <Checkbox checked={receiveEmails} />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                    {smsEnabled && (
                        <ListItem button key="smsNotifications" onClick={smsClickHandler} >
                            <ListItemText primary={lm.get(lang, Labels.NSETTS_SMS_NOTIFS)} />
                            <ListItemSecondaryAction>
                                {loading ? (
                                    <div className={classes.loader}>
                                        <PuffLoader size='2em' color={theme.palette.secondary.main}></PuffLoader>
                                    </div>
                                ) : (
                                    <IconButton edge="end" onClick={smsClickHandler}>
                                        <Checkbox checked={receiveSMS} />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
                <WithHelp position='endOfElement' section={HelpSections.SETTS_EMAIL_NOTIFS} setSection={setHelpSection} setOpenHelp={setHelpOpen}>
                    <Typography variant="h6">{lm.get(lang, Labels.NSETTS_NOTIF_EVENTS)}</Typography>
                </WithHelp>
                <List>
                    <ListItem button key="priceDropNotifications" onClick={() => priceDropClickHandler(true)} >
                        <ListItemText primary={lm.get(lang, Labels.NSETTS_PRICE_DROP_EV)} />
                        <ListItemSecondaryAction>
                            {loading ? (
                                <div className={classes.loader}>
                                    <PuffLoader size='2em' color={theme.palette.secondary.main}></PuffLoader>
                                </div>
                            ) : (
                                <IconButton edge="end" onClick={() => priceDropClickHandler(true)}>
                                    <Radio checked={receivePriceDrop} disabled={!receiveEmails && !receiveSMS} />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button key="targetNotifications" onClick={() => priceDropClickHandler(false)} >
                        <ListItemText primary={lm.get(lang, Labels.NSETTS_TARGET_REACHED_EV)} />
                        <ListItemSecondaryAction>
                            {loading ? (
                                <div className={classes.loader}>
                                    <PuffLoader size='2em' color={theme.palette.secondary.main}></PuffLoader>
                                </div>
                            ) : (
                                <IconButton edge="end" onClick={() => priceDropClickHandler(false)}>
                                    <Radio checked={!receivePriceDrop} disabled={!receiveEmails && !receiveSMS} />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <WithHelp position='endOfElement' section={HelpSections.SETTS_EMAIL_FREQ} setSection={setHelpSection} setOpenHelp={setHelpOpen}>
                    <Typography variant="h6">{lm.get(lang, Labels.NSETTS_NOTIF_FREQUENCY)}</Typography>
                </WithHelp>
                <List>
                    <ListItem button key="everyTime" onClick={() => freqClickHandler(true)}>
                        <ListItemText primary={lm.get(lang, Labels.NSETTS_EVERY_TIME_PRICE_CHANGES)} />
                        <ListItemSecondaryAction>
                            {loading ? (
                                <div className={classes.loader}>
                                    <PuffLoader size='2em' color={theme.palette.secondary.main}></PuffLoader>
                                </div>
                            ) : (
                                <IconButton edge="end" onClick={() => freqClickHandler(true)}>
                                    <Radio checked={freqEveryTime} disabled={!receiveEmails && !receiveSMS} />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button key="onceADay" onClick={() => freqClickHandler(false)}>
                        <ListItemText primary={lm.get(lang, Labels.NSETTS_ONCE_A_DAY)} />
                        <ListItemSecondaryAction>
                            {loading ? (
                                <div className={classes.loader}>
                                    <PuffLoader size='2em' color={theme.palette.secondary.main}></PuffLoader>
                                </div>
                            ) : (
                                <IconButton edge="end" onClick={() => freqClickHandler(false)}>
                                    <Radio checked={!freqEveryTime} disabled={!receiveEmails && !receiveSMS} />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                {!freqEveryTime && receiveEmails && 
                    <WithHelp position='endOfElement' section={HelpSections.SETTS_WHEN_EMAIL} setSection={setHelpSection} setOpenHelp={setHelpOpen}>
                        <Typography variant="h6">{lm.get(lang, Labels.NSETTS_WHEN_TO_GET_EMAIL)}</Typography>
                    </WithHelp>
                }
                {!freqEveryTime && receiveEmails &&
                    <Box className={classes.contentBox}>
                        <FormControl className={classes.dropDown} variant="outlined">
                            <TextField
                                select
                                label={lm.get(lang, Labels.NSETTS_TIME_ZONE)}
                                variant='outlined'
                                size='small'
                                id="select-tz"
                                value={timeZone}
                                onChange={handleTimeZoneChange}
                            >
                                {
                                    [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                                        .map(v => <MenuItem key={"gmt" + v} value={v}>GMT {v > 0 ? '+' : ''}{v == 0 ? '' : v}</MenuItem>)
                                }

                            </TextField>
                        </FormControl>
                        <FormControl className={classes.dropDown} variant="outlined">
                            <TextField
                                select
                                label={lm.get(lang, Labels.NSETTS_TIME_OF_DAY)}
                                variant='outlined'
                                size='small'
                                id="select-time"
                                value={emailTime}
                                onChange={handleEmailTimeChange}
                            >
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
                                        .map(v => <MenuItem key={"et" + v} value={v}>{v - 12 <= 0 ? v + 'AM' : (v - 12) + 'PM'}</MenuItem>)
                                }

                            </TextField>
                        </FormControl>
                    </Box>
                }
                <WithHelp position='endOfElement' section={HelpSections.SETTS_OTHER_OPTS} setSection={setHelpSection} setOpenHelp={setHelpOpen}>
                    <Typography variant="h6">{lm.get(lang, Labels.NSETTS_OTHER_OPTS)}</Typography>
                </WithHelp>
                <Box className={classes.contentBox}>
                    <Button
                        style={{ marginRight: '1em' }}
                        onClick={clearImmediateNotifs}
                        variant='contained'
                        size='small'
                        color='primary'
                        disabled={loadingClearImm}
                        endIcon={<NotificationImportantIcon />}
                        fullWidth
                    >
                        {(!loadingClearImm ? lm.get(lang, Labels.NSETTS_CLEAR_IMMEDIATE_NOTIFS) : <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} />)}
                    </Button>
                </Box>
            </Box>
            <GenericHelpDialog open={helpOpen} setOpen={setHelpOpen} section={helpSection}></GenericHelpDialog>
            <BroSnack
                autoHideDuration={6000}
                open={sbOpen}
                message={sbMsg}
                snackType={sbType}
                onSnackClose={() => { setSbOpen(false) }}
            />
        </div >
    )
}

export default NotificationSettings
