import NotificationInfo from "./NotificationInfo";
import ProductImage from "./ProductImage";
import ProductSearch from "../request/ProductSearch";
import ProductTrackInfo from "./ProductTrackInfo";

export default class ProductInfo {
    asin: string;
    url: string;
    smallImages: ProductImage[];
    mediumImages: ProductImage[];
    largeImages: ProductImage[];
    primaryImageUrl: string;
    primaryImageLarge: ProductImage;
    primaryImageMedium: ProductImage;
    primaryImageSmall: ProductImage;
    brand: string;
    manufacturer: string;
    features: string[];
    itemPartNumber: string;
    model: string;
    warranty: string;
    title: string;
    price: string;
    priceAmount:number;
    currency: string;
    prime: boolean;
    freeShipping: boolean;
    amazonFulfilled: boolean;
    availability: string;
    binding:string;
    contributors:string[];
    trackInfo:ProductTrackInfo;    
    marketplaceKey:string;
    alwaysNotify: boolean;

    static getLargestPrimary(prodInfo:ProductInfo):ProductImage {
        if(prodInfo.primaryImageLarge.hasImage) return prodInfo.primaryImageLarge;
        if(prodInfo.primaryImageMedium.hasImage) return prodInfo.primaryImageMedium;
        if(prodInfo.primaryImageSmall.hasImage) return prodInfo.primaryImageSmall;

        return prodInfo.primaryImageSmall;
    }

    static getSmallestPrimary(prodInfo:ProductInfo):ProductImage {
        if(prodInfo.primaryImageSmall.hasImage) return prodInfo.primaryImageSmall;
        if(prodInfo.primaryImageMedium.hasImage) return prodInfo.primaryImageMedium;
        if(prodInfo.primaryImageLarge.hasImage) return prodInfo.primaryImageLarge;

        return prodInfo.primaryImageSmall;
    }

    static getMediumPrimary(prodInfo:ProductInfo):ProductImage {
        if(prodInfo.primaryImageMedium.hasImage) return prodInfo.primaryImageMedium;
        if(prodInfo.primaryImageSmall.hasImage) return prodInfo.primaryImageSmall;
        if(prodInfo.primaryImageLarge.hasImage) return prodInfo.primaryImageLarge;

        return prodInfo.primaryImageSmall;
    }

    static getSecondaryImages(prodInfo:ProductInfo):ProductImage[]{
        if(prodInfo.largeImages.length > 0) return prodInfo.largeImages;
        if(prodInfo.mediumImages.length > 0) return prodInfo.mediumImages;
        if(prodInfo.smallImages.length > 0) return prodInfo.smallImages;
        
        return prodInfo.smallImages;
    }

    static getAllImages(prodInfo:ProductInfo):ProductImage[] {
        let imgs:ProductImage[] = [];
        imgs.push(ProductInfo.getLargestPrimary(prodInfo));
        imgs = imgs.concat(ProductInfo.getSecondaryImages(prodInfo));

        return imgs;
    }

    static getFormattedContributors(prodInfo:ProductInfo):string{
        let s:string = "";
        prodInfo.contributors.forEach((x) => s+=x+', ');
        s = s.substr(0,s.length-2);
        return s;
    }
}