import { Labels } from "./enums/Labels";
import { Languages } from "./enums/Languages";

export default class LabelManager {
    private static enUsLabels: Map<Labels, string>;
    private static esMxLabels: Map<Labels, string>;
    private static allLabels: Map<Languages, Map<Labels, string>>;

    public static get(lang: Languages, label: Labels): string | undefined {
        if (this.allLabels == undefined || this.allLabels.size === 0) {
            this.loadLabels();
        }

        if (this.allLabels.get(lang) == undefined) {
            return this.allLabels.get(Languages.EN_US)?.get(label);
        }

        return this.allLabels.get(lang)?.get(label);
    }

    private static loadLabels() {
        this.allLabels = new Map<Languages, Map<Labels, string>>();
        this.enUsLabels = new Map<Labels, string>();
        this.esMxLabels = new Map<Labels, string>();

        this.allLabels.set(Languages.EN_US, this.enUsLabels);
        this.allLabels.set(Languages.ES_MX, this.esMxLabels);


        //=======================
        //======= ENGLISH =======
        //=======================
        this.enUsLabels.set(Labels.EMPTY, "");

        this.enUsLabels.set(Labels.APP_NEW_VERSION_AVAILABLE, "A new version of the site is available.");
        this.enUsLabels.set(Labels.APP_GET_NEW_VERSION, "GET NEW VERSION");

        this.enUsLabels.set(Labels.HOME_FIRST_LOAD1, "Compiling your most recent discounts...");
        this.enUsLabels.set(Labels.HOME_FIRST_LOAD2, "Creating your personalized deals page...");
        this.enUsLabels.set(Labels.HOME_FIRST_LOAD3, "Contacting the mothership for the best deals available...");
        this.enUsLabels.set(Labels.HOME_FIRST_LOAD4, "Analysing our database for your best deals...");
        this.enUsLabels.set(Labels.HOME_NO_NOTIFS_NO_TRACK, "Hi! Start tracking products to get notifications on price drops.");
        this.enUsLabels.set(Labels.HOME_NO_NOTIFS_TRACK, "There haven't been any price drops for the products you are tracking.");
        this.enUsLabels.set(Labels.HOME_NO_MORE_NOTIFS, "You have no more notifications");
        this.enUsLabels.set(Labels.HOME_TRACK_MORE_PRODS, "Track more products");
        this.enUsLabels.set(Labels.HOME_CHECK_FOR_NEW_NOTIFS, "Check for new notifications");
        this.enUsLabels.set(Labels.HOME_SELECT_YOUR_AMZ_STORES, "Select Your Amazon Stores");
        this.enUsLabels.set(Labels.HOME_SET_NOTIF_SETTS, "Set Your Notification Settings");
        this.enUsLabels.set(Labels.HOME_START_TRK_PRODS, "Start Tracking Products");

        this.enUsLabels.set(Labels.MYPR_ALL_ST, "All");
        this.enUsLabels.set(Labels.MYPR_STORE, "Store");
        this.enUsLabels.set(Labels.MYPR_CATEGORY, "Category");
        this.enUsLabels.set(Labels.MYPR_FIRST_LOAD, "We are loading your tracked items...");
        this.enUsLabels.set(Labels.MYPR_NO_TRACKED, "Start tracking products to see them here.");
        this.enUsLabels.set(Labels.MYPR_NO_TRACKED_BTN, "Start Tracking Products");
        this.enUsLabels.set(Labels.MYPR_NO_STORE_SELECTED, "Select your Amazon stores to search for products.");
        this.enUsLabels.set(Labels.MYPR_NO_STORE_SELECTED_BTN, "Select your amazon stores");
        this.enUsLabels.set(Labels.MYPR_MY_PRODUCTS, "My Products");
        this.enUsLabels.set(Labels.MYPR_SEARCH, "Search");
        this.enUsLabels.set(Labels.MYPR_SEARCHBOX_PLACEHOLDER, "Search or URL");
        this.enUsLabels.set(Labels.MYPR_NEXT_P, "Next Page");
        this.enUsLabels.set(Labels.MYPR_PREV_P, "Previous Page");
        this.enUsLabels.set(Labels.MYPR_PRIME, "Prime");
        this.enUsLabels.set(Labels.MYPR_FREE_SHIPPING, "Free shipping");
        this.enUsLabels.set(Labels.MYPR_FILTERS, "Filters");
        this.enUsLabels.set(Labels.MYPR_MORE_OPTIONS, "More Options");
        this.enUsLabels.set(Labels.MYPR_SORTBY, "Sort by");
        this.enUsLabels.set(Labels.MYPR_SORTBY_FEATURED, "Featured");
        this.enUsLabels.set(Labels.MYPR_SORTBY_RELEVANCE, "Relevance");
        this.enUsLabels.set(Labels.MYPR_SORTBY_PRICE_HTL, "Price High to Low");
        this.enUsLabels.set(Labels.MYPR_SORTBY_PRICE_LTH, "Price Low to High");
        this.enUsLabels.set(Labels.MYPR_SORTBY_NEWEST, "Newest Arrivals");

        this.enUsLabels.set(Labels.MYST_MY_STORES, "My Stores");

        this.enUsLabels.set(Labels.NSETTS_NOTIF_SETTINGS, "Settings");
        this.enUsLabels.set(Labels.NSETTS_NOTIF_EVENTS, "Email notifications");
        this.enUsLabels.set(Labels.NSETTS_NOTIF_TYPES, "Notification types");
        this.enUsLabels.set(Labels.NSETTS_EMAIL_NOTIFS, "Email notifications");
        this.enUsLabels.set(Labels.NSETTS_SMS_NOTIFS, "Sms notifications");
        this.enUsLabels.set(Labels.NSETTS_PRICE_DROP_EV, "All price drops");
        this.enUsLabels.set(Labels.NSETTS_TARGET_REACHED_EV, "Only target price reached");
        this.enUsLabels.set(Labels.NSETTS_NOTIF_FREQUENCY, "Email frequency");
        this.enUsLabels.set(Labels.NSETTS_EVERY_TIME_PRICE_CHANGES, "Every time there is a price drop");
        this.enUsLabels.set(Labels.NSETTS_ONCE_A_DAY, "Once per day");
        this.enUsLabels.set(Labels.NSETTS_WHEN_TO_GET_EMAIL, "When to receive email");
        this.enUsLabels.set(Labels.NSETTS_TIME_ZONE, "Time zone");
        this.enUsLabels.set(Labels.NSETTS_TIME_OF_DAY, "Time of day");
        this.enUsLabels.set(Labels.NSETTS_OTHER_OPTS, "Other options");
        this.enUsLabels.set(Labels.NSETTS_CLEAR_IMMEDIATE_NOTIFS, "Clear all priority email notifications");
        this.enUsLabels.set(Labels.NSETTS_SUCCESS_CLEARED_IMM_NOTIFS, "All your priority notifications have been cleared.");
        this.enUsLabels.set(Labels.NSETTS_ERROR_CLEARED_IMM_NOTIFS, "There was a problem clearing your priority notifications. Try again later.");

        this.enUsLabels.set(Labels.LOGIN_EMAIL, "Email address");
        this.enUsLabels.set(Labels.LOGIN_PASSWORD, "Password");
        this.enUsLabels.set(Labels.LOGIN_FORGOT_PASS, "Forgot password?");
        this.enUsLabels.set(Labels.LOGIN_REMEMBER, "Remember me");
        this.enUsLabels.set(Labels.LOGIN_SIGNIN, "Sign In");
        this.enUsLabels.set(Labels.LOGIN_SIGNING_IN, "Signing you in...");
        this.enUsLabels.set(Labels.LOGIN_SIGNUP, "Don't have an account? Sign Up");
        this.enUsLabels.set(Labels.LOGIN_OR, "Or");

        this.enUsLabels.set(Labels.SIGNUP_SIGNUP, "Sign Up");
        this.enUsLabels.set(Labels.SIGNUP_EMAIL, "Email address");
        this.enUsLabels.set(Labels.SIGNUP_PASSWORD, "Password");
        this.enUsLabels.set(Labels.SIGNUP_CPASSWORD, "Confirm password");
        this.enUsLabels.set(Labels.SIGNUP_SIGNIN, "Already have an account? Sign in");

        this.enUsLabels.set(Labels.MENU_LOGOUT, "Sign out");
        this.enUsLabels.set(Labels.MENU_MY_PRODUCTS, "My Products");
        this.enUsLabels.set(Labels.MENU_MY_STORES, "My Stores");
        this.enUsLabels.set(Labels.MENU_NOTIF_SETTS, "Settings");
        this.enUsLabels.set(Labels.MENU_SEARCH, "Search");
        this.enUsLabels.set(Labels.MENU_MYACCOUNT, "My Account");
        this.enUsLabels.set(Labels.MENU_ABOUT, "About");
        this.enUsLabels.set(Labels.MENU_HELP, "Help");
        this.enUsLabels.set(Labels.MENU_PRIVACY, "Privacy Policy");
        this.enUsLabels.set(Labels.MENU_TERMS, "Terms of Use");

        this.enUsLabels.set(Labels.NC_NEW_PRICE, "NEW PRICE");
        this.enUsLabels.set(Labels.NC_PRICE_DROP, "PRICE DROP");
        this.enUsLabels.set(Labels.NC_TARGET_REACHED, "TARGET PRICE REACHED!");
        this.enUsLabels.set(Labels.NC_YOUR_TARGET_PRICE, "YOUR TARGET PRICE");
        this.enUsLabels.set(Labels.NC_NO_TARGET_SET, "NO TARGET PRICE SET");

        this.enUsLabels.set(Labels.C_BUY_ON_AMZ, "Buy on Amazon");
        this.enUsLabels.set(Labels.C_BUY_ON_AMZ_SH, "Buy");
        this.enUsLabels.set(Labels.C_VIEW_DETAILS, "View Details");
        this.enUsLabels.set(Labels.C_PRIME, "Prime");
        this.enUsLabels.set(Labels.C_CURRENT_PRICE, "Current price");
        this.enUsLabels.set(Labels.C_PRICE_WHEN_START_FOLLOW, "Price when you started tracking this product");
        this.enUsLabels.set(Labels.C_TARGET_PRICE, "Target price");
        this.enUsLabels.set(Labels.C_SET_TARGET_PRICE, "Set target price");
        this.enUsLabels.set(Labels.C_TRACKING_SINCE, "Tracking since");
        this.enUsLabels.set(Labels.C_TRACK, "Track");
        this.enUsLabels.set(Labels.C_UNTRACK, "Untrack");
        this.enUsLabels.set(Labels.C_DETAILS, "Details");
        this.enUsLabels.set(Labels.C_PRICE_HISTORY, "Price History");
        this.enUsLabels.set(Labels.C_ABOUT_ITEM, "About this item");
        this.enUsLabels.set(Labels.C_YOUR_TARGET_PRICE, "Your target price");
        this.enUsLabels.set(Labels.C_NO_TARGET_SET, "No target price set");

        this.enUsLabels.set(Labels.PH_PRICE_HISTORY, "Price History");
        this.enUsLabels.set(Labels.PH_PRICE, "Price");
        this.enUsLabels.set(Labels.PH_DATE, "Date");
        this.enUsLabels.set(Labels.PH_MORE, "More");
        this.enUsLabels.set(Labels.PH_NO_HISTORY_YET, "We don't have a price history for this product yet. Start tracking this product to start generating one.");
        this.enUsLabels.set(Labels.PH_LOW_30D, "Lowest past 30 days");
        this.enUsLabels.set(Labels.PH_HIGH_30D, "Highest past 30 days");
        this.enUsLabels.set(Labels.PH_LOW_YEAR, "Lowest past year");
        this.enUsLabels.set(Labels.PH_HIGH_YEAR, "Highest past year");

        this.enUsLabels.set(Labels.RP_RESET_PASSWORD, "Reset Password");
        this.enUsLabels.set(Labels.RP_EMAIL, "Email address");
        this.enUsLabels.set(Labels.RP_RESET_BTN, "Reset Password");
        this.enUsLabels.set(Labels.RP_RESETTING_PASS, "Resetting your password...");
        this.enUsLabels.set(Labels.RP_PASS_HAS_BEEN_RESET, "Your password has been reset! We've sent you an e-mail with your new password. You'll be redirected to the sign in page in just a moment...");
        this.enUsLabels.set(Labels.RP_PROBLEM_RESETTING, "There was a problem resetting your password.");

        this.enUsLabels.set(Labels.NOTICE_RESET_PASSWORD, "We've sent you an email with instructions on how to reset your password. You may close this tab now.");
        this.enUsLabels.set(Labels.NOTICE_VERIFY, "We've sent you an email with instructions on how to verify your account. You may close this tab now.");
        this.enUsLabels.set(Labels.NOTICE_OR, "or...");
        this.enUsLabels.set(Labels.NOTICE_GO_BACK, "Go Back");

        this.enUsLabels.set(Labels.VERIFY_VERIFYING, "Verifying...");
        this.enUsLabels.set(Labels.VERIFY_VERIFIED, "Verified! You'll be redirected to the sign in page in just a moment...");
        this.enUsLabels.set(Labels.VERIFY_PROBLEM, "There was a problem verifying your account.");
        
        this.enUsLabels.set(Labels.MACC_MY_ACCOUNT, "My Account");
        this.enUsLabels.set(Labels.MACC_CHANGE_PASS, "Change Password");
        this.enUsLabels.set(Labels.MACC_CURRENT_PASS, "Current password");
        this.enUsLabels.set(Labels.MACC_EMAIL, "E-Mail Address");        
        this.enUsLabels.set(Labels.MACC_NEW_PASS, "New password");
        this.enUsLabels.set(Labels.MACC_NEW_PASS_CONFIRM, "Confirm new password");
        this.enUsLabels.set(Labels.MACC_SUBMIT_PASS, "Submit password change");  
        this.enUsLabels.set(Labels.MACC_PASS_UPDATED, "Your password has been updated.");  
        this.enUsLabels.set(Labels.MACC_CURR_PASS_INCORRECT, "Your current password is incorrect. The password was not changed.");  
        this.enUsLabels.set(Labels.MACC_NEW_PASS_DONT_MATCH, "Your new password does not match the confirmation. The password was not changed.");  
        this.enUsLabels.set(Labels.MACC_PROBLEM_UPDATING_PASS, "There was a problem changing your password. Try again later.");
        this.enUsLabels.set(Labels.MACC_LANGUAGE, "Language");
        this.enUsLabels.set(Labels.MACC_SELECT_LANGUAGE, "Select your language");
        this.enUsLabels.set(Labels.MACC_ENGLISH, "English");
        this.enUsLabels.set(Labels.MACC_SPANISH, "Spanish");
        this.enUsLabels.set(Labels.MACC_DELETE_ACCT, "Delete My Account");
        this.enUsLabels.set(Labels.MACC_CLICK_TO_DELETE_ACCT, "Click here to delete your account");
        this.enUsLabels.set(Labels.MACC_ACCOUNT_DELETED, "Your account has been deleted. Come back again soon!");
        this.enUsLabels.set(Labels.MACC_ACCOUNT_DELETE_WARNING, "Deleting your account is permanent, and cannot be undone. Click the button again to delete your account permanently.");
        
        this.enUsLabels.set(Labels.AZD_TAKING_YOU_TO_AMAZON, "We're taking you to Amazon...");
        this.enUsLabels.set(Labels.AZD_CLICK_TO_GO_NOW, "Click here to go now");

        this.enUsLabels.set(Labels.OM_ONE_MOMENT_PLEASE, "One moment please...");

        this.enUsLabels.set(Labels.ABT_TITLE, "About");

        this.enUsLabels.set(Labels.PP_TITLE, "Privacy policy");

        this.enUsLabels.set(Labels.TOS_TITLE, "Terms of use");

        this.enUsLabels.set(Labels.DEL_TITLE, "How to delete your account");

        this.enUsLabels.set(Labels.HLP_TITLE, "Help");                
        this.enUsLabels.set(Labels.HLP_CONTACT_US, "Contact us");        
        this.enUsLabels.set(Labels.HLP_EMAIL, "Email address");
        this.enUsLabels.set(Labels.HLP_MSG, "Message");
        this.enUsLabels.set(Labels.HLP_SEND, "Send");
        this.enUsLabels.set(Labels.HLP_SENT_SUCCESS, "Your message has been sent.");
        this.enUsLabels.set(Labels.HLP_BELL_BUTTON_TITLE, "What does this button do?");
        this.enUsLabels.set(Labels.HLP_BELL_BUTTON_BTN_TEXT, "Click here to learn");
        this.enUsLabels.set(Labels.HLP_SEARCH_TITLE, "Search Options");
        this.enUsLabels.set(Labels.HLP_SEARCH_STORE, "Store");
        this.enUsLabels.set(Labels.HLP_SEARCH_CATEGORY, "Category");
        this.enUsLabels.set(Labels.HLP_SEARCH_FILTERS, "Filters");
        this.enUsLabels.set(Labels.HLP_SEARCH_SEARCH, "Search or Url");
        this.enUsLabels.set(Labels.HLP_SETTS_TITLE, "Settings Options");
        this.enUsLabels.set(Labels.HLP_SETTS_LANG, "Language");
        this.enUsLabels.set(Labels.HLP_SETTS_NOTIF_TYPES, "Notification Types");
        this.enUsLabels.set(Labels.HLP_SETTS_EMAIL_NOTIFS, "Email Notifications");
        this.enUsLabels.set(Labels.HLP_SETTS_EMAIL_FREQ, "Email Frequency");
        this.enUsLabels.set(Labels.HLP_SETTS_EMAIL_WHEN, "When to receive email");
        this.enUsLabels.set(Labels.HLP_SETTS_OTHER, "Other options");

        this.enUsLabels.set(Labels.LAND_SUBTITLE_1, "Track prices on Amazon products and buy cheap");
        this.enUsLabels.set(Labels.LAND_FAQ, "FAQ");
        this.enUsLabels.set(Labels.LAND_SIGN_IN, "Sign In With Email");
        this.enUsLabels.set(Labels.LAND_CREATE_ACCT, "Create Account With Email");

        this.enUsLabels.set(Labels.FAQ_SUBTITLE, "Frecuently Asked Questions");
        this.enUsLabels.set(Labels.FAQ_BACK, "Go Back");

        this.enUsLabels.set(Labels.COOKIE_CONSENT_WARN_TEXT, "Before you sign in, you must click the 'I Understand' button below.");
        this.enUsLabels.set(Labels.COOKIE_CONSENT_INFO_TEXT, "This website uses cookies to enhance the user experience.");
        this.enUsLabels.set(Labels.COOKIE_CONSENT_BUTTON, "I Understand");

        //=======================
        //======= ESPAÑOL =======
        //=======================

        this.esMxLabels.set(Labels.EMPTY, "");

        this.esMxLabels.set(Labels.APP_NEW_VERSION_AVAILABLE, "Una versión nueva del sitio está disponible.");
        this.esMxLabels.set(Labels.APP_GET_NEW_VERSION, "OBTEN LA VERSION NUEVA");

        this.esMxLabels.set(Labels.HOME_FIRST_LOAD1, "¡Estoy recopilando tus descuentos más recientes!");
        this.esMxLabels.set(Labels.HOME_FIRST_LOAD2, "¡Analizando los mejores descuentos para ti!");
        this.esMxLabels.set(Labels.HOME_FIRST_LOAD3, "Creando tu página de descuentos personalizada...");
        this.esMxLabels.set(Labels.HOME_FIRST_LOAD4, "Contactando a la nave madre para ofrecerte los mejores descuentos...");
        this.esMxLabels.set(Labels.HOME_NO_NOTIFS_NO_TRACK, "¡Hola! Empieza a rastrear productos para tener notificaciones sobre descuentos.");
        this.esMxLabels.set(Labels.HOME_NO_NOTIFS_TRACK, "No ha habido nigun descuento en los productos que estás rastreando.");
        this.esMxLabels.set(Labels.HOME_NO_MORE_NOTIFS, "No tienes más notificaciones");
        this.esMxLabels.set(Labels.HOME_TRACK_MORE_PRODS, "Rastrea más productos");
        this.esMxLabels.set(Labels.HOME_CHECK_FOR_NEW_NOTIFS, "Actualiza tus notificaciones");
        this.esMxLabels.set(Labels.HOME_SELECT_YOUR_AMZ_STORES, "Selecciona tus tiendas Amazon");
        this.esMxLabels.set(Labels.HOME_SET_NOTIF_SETTS, "Configura tus notificaciones");
        this.esMxLabels.set(Labels.HOME_START_TRK_PRODS, "Empieza a rastrear productos");

        this.esMxLabels.set(Labels.MYPR_ALL_ST, "Todas");
        this.esMxLabels.set(Labels.MYPR_STORE, "Tienda");
        this.esMxLabels.set(Labels.MYPR_CATEGORY, "Categoría");
        this.esMxLabels.set(Labels.MYPR_FIRST_LOAD, "Estamos cargando los productos que estas rastreando...");
        this.esMxLabels.set(Labels.MYPR_NO_TRACKED, "Empieza a rastrear productos para verlos aquí.");
        this.esMxLabels.set(Labels.MYPR_NO_TRACKED_BTN, "Empieza a rastrear productos");
        this.esMxLabels.set(Labels.MYPR_NO_STORE_SELECTED, "Selecciona tus tiendas de Amazon para buscar productos.");
        this.esMxLabels.set(Labels.MYPR_NO_STORE_SELECTED_BTN, "Selecciona tus tiendas de Amazon");
        this.esMxLabels.set(Labels.MYPR_MY_PRODUCTS, "Mis Productos");
        this.esMxLabels.set(Labels.MYPR_SEARCH, "Buscar");
        this.esMxLabels.set(Labels.MYPR_SEARCHBOX_PLACEHOLDER, "Buscar o URL");
        this.esMxLabels.set(Labels.MYPR_NEXT_P, "Siguiente Página");
        this.esMxLabels.set(Labels.MYPR_PREV_P, "Página Anterior");
        this.esMxLabels.set(Labels.MYPR_PRIME, "Prime");
        this.esMxLabels.set(Labels.MYPR_FREE_SHIPPING, "Envío gratis");
        this.esMxLabels.set(Labels.MYPR_FILTERS, "Filtros");
        this.esMxLabels.set(Labels.MYPR_MORE_OPTIONS, "Más Opciones");
        this.esMxLabels.set(Labels.MYPR_SORTBY, "Ordenar por");
        this.esMxLabels.set(Labels.MYPR_SORTBY_FEATURED, "Destacados");
        this.esMxLabels.set(Labels.MYPR_SORTBY_RELEVANCE, "Relevancia");
        this.esMxLabels.set(Labels.MYPR_SORTBY_PRICE_HTL, "Precio Más Alto a Más Bajo");
        this.esMxLabels.set(Labels.MYPR_SORTBY_PRICE_LTH, "Precio Más Bajo a Más Alto");
        this.esMxLabels.set(Labels.MYPR_SORTBY_NEWEST, "Lo Más Nuevo");

        this.esMxLabels.set(Labels.MYST_MY_STORES, "Mis Tiendas");

        this.esMxLabels.set(Labels.NSETTS_NOTIF_SETTINGS, "Configuración");
        this.esMxLabels.set(Labels.NSETTS_NOTIF_EVENTS, "Notificaciones por correo");
        this.esMxLabels.set(Labels.NSETTS_NOTIF_TYPES, "Tipos de notificacion");
        this.esMxLabels.set(Labels.NSETTS_EMAIL_NOTIFS, "Notificaciones por correo");
        this.esMxLabels.set(Labels.NSETTS_SMS_NOTIFS, "Notificaciones por SMS");
        this.esMxLabels.set(Labels.NSETTS_PRICE_DROP_EV, "Todas las caídas de precio");
        this.esMxLabels.set(Labels.NSETTS_TARGET_REACHED_EV, "Solo precio meta alcanzado");
        this.esMxLabels.set(Labels.NSETTS_NOTIF_FREQUENCY, "Frecuencia de correos");
        this.esMxLabels.set(Labels.NSETTS_EVERY_TIME_PRICE_CHANGES, "Cada vez que baje un precio");
        this.esMxLabels.set(Labels.NSETTS_ONCE_A_DAY, "Una vez al día");
        this.esMxLabels.set(Labels.NSETTS_WHEN_TO_GET_EMAIL, "Cuándo recibir el correo");
        this.esMxLabels.set(Labels.NSETTS_TIME_ZONE, "Zona horaria");
        this.esMxLabels.set(Labels.NSETTS_TIME_OF_DAY, "Hora del día");
        this.esMxLabels.set(Labels.NSETTS_OTHER_OPTS, "Otras opciones");
        this.esMxLabels.set(Labels.NSETTS_CLEAR_IMMEDIATE_NOTIFS, "Eliminar todas las notificaciones de prioridad por correo");
        this.esMxLabels.set(Labels.NSETTS_SUCCESS_CLEARED_IMM_NOTIFS, "Todas tus notificaciones de prioridad se han eliminado.");
        this.esMxLabels.set(Labels.NSETTS_ERROR_CLEARED_IMM_NOTIFS, "Hubo un problema eliminando tus notificaciones de prioridad. Intentalo más tarde.");

        this.esMxLabels.set(Labels.LOGIN_EMAIL, "Correo electrónico");
        this.esMxLabels.set(Labels.LOGIN_PASSWORD, "Contraseña");
        this.esMxLabels.set(Labels.LOGIN_FORGOT_PASS, "¿Olvidaste tu contraseña?");
        this.esMxLabels.set(Labels.LOGIN_REMEMBER, "Recuérdame");
        this.esMxLabels.set(Labels.LOGIN_SIGNIN, "Ingresar");
        this.esMxLabels.set(Labels.LOGIN_SIGNING_IN, "Ingresando...");
        this.esMxLabels.set(Labels.LOGIN_SIGNUP, "¿No tienes cuenta? Regístrate");
        this.esMxLabels.set(Labels.LOGIN_OR, "Ó");

        this.esMxLabels.set(Labels.SIGNUP_SIGNUP, "Crea una cuenta");
        this.esMxLabels.set(Labels.SIGNUP_EMAIL, "Correo electrónico");
        this.esMxLabels.set(Labels.SIGNUP_PASSWORD, "Contraseña");
        this.esMxLabels.set(Labels.SIGNUP_CPASSWORD, "Confirma tu contraseña");
        this.esMxLabels.set(Labels.SIGNUP_SIGNIN, "¿Ya tienes una cuenta? Ingresa aquí");

        this.esMxLabels.set(Labels.MENU_LOGOUT, "Salir");
        this.esMxLabels.set(Labels.MENU_MY_PRODUCTS, "Mis Productos");
        this.esMxLabels.set(Labels.MENU_MY_STORES, "Mis Tiendas");
        this.esMxLabels.set(Labels.MENU_NOTIF_SETTS, "Configuración");
        this.esMxLabels.set(Labels.MENU_SEARCH, "Buscar");
        this.esMxLabels.set(Labels.MENU_MYACCOUNT, "Mi Cuenta");
        this.esMxLabels.set(Labels.MENU_ABOUT, "Acerca de");
        this.esMxLabels.set(Labels.MENU_HELP, "Ayuda");
        this.esMxLabels.set(Labels.MENU_PRIVACY, "Política de Privacidad");
        this.esMxLabels.set(Labels.MENU_TERMS, "Términos de Uso");

        this.esMxLabels.set(Labels.NC_NEW_PRICE, "PRECIO NUEVO");
        this.esMxLabels.set(Labels.NC_PRICE_DROP, "CAÍDA DE PRECIO");
        this.esMxLabels.set(Labels.NC_TARGET_REACHED, "PRECIO META ALCANZADO!");
        this.esMxLabels.set(Labels.NC_YOUR_TARGET_PRICE, "TU PRECIO META");
        this.esMxLabels.set(Labels.NC_NO_TARGET_SET, "SIN PRECIO META");

        this.esMxLabels.set(Labels.C_BUY_ON_AMZ, "Comprar en Amazon");
        this.esMxLabels.set(Labels.C_BUY_ON_AMZ_SH, "Comprar");
        this.esMxLabels.set(Labels.C_VIEW_DETAILS, "Ver Detalles");
        this.esMxLabels.set(Labels.C_PRIME, "Prime");
        this.esMxLabels.set(Labels.C_CURRENT_PRICE, "Precio actual");
        this.esMxLabels.set(Labels.C_PRICE_WHEN_START_FOLLOW, "Precio cuando empezaste a rastrear este producto");
        this.esMxLabels.set(Labels.C_TARGET_PRICE, "Precio meta");
        this.esMxLabels.set(Labels.C_SET_TARGET_PRICE, "Definir precio meta");
        this.esMxLabels.set(Labels.C_TRACKING_SINCE, "Rastreando desde");
        this.esMxLabels.set(Labels.C_TRACK, "Rastrear");
        this.esMxLabels.set(Labels.C_UNTRACK, "Olvidar");
        this.esMxLabels.set(Labels.C_DETAILS, "Detalles");
        this.esMxLabels.set(Labels.C_PRICE_HISTORY, "Historial de precios");
        this.esMxLabels.set(Labels.C_ABOUT_ITEM, "Acerca de este artículo");
        this.esMxLabels.set(Labels.C_YOUR_TARGET_PRICE, "Tu precio meta");
        this.esMxLabels.set(Labels.C_NO_TARGET_SET, "Sin precio meta");

        this.esMxLabels.set(Labels.PH_PRICE_HISTORY, "Historial de Precios");
        this.esMxLabels.set(Labels.PH_PRICE, "Precio");
        this.esMxLabels.set(Labels.PH_DATE, "Fecha");
        this.esMxLabels.set(Labels.PH_MORE, "Más");
        this.esMxLabels.set(Labels.PH_NO_HISTORY_YET, "Aún no tenemos un historial de precios para este producto. Rastrea este producto para empezar a generarlo.");
        this.esMxLabels.set(Labels.PH_LOW_30D, "Más bajo en 30 días");
        this.esMxLabels.set(Labels.PH_HIGH_30D, "Más alto en 30 días");
        this.esMxLabels.set(Labels.PH_LOW_YEAR, "Más bajo en un año");
        this.esMxLabels.set(Labels.PH_HIGH_YEAR, "Más alto en un año");

        this.esMxLabels.set(Labels.RP_RESET_PASSWORD, "Reiniciar Contraseña");
        this.esMxLabels.set(Labels.RP_EMAIL, "Correo electrónico");
        this.esMxLabels.set(Labels.RP_RESET_BTN, "Reiniciar Contraseña");
        this.esMxLabels.set(Labels.RP_RESETTING_PASS, "Estamos reiniciando tu contraseña...");
        this.esMxLabels.set(Labels.RP_PASS_HAS_BEEN_RESET, "¡Hemos reiniciado tu contraseña! Te enviamos un correo con tu contraseña nueva. Te regresaremos a la página de ingreso en un momento más...");
        this.esMxLabels.set(Labels.RP_PROBLEM_RESETTING, "Hubo un problema al reiniciar tu contraseña.");

        this.esMxLabels.set(Labels.NOTICE_RESET_PASSWORD, "Te hemos enviado un correo electrónico con instrucciones para reiniciar tu contraseña. Ya puedes cerrar esta pestaña.");
        this.esMxLabels.set(Labels.NOTICE_VERIFY, "Te hemos enviado un correo electrónico con instrucciones para verificar tu cuenta. Ya puedes cerrar esta pestaña.");
        this.esMxLabels.set(Labels.NOTICE_OR, "ó tambien puedes...");
        this.esMxLabels.set(Labels.NOTICE_GO_BACK, "Regresar");

        this.esMxLabels.set(Labels.VERIFY_VERIFYING, "Verificando...");
        this.esMxLabels.set(Labels.VERIFY_VERIFIED, "¡Verificado! Te llevaremos a la pagina de inicio de sesión en un momento...");
        this.esMxLabels.set(Labels.VERIFY_PROBLEM, "Ocurrió un problema al verificar tu cuenta.");

        this.esMxLabels.set(Labels.MACC_CHANGE_PASS, "Cambiar Contraseña");
        this.esMxLabels.set(Labels.MACC_CURRENT_PASS, "Contraseña actual");
        this.esMxLabels.set(Labels.MACC_EMAIL, "Correo Electrónico");
        this.esMxLabels.set(Labels.MACC_MY_ACCOUNT, "Mi Cuenta");
        this.esMxLabels.set(Labels.MACC_NEW_PASS, "Contraseña nueva");
        this.esMxLabels.set(Labels.MACC_NEW_PASS_CONFIRM, "Confirma tu contraseña nueva");
        this.esMxLabels.set(Labels.MACC_SUBMIT_PASS, "Confirmar cambio de contraseña");     
        this.esMxLabels.set(Labels.MACC_PASS_UPDATED, "Tu contraseña ha sido actualizada.");  
        this.esMxLabels.set(Labels.MACC_CURR_PASS_INCORRECT, "Tu contraseña actual es incorrecta. La contraseña no ha sido actualizada.");  
        this.esMxLabels.set(Labels.MACC_NEW_PASS_DONT_MATCH, "Tu contraseña nueva no es igual a la confirmación.  La contraseña no ha sido actualizada.");  
        this.esMxLabels.set(Labels.MACC_PROBLEM_UPDATING_PASS, "Hubo un problema y no pudimos cambiar tu contraseña. Intenta más tarde.");
        this.esMxLabels.set(Labels.MACC_LANGUAGE, "Idioma");
        this.esMxLabels.set(Labels.MACC_SELECT_LANGUAGE, "Selecciona tu idioma");
        this.esMxLabels.set(Labels.MACC_ENGLISH, "Inglés");
        this.esMxLabels.set(Labels.MACC_SPANISH, "Español");
        this.esMxLabels.set(Labels.MACC_DELETE_ACCT, "Eliminar Mi Cuenta");
        this.esMxLabels.set(Labels.MACC_CLICK_TO_DELETE_ACCT, "Haz clic aquí para eliminar tu cuenta");
        this.esMxLabels.set(Labels.MACC_ACCOUNT_DELETED, "Tu cuenta ha sido eliminada. ¡Vuelve pronto!");
        this.esMxLabels.set(Labels.MACC_ACCOUNT_DELETE_WARNING, "Eliminar tu cuenta es permanente y no se puede revertir. Haz clic en el boton de nuevo para eliminar tu cuenta permanentemente.");

        this.esMxLabels.set(Labels.AZD_TAKING_YOU_TO_AMAZON, "Te estamos llevando a Amazon...");
        this.esMxLabels.set(Labels.AZD_CLICK_TO_GO_NOW, "Haz clic aquí para ir ahora");

        this.esMxLabels.set(Labels.OM_ONE_MOMENT_PLEASE, "Un momento porfavor...");

        this.esMxLabels.set(Labels.ABT_TITLE, "Acerca de");

        this.esMxLabels.set(Labels.PP_TITLE, "Política de privacidad");

        this.esMxLabels.set(Labels.TOS_TITLE, "Términos de uso");

        this.esMxLabels.set(Labels.DEL_TITLE, "Cómo eliminar tu cuenta");

        this.esMxLabels.set(Labels.HLP_TITLE, "Ayuda");
        this.esMxLabels.set(Labels.HLP_CONTACT_US, "Contáctanos");        
        this.esMxLabels.set(Labels.HLP_EMAIL, "Correo electrónico");
        this.esMxLabels.set(Labels.HLP_MSG, "Mensaje");
        this.esMxLabels.set(Labels.HLP_SEND, "Enviar");
        this.esMxLabels.set(Labels.HLP_SENT_SUCCESS, "Tu mensaje ha sido enviado.");
        this.esMxLabels.set(Labels.HLP_BELL_BUTTON_TITLE, "¿Qué hace este boton?");
        this.esMxLabels.set(Labels.HLP_BELL_BUTTON_BTN_TEXT, "Haz clic aquí para aprender");
        this.esMxLabels.set(Labels.HLP_SEARCH_TITLE, "Opciones de Búsqueda");
        this.esMxLabels.set(Labels.HLP_SEARCH_STORE, "Tienda");
        this.esMxLabels.set(Labels.HLP_SEARCH_CATEGORY, "Categoría");
        this.esMxLabels.set(Labels.HLP_SEARCH_FILTERS, "Filtros");
        this.esMxLabels.set(Labels.HLP_SEARCH_SEARCH, "Buscar o URL");
        this.esMxLabels.set(Labels.HLP_SETTS_TITLE, "Opciones de Configuración");
        this.esMxLabels.set(Labels.HLP_SETTS_LANG, "Idioma");
        this.esMxLabels.set(Labels.HLP_SETTS_NOTIF_TYPES, "Tipos de Notificación");
        this.esMxLabels.set(Labels.HLP_SETTS_EMAIL_NOTIFS, "Notificaciones por Correo");
        this.esMxLabels.set(Labels.HLP_SETTS_EMAIL_FREQ, "Frecuencia de Correos");
        this.esMxLabels.set(Labels.HLP_SETTS_EMAIL_WHEN, "Cuándo recibir el correo");
        this.esMxLabels.set(Labels.HLP_SETTS_OTHER, "Otras opciones");

        this.esMxLabels.set(Labels.LAND_SUBTITLE_1, "Rastrea precios en Amazon y compra a precios bajos");
        this.esMxLabels.set(Labels.LAND_FAQ, "Preguntas Frecuentes");
        this.esMxLabels.set(Labels.LAND_SIGN_IN, "Ingresar con correo");
        this.esMxLabels.set(Labels.LAND_CREATE_ACCT, "Crear Cuenta con correo");

        this.esMxLabels.set(Labels.FAQ_SUBTITLE, "Preguntas Frecuentes");
        this.esMxLabels.set(Labels.FAQ_BACK, "Regresar");

        this.esMxLabels.set(Labels.COOKIE_CONSENT_WARN_TEXT, "Antes de ingresar, debes hacer clic en el boton de 'Entiendo' en la parte de abajo.");
        this.esMxLabels.set(Labels.COOKIE_CONSENT_INFO_TEXT, "Este sitio utiliza cookies para mejorar la experiencia del usuario.");
        this.esMxLabels.set(Labels.COOKIE_CONSENT_BUTTON, "Entiendo");

    }

}