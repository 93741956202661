export enum HelpSections{    
    EMPTY,
    SETTS_LANGUAGE,
    SETTS_NOTIF_TYPES,
    SETTS_EMAIL_NOTIFS,
    SETTS_EMAIL_FREQ,
    SETTS_WHEN_EMAIL,
    SETTS_OTHER_OPTS,

    SEARCH_STORE,
    SEARCH_CATEGORY,
    SEARCH_FILTERS,
    SEARCH_SEARCH,
    SEARCH_SORTBY,
}