import { Box, Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import $ from "jquery";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Constants from '../components/Constants';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import { langContext } from '../contexts/LangContext';
import AResponse from '../dto/AResponse';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            border: '3px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '1em',
            paddingTop: '3em'
        },
        headerBox: {
            marginTop: '0em'
        },
        header: {
            textAlign: 'center'
        },
        alienBox: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        alien: {
            maxWidth: '7em',
            maxHeight: '7em'
        },
        buttonBox: {
            marginTop: '1em',
            textAlign: 'center'
        },
        orBox: {
            marginTop: '3em',
            textAlign: 'center'
        }
    }),
);

function ResetPassword2() {
    const classes = useStyles();
    const history = useHistory();
    const [resetting, setResetting] = useState<boolean>(true);
    const [reset, setReset] = useState<boolean>(false);
    const search = useLocation().search;
    const code = new URLSearchParams(search).get('resetCode');    
    const { lang } = useContext(langContext);

    useEffect(() => {

        let token = $("meta[name='_csrf']").attr("content");
        let headerName = $("meta[name='_csrf_header']").attr("content");

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader(headerName!, token!);
            }
        });

        $.ajax({
            url: Constants.BASE_URL + 'api/user/resetPassword/' + code,
            method: 'POST',
            processData: false,
            contentType: 'application/json'
        }).done(function (data) {
            setResetting(false);
            const r: AResponse<String> = data;
            if (r.isError) {
                alert(r.message);
            } else {
                setReset(true);
            }

            setTimeout(() => {
                history.replace('/sign-in');
            }, 10000);
        });
    }, [code])
    return (
        <Container className={classes.root}>
            <Box className={classes.alienBox}>
                <img className={classes.alien} src={'/img/aliens/150/happy4-150px.png'}></img>
            </Box>
            <Box className={classes.headerBox}>
                {resetting && <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.RP_RESETTING_PASS)}</Typography>}
                {!resetting && reset && <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.RP_PASS_HAS_BEEN_RESET)}</Typography>}
                {!resetting && !reset && <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.RP_PROBLEM_RESETTING)}</Typography>}
            </Box>
        </Container>        
    )
}

export default ResetPassword2
