import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {              
            display: 'flex',
            flexDirection: 'column',                        
            alignItems: 'center',          
            width:'100%',
            height:'100%',
            backgroundColor:theme.palette.common.white,
            background: 'linear-gradient(160deg, rgba(255,255,255,1) 0%, rgba(91,132,196,0.25) 100%);',
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '45%',
            alignItems: 'center',
        },
        wrapper: {
            maxWidth: '80%',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
        },
        btn: {
            marginTop: '10%'
        }
    }),
);

function Page404() {
    const history = useHistory();
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <div className={classes.body}>
                <div className={classes.wrapper}>
                    <Typography variant="h5" color='textSecondary'>The page you are looking for doesn't exist.</Typography>
                    <Button
                        className={classes.btn}
                        variant='outlined'
                        color='primary'
                        onClick={() => { history.push("/") }}
                    >Go Back</Button>
                </div>
            </div>
        </div>

    )
}

export default Page404
