import { makeStyles, Theme, createStyles, Box, Typography, Container} from '@material-ui/core';
import React, { useContext } from 'react'
import lm from '../components/LabelManager';
import { Labels } from '../components/enums/Labels';
import { langContext } from '../contexts/LangContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,            
            border: '3px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '1em',
            justifyContent:'center'
        },
        headerBox: {
            marginTop: '0em'
        },
        header: {
            textAlign: 'center'
        },
        alienBox:{            
            marginLeft:'auto',
            marginRight:'auto',
        },
        alien:{
            maxWidth:'7em',
            maxHeight:'7em'
        },
    }),
);

function OneMoment() {
    const classes = useStyles();
    const { lang } = useContext(langContext);
    return (
        <Container className={classes.root}>
            <Box className={classes.alienBox}>
                <img className={classes.alien} src={'/img/aliens/150/curious3-150px.png'}></img>
            </Box>
            <Box className={classes.headerBox}>
                <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.OM_ONE_MOMENT_PLEASE)}</Typography>
            </Box>
        </Container>
    )
}

export default OneMoment
