import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import $ from "jquery";
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BroSnack from '../components/BroSnack';
import Constants from '../components/Constants';
import Copyright from '../components/Copyright';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import { authContext } from '../contexts/AuthContext';
import AResponse from '../dto/AResponse';
import ScaleLoader from "react-spinners/ScaleLoader";
import theme from '../CTheme';
import { useEffect } from 'react';
import { langContext } from '../contexts/LangContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TitleBox from '../components/TitleBox';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  recaptchaBox:{
    marginTop:"2em"
  },
  titleBox:{
    marginBottom:'2em'
  }
}));

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [sbMsg, setSbMsg] = useState<string | undefined>("");
  const [sbOpen, setSbOpen] = useState<boolean>(false);
  const [sbType, setSbType] = useState<'info'|'warning'>('warning');
  const { lang } = useContext(langContext);
  const { setAuthData, auth } = useContext(authContext);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if(auth.email && auth.token && auth.email.trim().length > 0 && auth.token.trim().length > 0){
        history.push('/home');
    }
  }, [auth,history]);

  const onSignupClickHandler = async () => {
    if (submitted) return;

    let captchaRes: string = "";
    try {
      captchaRes = await executeRecaptcha('register');
    } catch { };

    const regData = {
      email: email,
      password: password,
      password2: password2,
      lang: lang,
      captchaToken: captchaRes
    };

    setSubmitted(true);

    let token = $("meta[name='_csrf']").attr("content");
    let headerName = $("meta[name='_csrf_header']").attr("content");

    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader(headerName!, token!);
      }
    });

    $.ajax({
      url: Constants.BASE_URL + 'api/user/register',
      method: 'POST',
      data: JSON.stringify(regData),
      processData: false,
      contentType: 'application/json'
    }).done(function (data) {
      const r: AResponse<String> = data;
      if (r.isError) {
        setSubmitted(false);
        setSbMsg(r.message);
        setSbOpen(true);
        if(r.intCode == 513)        
          setSbType('info');
        else  
          setSbType('warning');
      } else {
        history.replace('/verify-notice?n=vn');
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <TitleBox className={classes.titleBox} color='black'/>
        <Typography style={{fontFamily:"'Comfortaa', Roboto, Helvetica, Arial, sans-serif"}} component="h1" variant="h5">
          {lm.get(lang, Labels.SIGNUP_SIGNUP)}
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={lm.get(lang, Labels.SIGNUP_EMAIL)}
                name="email"
                autoComplete="email"
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={lm.get(lang, Labels.SIGNUP_PASSWORD)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => {
                  setPassword(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password2"
                label={lm.get(lang, Labels.SIGNUP_CPASSWORD)}
                type="password"
                id="password2"
                onChange={e => {
                  setPassword2(e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSignupClickHandler}
            disabled={submitted}
          >
            {(!submitted ? lm.get(lang, Labels.SIGNUP_SIGNUP) : <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} />)}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/sign-in" variant="body2">
                {lm.get(lang, Labels.SIGNUP_SIGNIN)}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <BroSnack
        autoHideDuration={6000}
        open={sbOpen}
        message={sbMsg}
        snackType={sbType}
        onSnackClose={() => { setSbOpen(false) }}
      />
      <Box className={classes.recaptchaBox}>
        <Typography variant='body2' color='textSecondary'>
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </Typography>
      </Box>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}