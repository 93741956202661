import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import { HelpSections } from './enums/HelpSections';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        chDiv: {
            flexGrow: 999,
            alignItems: 'center',
            // marginRight: '1rem'
        },
        chDivSmall: {
            flexGrow: 1,
            alignItems: 'center',
            // marginRight: '1rem'
        },
        btnDiv: {
            flexGrow: 1,
            // position: 'sticky',
            // right: '.5rem'
        },
        btnDivLarge: {
            flexGrow: 999,
            // position: 'sticky',
            // right: '.5rem'
        },
        iconBtnStart: {
            padding: '.2rem'
        },
        iconBtnEnd: {
            padding: '.2rem'
        }
    }),
);

export interface HelpButtonProps {
    children: React.ReactNode,
    position?: 'start' | 'end' | 'endOfElement',
    className?: string,
    setOpenHelp: (open: boolean) => void,
    section: HelpSections,
    setSection: (setSection: HelpSections) => void,
}

function WithHelp(props: HelpButtonProps) {
    const classes = useStyles();

    const helpButtonClickHandler = () => {
        props.setSection(props.section);
        props.setOpenHelp(true);
    };

    return (
        <div className={props.className}>
            <div className={classes.root}>
                {props.position && props.position! === 'start' &&
                    <div className={classes.btnDiv}>
                        <IconButton
                            onClick={helpButtonClickHandler}
                            className={classes.iconBtnStart}>
                            <HelpIcon></HelpIcon>
                        </IconButton>
                    </div>
                }
                <div className={props.position && props.position! === 'endOfElement' ? classes.chDivSmall : classes.chDiv}>
                    {props.children}
                </div>
                {(props.position == undefined || props.position! === 'end' || props.position! === 'endOfElement') &&
                    <div className={props.position && props.position! === 'endOfElement' ? classes.btnDivLarge : classes.btnDiv}>
                        <IconButton
                            onClick={helpButtonClickHandler}
                            className={classes.iconBtnEnd}>
                            <HelpIcon></HelpIcon>
                        </IconButton>
                    </div>
                }
            </div>
        </div>
    )
}

export default WithHelp
