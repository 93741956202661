import { Box, Button, createStyles, Divider, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { Add, ArrowDropDown, ArrowDropUp, OpenInNew, Remove } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import { langContext } from '../contexts/LangContext';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import ProductPriceTarget from '../dto/request/ProductPriceTarget';
import ProductTrack from '../dto/request/ProductTrack';
import ProductUntrack from '../dto/request/ProductUntrack';
import ProductInfo from '../dto/response/ProductInfo';
import APIRequests from './APIRequests';
import Constants from './Constants';
import { Labels } from './enums/Labels';
import lm from './LabelManager';
import ProductDetailsDialog from './ProductDetailsDialog';
import ProductPriceHistoryDialog from './ProductPriceHistoryDialog';
import ProductAlwaysNotify from '../dto/request/ProductAlwaysNotify';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import BuyProduct from './BuyProduct';
import ScaleLoader from "react-spinners/ScaleLoader";
import theme from '../CTheme';

interface ProductCardProps {
    prodInfo: ProductInfo,
    setNewProductsTracked: (newProdsTracked: boolean) => void,
    updateProdCache: (prodInfo: ProductInfo) => void,
    setOpenBellTutorial: (open: boolean) => void,
    showWarning: (text: string) => void,
    index: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prodBox: {
            borderRadius: '.5em',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.light,
            marginTop: '.5em',
            marginBottom: '.5em',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: theme.palette.background.default,
            padding: '.25em .5em .35em .5em',
            position: 'relative'
            // maxWidth: '25em'
        },
        prodFlexBox: {
            display: 'flex',
            flexDirection: 'row',
        },
        prodLeftBox: {
            width: '15%',
            marginRight: '3%',
            maxWidth: '15%'
        },
        prodImg: {
            maxWidth: '100%',
            maxHeight: '4em',
            borderRadius: '.25em',
            cursor: 'pointer'
        },
        prodRightBox: {
            maxWidth: '85%',
            width: '85%'
        },
        prodTitleBox: {
            position: 'relative'
        },
        prodTitle: {
            fontSize: '.8em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 1,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            marginRight: '2rem',
            cursor: 'pointer'
        },
        importantIconBox: {
            position: 'absolute',
            right: '0rem',
            top: '-.27rem',
        },
        importantIconNone: {
            color: theme.palette.grey[400],

        },
        importantIconAll: {
            color: theme.palette.extra2.main
        },
        prodCost: {
            fontSize: '.8em',
            fontWeight: 'bold',
            display: 'inline-block',
            lineHeight: '0'
        },
        targetPriceCost: {
            fontSize: '.7em',
            fontWeight: 'normal',
            display: 'inline-block'
        },
        prodDetailsBox: {
            marginTop: '.1em'
        },
        prodButtonsBox: {
            marginTop: '.5em',
            display: 'flex',
            maxWidth: '12em'
        },
        prodButton: {
            marginLeft: '.25em',
            marginRight: '.25em',
            height: '1.7em',
            fontSize: '.6em'
        },
        prodPriceHeader: {
            display: 'inline-block',
            fontSize: '.8em',
            marginRight: '.5em',
            color: theme.palette.text.primary,
            lineHeight: '0'
        },
        targetPriceHeader: {
            display: 'inline-block',
            fontSize: '.7em',
            marginRight: '.5em',
            color: theme.palette.primary.main
        },
        footerFlag: {
            position: 'absolute',
            left: '1em',
            bottom: '.2em',
            width: '1.1em',
            height: '1.1em',
            borderColor: theme.palette.common.white,
            backgroundColor: theme.palette.common.white,
            padding: '0em 2px',
            borderRadius: '.2em'
        },
        openNewIcon: {
            fontSize: '.9rem !important'
        },
        priceChangeIconDown: {
            position: 'absolute',
            color: theme.palette.success.main,
            top: '-.5em',
        },
        priceChangeIconUp: {
            position: 'absolute',
            color: theme.palette.error.main,
            top: '-.5em',
        },
        targetPriceBox: {
            marginTop: '.2em',
            lineHeight: '0'
        },
        prodCostBox: {
            position: 'relative',
            marginTop: '.4em',
            lineHeight: '0',
            marginBottom: '.8em',
        }
    }),
);

function ProductCardLine(props: ProductCardProps) {
    const { setAuthData, auth } = useContext(authContext);
    const { lang } = useContext(langContext);
    const history = useHistory();
    const classes = useStyles();
    const [tracking, setTracking] = useState<boolean>(props.prodInfo.trackInfo.tracking);
    const [trackingLoad, setTrackingLoad] = useState<boolean>(false);
    const [priceHistoryOpen, setPriceHistoryOpen] = useState<boolean>(false);
    const [trackDate, setTrackDate] = useState<Date>(props.prodInfo.trackInfo.dateAdded);
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const [targetPrice, setTargetPrice] = useState<number>(props.prodInfo.trackInfo.targetPrice);
    const [editingTargetPrice, setEditingTargetPrice] = useState<boolean>(false);
    const [alwaysNotify, setAlwaysNotify] = useState<boolean>(props.prodInfo.alwaysNotify);
    const [loadingAlwaysNotify, setLoadingAlwaysNotify] = useState<boolean>(false);
    const [buyLoading, setBuyLoading] = useState<boolean>(false);

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode == 503) {
            logout();
        } else {
            if(res.message.trim().length > 0)
                props.showWarning(res.message.trim());
        }
    };

    const trackHandler = (prodInfo: ProductInfo) => () => {
        setTracking(true);
        setTrackingLoad(true);
        setTrackDate(new Date());
        const req = new ARequest<ProductTrack>(auth.token);
        const pt = new ProductTrack();
        pt.asin = prodInfo.asin;
        pt.currency = prodInfo.currency;
        pt.marketplaceKey = prodInfo.marketplaceKey;
        pt.priceAmount = prodInfo.priceAmount;
        pt.prime = prodInfo.prime;
        req.data = pt;

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/track', req, function (data) {
            setTrackingLoad(false);
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setTracking(false);
            } else {
                props.setNewProductsTracked(true);
                setTracking(r.data);
            }
        });
    };

    const untrackHandler = (prodInfo: ProductInfo) => () => {
        setTracking(false);
        const req = new ARequest<ProductUntrack>(auth.token);
        const pu = new ProductUntrack();
        pu.asin = prodInfo.asin;
        pu.marketplaceKey = prodInfo.marketplaceKey;
        req.data = pu;

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/untrack', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setTracking(true);
            } else {
                props.setNewProductsTracked(true);
                setTracking(r.data);
            }
        });
    };

    const setTrackPriceHandler = (prodInfo: ProductInfo) => {
        const req = new ARequest<ProductPriceTarget>(auth.token);

        const ppt = new ProductPriceTarget();
        ppt.asin = prodInfo.asin;
        ppt.marketplaceKey = prodInfo.marketplaceKey;
        ppt.targetPrice = targetPrice;
        req.data = ppt;

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/setTargetPrice', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
            } else {
                if (!r.data) {
                    setTargetPrice(0);
                    setEditingTargetPrice(true);
                }
            }
        });
    }

    const alwaysNotifyHandler = (prodInfo: ProductInfo, an: boolean) => {
        if (loadingAlwaysNotify) return;
        props.setOpenBellTutorial(true);
        setLoadingAlwaysNotify(true);
        const req = new ARequest<ProductAlwaysNotify>(auth.token);
        const pt = new ProductAlwaysNotify();
        pt.asin = prodInfo.asin;
        pt.marketplaceKey = prodInfo.marketplaceKey;
        pt.alwaysNotify = an;
        req.data = pt;
        const prev = alwaysNotify;
        setAlwaysNotify(an);
        prodInfo.alwaysNotify = an;
        props.updateProdCache(prodInfo);

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/setAlwaysNotify', req, function (data) {
            const r: AResponse<boolean> = data;
            setLoadingAlwaysNotify(false);
            if (r.isError) {
                handleError(r);
                setAlwaysNotify(prev);
                prodInfo.alwaysNotify = prev;
                props.updateProdCache(prodInfo);
            }
        });
    };

    const getFlagName = (mpKey: string): string | undefined => {
        switch (mpKey) {
            case 'MEXICO':
                return 'mx';
            case 'USA':
                return 'us';
            case 'SPAIN':
                return 'sp';
            case 'UK':
                return 'uk';
            default:
                return undefined;
        }
    };

    return (
        <Box boxShadow={0} className={classes.prodBox}>            
            <Box className={classes.prodFlexBox}>
                <Box className={classes.prodLeftBox}>
                    <img onClick={() => { setDetailsOpen(true) }} className={classes.prodImg} src={ProductInfo.getMediumPrimary(props.prodInfo).url} />
                </Box>
                <Box className={classes.prodRightBox}>
                    <Box className={classes.prodTitleBox}>
                        <Typography onClick={() => { setDetailsOpen(true) }} className={classes.prodTitle}>{props.prodInfo.title}</Typography>
                        {tracking &&
                            <Box className={classes.importantIconBox}>
                                {alwaysNotify ?
                                    <IconButton onClick={() => { alwaysNotifyHandler(props.prodInfo, false) }} style={{ padding: '0' }}>
                                        <NotificationImportantIcon fontSize='small' className={classes.importantIconAll} />
                                    </IconButton>
                                    :
                                    <IconButton onClick={() => { alwaysNotifyHandler(props.prodInfo, true) }} style={{ padding: '0' }}>
                                        <NotificationImportantIcon fontSize='small' className={classes.importantIconNone} />
                                    </IconButton>
                                }
                            </Box>
                        }
                    </Box>
                    <Divider />
                    <Box className={classes.prodDetailsBox}>
                        <Box className={classes.targetPriceBox}>
                            {
                                props.prodInfo.trackInfo.targetPrice > 0 ?
                                    <Typography className={classes.targetPriceHeader}>{lm.get(lang, Labels.C_YOUR_TARGET_PRICE)}</Typography> :
                                    <Typography className={classes.targetPriceHeader}>{lm.get(lang, Labels.C_NO_TARGET_SET)}</Typography>
                            }
                            {
                                props.prodInfo.trackInfo.targetPrice > 0 &&
                                <Typography className={classes.targetPriceCost}>{props.prodInfo.trackInfo.targetPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + props.prodInfo.currency}</Typography>
                            }
                        </Box>
                        <Box className={classes.prodCostBox}>
                            <Typography className={classes.prodPriceHeader}>{lm.get(lang, Labels.C_CURRENT_PRICE)}</Typography>
                            <Typography className={classes.prodCost}>{props.prodInfo.price + ' ' + props.prodInfo.currency}</Typography>
                            {
                                props.prodInfo.trackInfo.tracking && props.prodInfo.trackInfo.initialPrice > props.prodInfo.priceAmount ?
                                    <ArrowDropDown className={classes.priceChangeIconDown}></ArrowDropDown>
                                    : props.prodInfo.trackInfo.tracking && props.prodInfo.trackInfo.initialPrice < props.prodInfo.priceAmount ?
                                        <ArrowDropUp className={classes.priceChangeIconUp}></ArrowDropUp>
                                        : null
                            }
                        </Box>
                    </Box>
                    <Box className={classes.prodButtonsBox}>
                        {tracking ?
                            <Button className={classes.prodButton} variant='contained' color='primary' fullWidth size='small' endIcon={<Remove />} onClick={untrackHandler(props.prodInfo)}>
                                {lm.get(lang, Labels.C_UNTRACK)}
                            </Button> :
                            <Button className={classes.prodButton} variant='contained' color='primary' fullWidth size='small' endIcon={<Add />} onClick={trackHandler(props.prodInfo)}>
                                {lm.get(lang, Labels.C_TRACK)}
                            </Button>
                        }
                        <Button className={classes.prodButton} variant='contained' color='secondary' fullWidth
                            endIcon={!buyLoading && <OpenInNew className={classes.openNewIcon} />}
                            disabled={buyLoading}
                            onClick={() => {
                                BuyProduct.click(buyLoading, setBuyLoading, props.prodInfo, auth.token, lang);
                            }}
                        >
                            {buyLoading ? <ScaleLoader css="position:relative;top:.3em;" height={10} width={3} color={theme.palette.text.disabled} /> : lm.get(lang, Labels.C_BUY_ON_AMZ_SH)}
                        </Button>

                    </Box>
                </Box>
            </Box>
            {getFlagName(props.prodInfo.marketplaceKey) && <img className={classes.footerFlag} src={'/svg/' + getFlagName(props.prodInfo.marketplaceKey) + '.svg'} />}

            {priceHistoryOpen &&
                <ProductPriceHistoryDialog
                    open={priceHistoryOpen}
                    setPriceHistoryOpen={setPriceHistoryOpen}
                    prodInfo={props.prodInfo}
                    key={props.prodInfo.asin + '_pph'}
                />
            }

            {detailsOpen &&
                <ProductDetailsDialog
                    open={detailsOpen}
                    prodInfo={props.prodInfo}
                    setDetailsOpen={setDetailsOpen}
                    setPriceHistoryOpen={setPriceHistoryOpen}
                    tracking={tracking}
                    key={props.prodInfo.asin + '_pdd'}
                    trackHandler={trackHandler}
                    untrackHandler={untrackHandler}
                    trackDate={trackDate}
                    editingTargetPrice={editingTargetPrice}
                    setEditingTargetPrice={setEditingTargetPrice}
                    setTargetPrice={setTargetPrice}
                    setTrackPriceHandler={setTrackPriceHandler}
                    targetPrice={targetPrice}
                    trackingLoad={trackingLoad}
                />
            }


        </Box>
    )
}
export default ProductCardLine

