import { AccordionDetails, AppBar, BottomNavigation, BottomNavigationAction, Box, Button, Container, createStyles, Drawer, Fab, FormControlLabel, Grid, IconButton, InputAdornment, LinearProgress, makeStyles, MenuItem, Paper, Switch, Tab, Tabs, TextField, Theme, Toolbar, Typography, useTheme, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import FormControl from '@material-ui/core/FormControl';
import { ArrowUpward, ExpandMore, Search } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import $ from 'jquery';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useHistory, useLocation } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import SwipeableViews from 'react-swipeable-views';
import APIRequests from '../components/APIRequests';
import CacheManager from '../components/CacheManager';
import Constants from '../components/Constants';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import ProductCard from '../components/ProductCard';
import ProductCardLine from '../components/ProductCardLine';
import TabPanel from '../components/TabPanel';
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import { encContext } from '../contexts/EncContext';
import { langContext } from '../contexts/LangContext';
import theme from '../CTheme';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import SearchCache from '../dto/cache/Search';
import ProductSearch from '../dto/request/ProductSearch';
import TrackedProducts from '../dto/request/TrackedProducts';
import MarketplaceTrackCount from '../dto/response/MarketplaceTrackCount';
import ProductInfo from '../dto/response/ProductInfo';
import ProductSearchResults from '../dto/response/ProductSearchResults';
import SearchIndex from '../dto/SearchIndex';
import MarketplaceSelection from '../dto/user/MarketplaceSelection';
import { Languages } from '../components/enums/Languages';
import { Autocomplete } from '@material-ui/lab';
import BroSnack from '../components/BroSnack';
import BellIconTutorial from '../components/BellIconTutorial';
import WithHelp from '../components/WithHelp';
import { HelpSections } from '../components/enums/HelpSections';
import GenericHelpDialog from '../components/GenericHelpDialog';
import MarketplaceManager from '../components/MarketplaceManager';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        myPrOptions: {
            display: 'flex',
        },
        myPrStoreListFC: {
            marginBottom: ".5em",
            marginRight: '.5em',
            flex: '9',
        },
        myPrViewToggles: {
            flex: '1'
        },
        myPrViewTogglesBtnGrp: {
            height: '2.5em'
        },
        formControl: {
            marginBottom: ".5em"
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        productCardTitle: {
            fontSize: '.8em',
        },
        productCard: {
            marginBottom: "1em",
        },
        productCardSub: {
            fontSize: "1em",
        },
        productCardmedia: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        productCardButton: {
            //width:'8em'
        },
        productCost: {
            fontSize: "1.5em",
        },
        productCardWrapper: {
            padding: '0px 0px 3em 0px',
        },
        progress: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5em'
        },
        linearProgress: {
            margin: '2em 0em'
        },
        backUpFab: {
            position: 'absolute',
            right: '1.5em',
            top: '5em',
        },
        bottomNavRoot: {
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: '0px',
            width: '100%',
            borderTop: '1px solid',
            borderTopColor: theme.palette.divider
        },
        bottomNavRootWideL: {
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: '0px',
            width: '39.9%',
            borderTop: '1px solid',
            borderTopColor: theme.palette.divider
        },
        bottomNavRootWideS: {
            display: 'flex',
            justifyContent: 'center',
            position: 'fixed',
            bottom: '0px',
            width: '69.9%',
            borderTop: '1px solid',
            borderTopColor: theme.palette.divider
        },
        hidden: {
            display: 'none'
        },
        firstLoadingWrapper: {
            margin: '2em 1em',
        },
        firstLoadingText: {
            fontSize: '2em',
            fontFamily: "'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
        },
        firstLoadingSpinnerWrapper: {
            marginTop: '5em',
            display: 'flex',
            justifyContent: 'center'
        },
        noTrackedProducts: {
            marginTop: '1em'
        },
        noTrackedProductsBtns: {
            marginTop: '1em'
        },
        disabledBottomNav: {
            color: theme.palette.text.disabled
        },
        enabledBottomNav: {
            color: theme.palette.text.secondary
        },
        moreSearchOptsBtn: {
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        searchOptsDrawerWide: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.extra1.dark,
            width: '100%',
            maxWidth: '100% !important',
            padding: '0'
        },
        searchOptsDrawerMobile: {
            display: 'flex',
            width: '100%',
            maxWidth: '100% !important',
            padding: '0'
        },
        searchOptsDrawerInnerL: {
            maxWidth: '40%',
            minWidth: '40%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[24],
            padding: '0'
        },
        searchOptsDrawerInnerS: {
            maxWidth: '70%',
            minWidth: '70%',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[24],
            padding: '0'
        },
        searchOptsDrawerInnerMobile: {
            maxWidth: '100%',
            minWidth: '100%',
            backgroundColor: theme.palette.background.paper,
            padding: '0'
        },
        searchOptsDrawerContent: {
            padding: '1em',
            paddingTop: '.5em',
            height: (window.innerHeight - 280) + 'px'
        }
    }),
);

const Accordion = withStyles({
    root: {
        border: '1px solid',
        borderColor: theme.palette.grey[400],
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
        minHeight: '38px'
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: '38px',
        paddingRight: '.4em',
    },
    content: {
        margin: '6px 0px'
    },
    expandIcon: {
        padding: '5px 12px'
    },

})(MuiAccordionSummary);

interface SearchAction {
    displayText: string,
    query: string,
    searchKind: string,
    url: string
}

function MyProducts() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [wide, setWide] = useState<boolean>(window.innerWidth > window.innerHeight);
    const [smallWide, setSmallWide] = useState<boolean>(window.innerWidth <= 1000);
    const [idb, setDB] = React.useState<IDBDatabase | undefined>();
    const [idbSupport, setIDBSupport] = React.useState<boolean | undefined>();
    const [cacheLoaded, setCacheLoaded] = React.useState<boolean>(false);
    const [blockCacheUpdate, setBlockCacheUpdate] = React.useState<boolean>(false);
    const [sbMsg, setSbMsg] = useState<string | undefined>("");
    const [sbOpen, setSbOpen] = useState<boolean>(false);
    const [sbType, setSbType] = useState<'success' | 'warning' | 'info' | 'error'>('warning');

    const windowResizeHandler = () => {
        setWide(window.innerWidth > window.innerHeight);
        setSmallWide(window.innerWidth <= 1000);
    };

    useEffect(() => {
        window.addEventListener('resize', windowResizeHandler);
        return () => {
            window.removeEventListener('resize', windowResizeHandler);
        };
    }, []);

    useEffect(() => {
        if (window.indexedDB) {
            setIDBSupport(true);
            CacheManager.initDB().then(db => { setDB(db) });
        } else {
            setIDBSupport(false);
        }
    }, []);

    const { setAuthData, auth } = useContext(authContext);
    const { lang } = useContext(langContext);
    const { getKey } = useContext(encContext);
    const history = useHistory();
    const classes = useStyles();
    const [windowScrollYVal, setWindowScrollYVal] = React.useState<number>(0);
    const search = useLocation().search;
    const tabToUse = new URLSearchParams(search).get('a');
    const [selectedTabVal, setSelectedTabVal] = React.useState<number>(tabToUse == 'search' ? 1 : 0);
    const [bellTutorialOpen, setBellTutorialOpen] = React.useState<boolean>(false);
    const [helpOpen, setHelpOpen] = useState<boolean>(false);
    const [helpSection, setHelpSection] = useState<HelpSections>(HelpSections.EMPTY);

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode == 503) {
            logout();
        }
    };

    useEffect(() => {
        const scrollChecker = setInterval(function () {
            setWindowScrollYVal(window.scrollY);
        }, 200);

        return () => {
            clearInterval(scrollChecker);
        };
    }, []);

    const showWarning = (text: string) => {
        setSbMsg(text);
        setSbType('warning');
        setSbOpen(true);
    };

    //MY PRODUCTS TAB STATE    
    const [newProductsTracked, setNewProductsTracked] = React.useState<boolean>(false);
    const [trackedProdsHasMore, setTrackedProdsHasMore] = React.useState<boolean>(false);
    const [trackedProdsPage, setTrackedProdsPage] = React.useState<number>(0);
    const [selectedMP_MyPr, setSelectedMP_MyPr] = React.useState<string>("All");
    const [trackedProducts, setTrackedProducts] = React.useState<ProductInfo[]>([]);
    const [mpTrackCount, setMpTrackCount] = React.useState<MarketplaceTrackCount[]>([]);
    const [loadingProducts_MyPr, setLoadingProducts_MyPr] = React.useState<boolean>(true);
    const [prodViewOption, setProdViewOption] = React.useState<string>('compact');
    const [prodViewOptionInt, setProdViewOptionInt] = React.useState<NodeJS.Timeout>();


    //SEARCH TAB STATE
    const [selectedMPSearch, setSelectedMPSearch] = React.useState<string>('');
    const [selectedCat, setSelectedCat] = React.useState<string>("");
    const [selectedSortby, setSelectedSortBy] = React.useState<number>(0);
    const [marketplaces, setMarketplaces] = React.useState<MarketplaceSelection[]>([]);
    const [marketplacesWP, setMarketplacesWP] = React.useState<MarketplaceSelection[]>([]);
    const [categories, setCategories] = React.useState<SearchIndex[]>([]);
    const [productSearchTextInput, setProductSearchTextInput] = React.useState<string>("");
    const [productSearchText, setProductSearchText] = React.useState<string>("");
    const [loadingProducts, setLoadingProducts] = React.useState<boolean>(false);
    const [loadingStores, setLoadingStores] = React.useState<boolean>(false);
    const [loadingStoresWP, setLoadingStoresWP] = React.useState<boolean>(false);
    const [loadingCats, setLoadingCats] = React.useState<boolean>(false);
    const [filterPrime, setFilterPrime] = React.useState<boolean>(true);
    const [freeShipping, setFreeShipping] = React.useState<boolean>(false);
    const [searchPageNum, setSearchPageNum] = React.useState<number>(1);
    const [filterCount, setFilterCount] = React.useState<number>(0);
    const [searchCache, setSearchCache] = React.useState<SearchCache | undefined>();
    const [productSearchResults, setProductSearchResults] = React.useState<ProductSearchResults>(new ProductSearchResults());
    const [autocOptions, setAutocOptions] = React.useState<string[]>([]);
    const [autoCompClicked, setAutoCompClicked] = React.useState<boolean>(false);
    const [mak, setMak] = React.useState<string>("");
    const [lastMaQ, setLastMaQ] = React.useState<string>("");
    const [lastMakUsed, setLastMakUsed] = React.useState<number>((new Date()).getTime());
    const [moreSearchOptsOpen, setMoreSearchOptsOpen] = React.useState<boolean>(false);

    const theme = useTheme();

    const checkIfNewProductsWereTracked = (tabIndex: number) => {
        if (tabIndex === 0) {
            if (newProductsTracked) {
                setTrackedProducts([]);
                setTrackedProdsPage(0);
                setTrackedProdsHasMore(true);
                setNewProductsTracked(false);
                setSelectedMP_MyPr("All");
                getMarketplacesWithProds();
                getTrackCount();
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<{}>, index: number) => { //when clicking tabs        
        setSelectedTabVal(index);
        checkIfNewProductsWereTracked(index);
    };

    const handleChangeIndex = (index: number) => { //when swiping tabs        
        setSelectedTabVal(index);
        checkIfNewProductsWereTracked(index);
    };

    const switchToSearch = () => {
        setSelectedTabVal(1);
        setTrackedProdsHasMore(false);
    };

    //MY PRODUCTS TAB CODE    
    useEffect(() => {
        const req = new ARequest<string>(auth.token);
        req.data = "";
        APIRequests.post(Constants.BASE_URL + 'api/user/prefs/getMyProdsView', req, function (data) {
            const r: AResponse<string> = data;
            if (r.isError) {
                handleError(r);
            } else {
                setProdViewOption(r.data);
            }
        });

        let aCall = $.ajax({
            url: Constants.BASE_URL + 'api/ui/mak',
            method: 'GET'
        }).done(function (data) {
            setMak(data);
        });

        return () => {
            aCall.abort();
        }
    }, []);

    useEffect(() => {
        if (idb && idbSupport === true && cacheLoaded === false) {
            setCacheLoaded(true);
            CacheManager.getMyProdsCache(idb, getKey).then(mp => {
                if (mp) {
                    setTrackedProdsPage(mp.trackedProdsPage);
                    setSelectedMP_MyPr(mp.selectedMP);
                    setMpTrackCount(mp.mpTrackCount);
                    let x: any;
                    new Promise<void>((resolve, reject) => {
                        if (mp.trackedProds.length == 0) {
                            throw "Cache is empty.";
                        }
                        let i = 0;
                        x = setInterval(() => {
                            if (i + 5 < mp.trackedProds.length) {
                                let grabbedProds = mp.trackedProds.slice(i, i + 5);
                                setTrackedProducts(prods => prods.concat(grabbedProds));
                                i += 5;
                            } else if (i < mp.trackedProds.length) {
                                let grabbedProds = mp.trackedProds.slice(i, mp.trackedProds.length);
                                setTrackedProducts(prods => prods.concat(grabbedProds));
                                i = mp.trackedProds.length;
                            } else {
                                clearInterval(x);
                                resolve();
                            }
                        }, 100);
                    }).then(() => {
                        setLoadingProducts_MyPr(false);
                        setTrackedProdsHasMore(true);
                    }).catch(() => {
                        CacheManager.deleteMyProdsCache(idb);
                        setLoadingProducts_MyPr(false);
                        setTrackedProdsHasMore(true);
                    });
                } else {
                    CacheManager.deleteMyProdsCache(idb);
                    setLoadingProducts_MyPr(false);
                    setTrackedProdsHasMore(true);
                }
            }).catch(() => {
                CacheManager.deleteMyProdsCache(idb);
                setLoadingProducts_MyPr(false);
                setTrackedProdsHasMore(true);
            });
        }

        if (idbSupport === false && cacheLoaded === false) {
            setCacheLoaded(true);
            setLoadingProducts_MyPr(false);
            setTrackedProdsHasMore(true);
        }
    }, [idb, idbSupport, cacheLoaded]);

    useEffect(() => {
        if (newProductsTracked) {
            CacheManager.deleteMyProdsCache(idb);
        } else {
            if (idb) {
                CacheManager.getMyProdsCache(idb, getKey).then(mp => {
                    CacheManager.updateMyProdsCache(idb, getKey, loadingProducts_MyPr, mp, trackedProducts, selectedMP_MyPr, trackedProdsPage, mpTrackCount);
                }).catch(() => {
                    CacheManager.updateMyProdsCache(idb, getKey, loadingProducts_MyPr, undefined, trackedProducts, selectedMP_MyPr, trackedProdsPage, mpTrackCount);
                });
            }
        }
    }, [trackedProdsPage, selectedMP_MyPr, trackedProducts, mpTrackCount, newProductsTracked, idb]);


    const handleMarketplaceChange_MyPr = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedMP_MyPr(event.target.value as string);
        setTrackedProducts([]);
        setTrackedProdsHasMore(true);
        setTrackedProdsPage(0);
    };

    const getTotalTrackCount = () => {
        let count: number = 0;
        mpTrackCount.forEach(x => { count += x.count });
        return count;
    };

    const getTrackCountByMp = (mpKey: string) => {
        let count: number = 0;
        mpTrackCount.forEach(x => { if (x.marketplaceKey === mpKey) count += x.count });
        return count;
    };

    const selectInputStores_MyPr = (
        <FormControl className={classes.myPrStoreListFC} variant="outlined">
            <TextField
                select
                label={lm.get(lang, Labels.MYPR_STORE)}
                variant='outlined'
                size='small'
                id="select-mp-mypr"
                value={selectedMP_MyPr}
                onChange={handleMarketplaceChange_MyPr}
            >
                <MenuItem key="All" value="All">{lm.get(lang, Labels.MYPR_ALL_ST)} ({getTotalTrackCount()})</MenuItem>
                {marketplacesWP?.map(x => {
                    return (
                        <MenuItem key={x.marketplaceKey} value={x.marketplaceKey}>{lang == Languages.EN_US ? x.en : x.es} ({getTrackCountByMp(x.marketplaceKey)})</MenuItem>
                    )
                })}
            </TextField>
        </FormControl>
    );

    const handleProdViewChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
        if (value != null) {
            if (prodViewOptionInt)
                clearInterval(prodViewOptionInt!);

            const req = new ARequest<string>(auth.token);
            req.data = value;
            APIRequests.post(Constants.BASE_URL + 'api/user/prefs/setMyProdsView', req, function (data) {
                const r: AResponse<string> = data;
                if (r.isError) {
                    handleError(r);
                }
            });

            setLoadingProducts_MyPr(true);
            setTrackedProdsHasMore(false);
            setTrackedProducts([]);
            setProdViewOption(value);
            CacheManager.getMyProdsCache(idb, getKey).then(mp => {
                let i = 0;
                if (mp) {
                    let x = setInterval(() => {
                        if (i + 5 < mp.trackedProds.length) {
                            let grabbedProds = mp.trackedProds.slice(i, i + 5);
                            setTrackedProducts(prods => prods.concat(grabbedProds));
                            i += 5;
                        } else if (i < mp.trackedProds.length) {
                            let grabbedProds = mp.trackedProds.slice(i, mp.trackedProds.length);
                            setTrackedProducts(prods => prods.concat(grabbedProds));
                            i = mp.trackedProds.length;
                        } else {
                            clearInterval(x);
                            setLoadingProducts_MyPr(false);
                            setTrackedProdsHasMore(true);
                        }
                    }, 100);
                    setProdViewOptionInt(x);
                } else {
                    setLoadingProducts_MyPr(false);
                    setTrackedProdsHasMore(true);
                    setTrackedProdsPage(0);
                }
            }).catch(() => {
                setLoadingProducts_MyPr(false);
                setTrackedProdsHasMore(true);
                setTrackedProdsPage(0);
            });
        }
    };

    const productCardViewOptions = (
        <Grid className={classes.myPrViewToggles} container spacing={0} direction="column" alignItems="center">
            <Grid item>
                <ToggleButtonGroup className={classes.myPrViewTogglesBtnGrp} size="small" value={prodViewOption} exclusive onChange={handleProdViewChange}>
                    <ToggleButton value="full">
                        <ViewAgendaIcon />
                    </ToggleButton>
                    <ToggleButton value="compact" >
                        <ViewHeadlineIcon />
                    </ToggleButton>
                    {/* <ToggleButton value="tiles" >
                        <ViewModuleIcon />
                    </ToggleButton> */}
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    );

    const getTrackedProds = () => {
        setLoadingProducts_MyPr(true);
        const req = new ARequest<TrackedProducts>(auth.token);
        let tpr = new TrackedProducts();
        tpr.marketplaceKey = selectedMP_MyPr;
        tpr.pageNumber = trackedProdsPage;
        req.data = tpr;
        setTrackedProdsPage(page => page + 1);

        let token = $("meta[name='_csrf']").attr("content");
        let headerName = $("meta[name='_csrf_header']").attr("content");

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader(headerName!, token!);
            }
        });

        return $.ajax({
            url: Constants.BASE_URL + 'api/tracker/products/tracked',
            method: 'POST',
            data: JSON.stringify(req),
            processData: false,
            contentType: 'application/json'
        }).done(function (data) {
            setLoadingProducts_MyPr(false);
            const r: AResponse<ProductSearchResults> = data;
            if (r.isError) {
                handleError(r);
                setTrackedProdsHasMore(false);
            } else {
                if (r.data.products.length > 0) {
                    setTrackedProdsHasMore(true);
                } else {
                    setTrackedProdsHasMore(false);
                }

                if (r.data.products.length > 0) {
                    setTrackedProducts(prods => prods.concat(r.data.products));
                }
            }
        });
    };

    const inputProgress = (<LinearProgress className={classes.linearProgress} />);

    const firstTimeProgress = (
        <div className={classes.firstLoadingWrapper}>
            <div className={classes.firstLoadingText}>{lm.get(lang, Labels.MYPR_FIRST_LOAD)}</div>
            <div className={classes.firstLoadingSpinnerWrapper}><BounceLoader color={theme.palette.primary.main} /></div>
        </div>
    );

    const noTrackedProducts = (
        <Container className={classes.noTrackedProducts}>
            <Typography variant='h5'>{lm.get(lang, Labels.MYPR_NO_TRACKED)}</Typography>
            <Box className={classes.noTrackedProductsBtns}>
                <Button onClick={switchToSearch} fullWidth variant='contained' color='secondary'>
                    {lm.get(lang, Labels.MYPR_NO_TRACKED_BTN)}
                </Button>
            </Box>
        </Container>
    );

    const infiniteRef = useInfiniteScroll<HTMLDivElement>({
        hasNextPage: (trackedProdsHasMore && selectedTabVal == 0),
        loading: loadingProducts_MyPr,
        onLoadMore: getTrackedProds,
        scrollContainer: "window",
        threshold: 3000
    });


    const updateProdInfoCache = (pi: ProductInfo) => {
        CacheManager.updateProdInfoCacheInMyProds(idb, getKey, loadingProducts_MyPr, pi);
        CacheManager.updateProdInfoCacheInNotifs(idb, getKey, pi);
    };

    const trackedProductList = (
        <Container style={{ padding: '0px', display: (selectedTabVal == 0 ? 'block' : 'none') }}>
            <Container
                style={{ display: !loadingProducts_MyPr && !trackedProdsHasMore && trackedProducts.length <= 0 ? 'none' : 'block' }}
                className={classes.productCardWrapper}
                id='productCardWrapper'
                key='productCardWrapperKey'
            >
                <div ref={infiniteRef}>
                    {trackedProducts.filter((x) => (selectedMP_MyPr == "All" || x.marketplaceKey == selectedMP_MyPr))
                        .map((x: ProductInfo, ix: number) =>
                            prodViewOption === "full" ?
                                (
                                    <ProductCard
                                        key={x.asin + '_tp'}
                                        setNewProductsTracked={setNewProductsTracked}
                                        setOpenBellTutorial={setBellTutorialOpen}
                                        prodInfo={x}
                                        updateProdCache={updateProdInfoCache}
                                        showWarning={showWarning}
                                    />
                                )
                                : (
                                    <ProductCardLine
                                        key={x.asin + '_tp'}
                                        setNewProductsTracked={setNewProductsTracked}
                                        setOpenBellTutorial={setBellTutorialOpen}
                                        prodInfo={x}
                                        updateProdCache={updateProdInfoCache}
                                        showWarning={showWarning}
                                        index={ix}
                                    />
                                )

                        )}
                    {loadingProducts_MyPr && trackedProdsPage < 2 && firstTimeProgress}
                    {loadingProducts_MyPr && trackedProdsPage >= 2 && inputProgress}
                </div>
            </Container>
        </Container>
    );



    // SEARCH TAB CODE
    const handleMarketplaceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedMPSearch(event.target.value as string);
    };

    const handleCatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedCat(event.target.value as string);
    };

    const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedSortBy(event.target.value as number);
    };

    const getTrackCount = () => {
        const req = new ARequest<string>(auth.token);
        req.data = "";
        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/getTrackCount', req, function (data) {
            const r: AResponse<MarketplaceTrackCount[]> = data;
            if (r.isError) {
                handleError(r);
            } else {
                setMpTrackCount(r.data);
            }
        });
    }

    const getMarketplacesWithProds = () => {
        setLoadingStoresWP(true);
        const req = new ARequest<string>(auth.token);
        req.data = "";
        APIRequests.post(Constants.BASE_URL + 'api/tracker/marketplaces/withProds', req, function (data) {
            setLoadingStoresWP(false);
            const r: AResponse<MarketplaceSelection[]> = data;
            if (r.isError) {
                handleError(r);
            } else {
                let mps = r.data;
                if (mps.length > 0) {
                    setMarketplacesWP(mps);
                }
            }
        });
    };

    useEffect(() => {
        if (selectedMPSearch == "" && categories.length == 0 && marketplaces.length == 0 && selectedCat == "") return;

        let sc = new SearchCache();
        sc.lastUsedMP = selectedMPSearch;
        sc.mpSearchIndexes = categories;
        sc.mpSelection = marketplaces;
        sc.selectedCat = selectedCat;
        sc.selectedSortBy = selectedSortby;
        getKey.then(jsk => {
            let enc = AES.encrypt(JSON.stringify(sc), jsk).toString();
            localStorage.setItem(Constants.SEARCH_CACHE, enc);
        }).catch(() => { });
    }, [selectedMPSearch, categories, selectedCat, marketplaces, selectedSortby]);

    const loadSearchFromCache = async (): Promise<boolean> => {
        let loaded: boolean = false;
        let enc = localStorage.getItem(Constants.SEARCH_CACHE);
        if (enc != null) {
            try {
                let jsk = await getKey;
                let bytes = AES.decrypt(enc, jsk);
                let jsonS = bytes.toString(CryptoJS.enc.Utf8);
                if (jsonS != null) {
                    let search = JSON.parse(jsonS) as SearchCache;
                    setMarketplaces(search.mpSelection);
                    setCategories(search.mpSearchIndexes);
                    setSelectedCat(search.selectedCat);
                    setSelectedMPSearch(search.lastUsedMP);
                    setSearchCache(search);
                    setSelectedSortBy(search.selectedSortBy);
                    loaded = true;
                }
            } catch { }
        }

        return loaded;
    };

    const loadMarketplaces = () => {
        setLoadingStores(true);
        const req = new ARequest<string>(auth.token);
        req.data = "";

        let mps = MarketplaceManager.getMarketplaces();
        setMarketplaces(mps);
        setSelectedMPSearch(mps[0].marketplaceKey);
        setLoadingStores(false);
        // APIRequests.post(Constants.BASE_URL + 'api/tracker/marketplaces/selected', req, function (data) {
        //     setLoadingStores(false);
        //     const r: AResponse<MarketplaceSelection[]> = data;
        //     if (r.isError) {
        //         handleError(r);
        //     } else {
        //         let selected = r.data.filter(x => x.selected);
        //         if (selected.length > 0) {
        //             setAuthData(auth);
        //             setMarketplaces(selected);
        //             setSelectedMPSearch(selected[0].marketplaceKey);
        //         }
        //     }
        // });
    };

    useEffect(() => {
        setLoadingStores(true);
        let fromCache = loadSearchFromCache();
        fromCache.then(loaded => {
            if (loaded) {
                setLoadingStores(false);
            } else {
                loadMarketplaces();
            }
        }).catch(() => {
            localStorage.removeItem(Constants.SEARCH_CACHE);
            loadMarketplaces();
        });

        getMarketplacesWithProds();
        getTrackCount();
    }, [auth]);



    useEffect(() => {
        if ((searchCache && selectedMPSearch == searchCache.lastUsedMP && searchCache.mpSearchIndexes.length > 0) || selectedMPSearch == "") {
            return;
        }

        const req = new ARequest<string>(auth.token);
        req.data = selectedMPSearch;
        setAuthData(auth);
        setCategories([]);
        setLoadingCats(true);

        let cats = MarketplaceManager.getCategories(selectedMPSearch);
        setCategories(MarketplaceManager.getCategories(selectedMPSearch));
        setSelectedCat(cats[0].searchIndex);
        setLoadingCats(false);
        // APIRequests.post(Constants.BASE_URL + 'api/tracker/marketplace/searchIndexes', req, function (data) {
        //     setLoadingCats(false);
        //     const r: AResponse<SearchIndex[]> = data;
        //     if (r.isError) {
        //         handleError(r);
        //     } else {
        //         setAuthData(auth);
        //         setCategories(r.data);
        //         setSelectedCat(r.data[0].searchIndex);
        //     }
        // });
    }, [searchCache, selectedMPSearch, auth]);


    const searchForProducts = async () => {
        if (loadingProducts || selectedMPSearch.trim() == "") {
            return;
        }

        setLoadingProducts(true);
        setAutocOptions([]);
        let captchaRes: string = "";
        try {
            captchaRes = await executeRecaptcha('search');
        } catch { };


        const prodSearch = new ProductSearch();
        prodSearch.marketplaceKey = selectedMPSearch;
        prodSearch.searchIndex = selectedCat;
        prodSearch.page = searchPageNum;
        prodSearch.searchQuery = productSearchText;
        prodSearch.prime = filterPrime;
        prodSearch.freeShipping = freeShipping;
        prodSearch.captchaToken = captchaRes;
        prodSearch.sortBy = selectedSortby;

        setSearchPageNum(page => page + 1);

        const req = new ARequest<ProductSearch>(auth.token);
        req.data = prodSearch;

        let token = $("meta[name='_csrf']").attr("content");
        let headerName = $("meta[name='_csrf_header']").attr("content");

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader(headerName!, token!);
            }
        });

        return $.ajax({
            url: Constants.BASE_URL + 'api/tracker/products/search',
            method: 'POST',
            data: JSON.stringify(req),
            processData: false,
            contentType: 'application/json'
        }).done(function (data) {
            setLoadingProducts(false);
            const r: AResponse<ProductSearchResults> = data;
            if (r.isError) {
                handleError(r);
            } else {
                if (productSearchResults.searchURL.trim().length == 0) {
                    setProductSearchResults(r.data);
                } else {
                    setProductSearchResults(sr => {
                        let psr = new ProductSearchResults();
                        psr.searchURL = sr.searchURL;
                        psr.totalResults = sr.totalResults;
                        psr.products = sr.products.concat(r.data.products);
                        return psr;
                    });
                }
            }
        });
    };

    const searchKeyPressHandler = (event: any) => {
        if (event.charCode == 13) {
            setAutocOptions([]);
            setProductSearchResults(new ProductSearchResults());
            setProductSearchText(productSearchTextInput);
            setSearchPageNum(1);
        }
    };

    const searchBtnPressHandler = () => {
        setProductSearchResults(new ProductSearchResults());
        setProductSearchText(productSearchTextInput);
        setSearchPageNum(1);
    };

    const infiniteRefSearch = useInfiniteScroll<HTMLDivElement>({
        hasNextPage: (searchPageNum <= 10 && selectedTabVal == 1 && productSearchText.trim().length > 0),
        loading: loadingProducts,
        onLoadMore: searchForProducts,
        scrollContainer: "window",
        threshold: 5000
    });

    useEffect(() => {
        if (productSearchText.trim().length > 0) {
            setSearchPageNum(1);
        }

        let count: number = 0;
        if (filterPrime) {
            count++;
        }

        if (freeShipping) {
            count++;
        }

        setFilterCount(count);
    }, [filterPrime, freeShipping]);

    const searchProgress = (
        <div className={classes.progress}>
            <BounceLoader color={theme.palette.primary.main} />
        </div>
    );

    const searchProductList = (
        <Container style={{ padding: '0px', display: (selectedTabVal == 1 ? 'block' : 'none') }}>
            <Container className={classes.productCardWrapper}>
                <div ref={infiniteRefSearch}>
                    {productSearchResults.products.map((x: ProductInfo) => (
                        <ProductCard
                            key={x.asin + '_sp'}
                            setNewProductsTracked={setNewProductsTracked}
                            setOpenBellTutorial={setBellTutorialOpen}
                            prodInfo={x}
                            updateProdCache={(prodInfo: ProductInfo) => { }}
                            showWarning={showWarning}
                        />
                    ))}
                </div>
            </Container>
        </Container>
    );

    const selectInputStores = (
        <WithHelp className={classes.formControl} section={HelpSections.SEARCH_STORE} setOpenHelp={setHelpOpen} setSection={setHelpSection}>
            <FormControl fullWidth variant="outlined">
                <TextField
                    select
                    variant="outlined"
                    label={lm.get(lang, Labels.MYPR_STORE)}
                    size="small"
                    id="select-mp"
                    value={selectedMPSearch}
                    onChange={handleMarketplaceChange}
                >
                    {marketplaces?.map(x => {
                        return (
                            <MenuItem key={x.marketplaceKey} value={x.marketplaceKey}>{lang == Languages.EN_US ? x.en : x.es}</MenuItem>
                        )
                    })}
                </TextField>
            </FormControl>
        </WithHelp>
    );

    const selectInputCats = (
        <WithHelp className={classes.formControl} section={HelpSections.SEARCH_CATEGORY} setOpenHelp={setHelpOpen} setSection={setHelpSection}>
            <FormControl fullWidth variant="outlined">
                <TextField
                    select
                    variant="outlined"
                    size="small"
                    label={lm.get(lang, Labels.MYPR_CATEGORY)}
                    id="select-ix"
                    value={selectedCat}
                    onChange={handleCatChange}
                >
                    {categories?.map(x => {
                        return (
                            <MenuItem key={x.searchIndex} value={x.searchIndex}>{x.displayName}</MenuItem>
                        )
                    })}
                </TextField>
            </FormControl>
        </WithHelp>
    );

    const selectSortBy = (
        <WithHelp className={classes.formControl} section={HelpSections.SEARCH_SORTBY} setOpenHelp={setHelpOpen} setSection={setHelpSection}>
            <FormControl fullWidth variant="outlined">
                <TextField
                    select
                    variant="outlined"
                    size="small"
                    label={lm.get(lang, Labels.MYPR_SORTBY)}
                    id="select-ix"
                    value={selectedSortby}
                    onChange={handleSortChange}
                >
                    <MenuItem key={'sortby_relevance'} value={0}>{lm.get(lang, Labels.MYPR_SORTBY_RELEVANCE)}</MenuItem>
                    <MenuItem key={'sortby_plth'} value={2}>{lm.get(lang, Labels.MYPR_SORTBY_PRICE_LTH)}</MenuItem>
                    <MenuItem key={'sortby_phtl'} value={1}>{lm.get(lang, Labels.MYPR_SORTBY_PRICE_HTL)}</MenuItem>
                    <MenuItem key={'sortby_new'} value={3}>{lm.get(lang, Labels.MYPR_SORTBY_NEWEST)}</MenuItem>
                    <MenuItem key={'sortby_featured'} value={4}>{lm.get(lang, Labels.MYPR_SORTBY_FEATURED)}</MenuItem>
                </TextField>
            </FormControl>
        </WithHelp>
    );



    const doAutoSuggest = useCallback(() => {
        if (productSearchTextInput.length <= 2 || mak.length == 0) {
            setAutocOptions([]);
            return;
        }

        if (lastMaQ.trim() == productSearchTextInput.trim()) {
            return;
        }

        setLastMaQ(productSearchTextInput);

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Ocp-Apim-Subscription-Key", mak);
            }
        });

        if (loadingProducts) {
            setAutocOptions([]);
            return;
        }

        let aCall = $.ajax({
            url: 'https://api.bing.microsoft.com/v7.0/suggestions?q=' + encodeURIComponent(productSearchTextInput),
            method: 'GET',
        }).done(function (data) {
            let ss = data.suggestionGroups[0].searchSuggestions as SearchAction[];
            if (loadingProducts) {
                setAutocOptions([]);
            } else {
                setAutocOptions(ss.map(x => x.displayText));
            }
        });
    }, [productSearchTextInput, mak, setAutocOptions, setLastMaQ, lastMaQ]);

    useEffect(() => {
        let autocI = setInterval(() => {
            doAutoSuggest();
        }, 500);
        return () => {
            clearInterval(autocI);
        }
    }, [doAutoSuggest]);

    useEffect(() => {
        if (productSearchTextInput.trim().length <= 2) {
            setAutocOptions([]);
        }
    }, [productSearchTextInput, setAutocOptions]);

    useEffect(() => {
        if (autoCompClicked && productSearchTextInput.trim().length > 0) {
            setAutoCompClicked(false);
            searchBtnPressHandler();
        }
    }, [productSearchTextInput, autoCompClicked, setAutoCompClicked]);

    const inputSearch = (
        <WithHelp className={classes.formControl} section={HelpSections.SEARCH_SEARCH} setOpenHelp={setHelpOpen} setSection={setHelpSection}>
            <FormControl fullWidth variant="outlined">
                <Autocomplete
                    options={autocOptions}
                    fullWidth
                    value={productSearchTextInput}
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue != null) {
                            setProductSearchTextInput(newValue!);
                            setAutoCompClicked(true);
                        }
                    }}
                    freeSolo
                    disableClearable
                    size='small'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="search-text"
                            label={lm.get(lang, Labels.MYPR_SEARCHBOX_PLACEHOLDER)}
                            variant="outlined"
                            // value={productSearchTextInput}
                            onKeyPress={searchKeyPressHandler}
                            size='small'
                            fullWidth
                            onChange={(event) => {
                                setProductSearchTextInput(event.target.value);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {productSearchTextInput != undefined && productSearchTextInput.length > 0 && <IconButton onClick={() => { setProductSearchTextInput(''); setAutocOptions([]); }}><CloseIcon /></IconButton>}
                                        <IconButton onClick={searchBtnPressHandler}><Search /></IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />)}
                />

            </FormControl>
        </WithHelp>
    );

    const filterInput = (
        <WithHelp className={classes.formControl} section={HelpSections.SEARCH_FILTERS} setOpenHelp={setHelpOpen} setSection={setHelpSection}>
            <Box>
                <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                        <Typography variant='body1'>{lm.get(lang, Labels.MYPR_FILTERS)} ({filterCount})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={filterPrime}
                                    onChange={() => { setFilterPrime(!filterPrime); }}
                                    name="filterPrimeSwitch"
                                    color='primary'
                                />
                            }
                            label={<Typography variant='body2'>{lm.get(lang, Labels.MYPR_PRIME)}</Typography>}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={freeShipping}
                                    onChange={() => { setFreeShipping(!freeShipping); }}
                                    name="freeShippingSwitch"
                                    color='primary'
                                />
                            }
                            label={<Typography variant='body2'>{lm.get(lang, Labels.MYPR_FREE_SHIPPING)}</Typography>}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </WithHelp>
    );

    const noSitesSelected = (
        <Container className={classes.noTrackedProducts}>
            <Typography variant='h5'>{lm.get(lang, Labels.MYPR_NO_STORE_SELECTED)}</Typography>
            <Box className={classes.noTrackedProductsBtns}>
                <Button onClick={() => { history.push("/my-sites") }} fullWidth variant='contained' color='primary'>
                    {lm.get(lang, Labels.MYPR_NO_STORE_SELECTED_BTN)}
                </Button>
            </Box>
        </Container>
    );

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar variant='dense'>
                    <IconButton edge="start" color="inherit" onClick={() => { history.push("/home") }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif" }} variant="h6">
                        {lm.get(lang, Labels.MYPR_MY_PRODUCTS)}
                    </Typography>
                    {windowScrollYVal > 1000 && (
                        <Fab className={classes.backUpFab} size='medium' aria-label="Back up" onClick={() => { window.scrollTo(0, 0); }}>
                            <ArrowUpward />
                        </Fab>
                    )}
                </Toolbar>
            </AppBar>
            <Paper square>
                <Tabs value={selectedTabVal} aria-label={lm.get(lang, Labels.MYPR_MY_PRODUCTS)} variant="fullWidth" onChange={handleChange}>
                    <Tab label={lm.get(lang, Labels.MYPR_MY_PRODUCTS)} id="tab-1" aria-controls="tab-1" fullWidth />
                    <Tab label={lm.get(lang, Labels.MYPR_SEARCH)} id="tab-2" aria-controls="tab-2" fullWidth />
                </Tabs>
            </Paper>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={selectedTabVal}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={0} index={0} dir={theme.direction}>
                    {!loadingStores && (<Box className={classes.myPrOptions}>{selectInputStores_MyPr} {productCardViewOptions}</Box>)}
                    {trackedProductList}
                    {!loadingProducts_MyPr && !trackedProdsHasMore && trackedProducts.length <= 0 && noTrackedProducts}
                </TabPanel>
                {/* <TabPanel value={1} index={1} dir={theme.direction}>
                    Item Two
                </TabPanel> */}
                <TabPanel value={1} index={1} dir={theme.direction}>
                    {loadingStores && inputProgress}
                    {!loadingStores && marketplaces.length > 0 && selectInputStores}
                    {marketplaces.length > 0 && loadingCats && inputProgress}
                    {marketplaces.length > 0 && (loadingCats || loadingStores) && inputProgress}
                    {marketplaces.length > 0 && !loadingCats && !loadingStores && inputSearch}
                    <div style={{ width: '91%', textAlign: 'center' }}>
                        {marketplaces.length > 0 && !loadingCats && !loadingStores &&
                            <Button className={classes.moreSearchOptsBtn} variant='text' size='small' onClick={() => setMoreSearchOptsOpen(true)}>
                                {lm.get(lang, Labels.MYPR_MORE_OPTIONS)}
                            </Button>
                        }
                    </div>
                    {!loadingStores && marketplaces.length <= 0 && noSitesSelected}
                    {searchProductList}
                    {searchPageNum < 3 && loadingProducts && searchProgress}
                    {searchPageNum >= 3 && loadingProducts && inputProgress}
                </TabPanel>
            </SwipeableViews>
            <Drawer anchor={'bottom'} open={moreSearchOptsOpen} onClose={() => setMoreSearchOptsOpen(false)}>
                <Container className={wide ? classes.searchOptsDrawerWide : classes.searchOptsDrawerMobile}>
                    <Container className={wide && !smallWide ? classes.searchOptsDrawerInnerL : (wide ? classes.searchOptsDrawerInnerS : classes.searchOptsDrawerInnerMobile)}>
                        <Box className={classes.searchOptsDrawerContent}>
                            <div style={{ width: '91%', textAlign: 'center' }}>
                                <IconButton style={{ paddingTop: '0' }} onClick={() => setMoreSearchOptsOpen(false)}><ArrowDownwardIcon></ArrowDownwardIcon></IconButton>
                            </div>
                            {marketplaces.length > 0 && !loadingCats && selectInputCats}
                            {marketplaces.length > 0 && !loadingCats && !loadingStores && filterInput}
                            {marketplaces.length > 0 && !loadingCats && selectSortBy}
                        </Box>
                    </Container>
                </Container>
            </Drawer>
            <BellIconTutorial
                open={bellTutorialOpen}
                setOpen={setBellTutorialOpen}
                showDontShowAgain={true}
            ></BellIconTutorial>
            <GenericHelpDialog open={helpOpen} setOpen={setHelpOpen} section={helpSection}></GenericHelpDialog>
            <BroSnack
                autoHideDuration={6000}
                open={sbOpen}
                message={sbMsg}
                snackType={sbType}
                onSnackClose={() => { setSbOpen(false) }}
            />
        </div>
    )
}

export default MyProducts
