import { Box, Button, ButtonBase, Container, createStyles, Dialog, IconButton, LinearProgress, Link, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Add, Check, CheckBox, CheckBoxOutlineBlank, Close, Edit, OpenInNew, Remove, Timeline } from '@material-ui/icons';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ProductInfo from '../dto/response/ProductInfo';
import NumberFormatInput from './NumberFormatInput';
import lm from './LabelManager';
import { Labels } from './enums/Labels';
import { authContext } from '../contexts/AuthContext';
import { langContext } from '../contexts/LangContext';
import BuyProduct from './BuyProduct';
import ScaleLoader from "react-spinners/ScaleLoader";
import theme from '../CTheme';

interface ProductDetailDialogProps {
    prodInfo: ProductInfo,
    open: boolean,
    tracking: boolean,
    trackingLoad: boolean,
    editingTargetPrice: boolean,
    targetPrice: number,
    trackDate: Date,
    setDetailsOpen: (open: boolean) => void,
    setPriceHistoryOpen: (open: boolean) => void,
    trackHandler: (prodInfo: ProductInfo) => () => void,
    untrackHandler: (prodInfo: ProductInfo) => () => void,
    setTargetPrice: (value: number) => void,
    setEditingTargetPrice: (editing: boolean) => void,
    setTrackPriceHandler: (prodInfo: ProductInfo) => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prodBinding: {
            fontSize: '.6em',
            color: theme.palette.text.disabled
        },
        prodContributors: {
            fontSize: '.6em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.text.disabled
        },
        prodDetailsDialog: {
            padding: '1em'
        },
        prodDDetailsBox: {
            maxHeight: '31em',
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '0px',
            margin: '0px',
            width: '100%'
        },
        prodDetailsSlider: {
            maxHeight: '14em',
            minHeight: '14em',
            marginBottom: '2em',
        },
        prodDetailsTitle: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            maxWidth: '94%'
        },
        prodDetailsPriceHeader: {
            fontSize: '.8em',
            marginTop: '1em',
            color: theme.palette.text.secondary
        },
        prodDetailsCost: {
            fontSize: '1.2em',
            fontWeight: 'bold',
        },
        prodDetailsOldCost: {
            fontSize: '1.2em',
            marginBottom: '.8em',
        },
        prodDetailsAv: {
            fontSize: '1em'
        },
        prodDetailsPrime: {
            display: 'inline-block',
            fontSize: '1em'
        },
        prodDetailsPrimeCheck: {
            display: 'inline-block',
            fontSize: '1.2em',
            position: 'relative',
            top: '.2em',
            marginLeft: '.25em'
        },
        prodDetailsTrackDate: {
            fontSize: '1em',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '.5em',
            padding: '.1em .5em',
            marginTop: '.5em',
            marginBottom: '.5em',
        },
        propDetailsFeatures: {
            marginTop: '1em'
        },
        propDetailsFeaturesTitle: {
            fontSize: '1em',
        },
        propDetailsFeature: {
            fontSize: '.8em',
        },
        prodDetailsButtonsBox: {
            marginTop: '3em',
            // display: 'flex',
            marginBottom: '1em',
            maxWidth: '98%'
        },
        prodDetailsButton: {
            marginLeft: '.25em',
            marginRight: '.25em',
            height: '2em',
            fontSize: '.9em',
            marginBottom: '.5em'
        },
        prodDetailsCloseBtn: {
            position: 'absolute',
            right: '0em',
            top: '0em'
        },
        prodPriceTargetInput: {
            display: 'inline-block',
            marginBottom: '.5em',
            marginTop: '.5em'
        },
        prodPriceTargetText: {
            display: 'inline-block',
            fontSize: '1.2em',
            marginBottom: '.5em'
        },
        prodSetPriceTargetText: {
            marginBottom: '1em',
            fontSize: '1em'
        },
        prodSetPriceTargetBtn: {
            // fontSize: '.7em',
            fontWeight: 'normal',
            // padding: '.1em .4em',
            marginBottom: '.5em'
        },
        prodPriceTargetLabel: {
            fontSize: ".8em",
            position: 'relative',
            bottom: '.2em'
        },
        prodPriceTargetBtn: {
            backgroundColor: theme.palette.primary.main,
            padding: '.45em .4em',
            borderRadius: '.25em',
            boxShadow: theme.shadows[2],
            marginLeft: '.5em',
            marginTop: '.55em'
        },
        prodPriceTargetBtnIcon: {
            color: '#FFF'
        },
        prodPriceTargetIconBtn: {
            padding: '0px',
            position: 'relative',
            bottom: '.1em',
            left: '.5em'
        },
        prodTargetPriceHeader: {
            fontSize: '.8em',
            marginTop: '.5em',
            color: theme.palette.primary.main
        },
    }),
);

function ProductDetailsDialog(props: ProductDetailDialogProps) {
    const { setAuthData, auth } = useContext(authContext);
    const [buyLoading, setBuyLoading] = useState<boolean>(false);
    const { lang } = useContext(langContext);
    const classes = useStyles();

    return (
        <Dialog open={props.open} id={props.prodInfo.asin + '-details'}
            onClose={() => { props.setDetailsOpen(false); }}
        >
            <Container className={classes.prodDetailsDialog}>
                <IconButton className={classes.prodDetailsCloseBtn} onClick={() => { props.setDetailsOpen(false) }}><Close /></IconButton>
                <Typography className={classes.prodDetailsTitle}>{props.prodInfo.title}</Typography>
                <Box>
                    <Typography className={classes.prodBinding}>{props.prodInfo.binding}</Typography>
                </Box>
                <Box>
                    <Typography className={classes.prodContributors}>{ProductInfo.getFormattedContributors(props.prodInfo)}</Typography>
                </Box>
                <Box className={classes.prodDDetailsBox}>
                    {props.tracking ? (
                        <Box className={classes.prodDetailsTrackDate}>
                            {lm.get(lang, Labels.C_TRACKING_SINCE)} {moment(props.trackDate).format('DD/MMM/YYYY')}
                        </Box>
                    ) : null}
                    <Container>
                        <Slider adaptiveHeight dots infinite speed={500} slidesToScroll={1} slidesToShow={1} className={classes.prodDetailsSlider} >
                            {ProductInfo.getAllImages(props.prodInfo).map((x) => (
                                <div key={x.url}>
                                    <div style={{ display: 'flex', minHeight: '12em' }}>
                                        <img style={{ maxHeight: '12em', maxWidth: '16em', margin: 'auto' }} src={x.url}></img>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Container>
                    <Box className={classes.prodDetailsButtonsBox}>
                        <Button className={classes.prodDetailsButton} fullWidth variant='contained' color='secondary'
                            endIcon={!buyLoading && <OpenInNew fontSize='small' />}
                            disabled={buyLoading}
                            onClick={() => {
                                BuyProduct.click(buyLoading, setBuyLoading, props.prodInfo, auth.token, lang);
                            }}
                        >                            
                            {buyLoading ? <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} /> : lm.get(lang, Labels.C_BUY_ON_AMZ)}
                        </Button>

                        {/* <Button variant='contained' fullWidth className={classes.prodDetailsButton} color='primary' endIcon={<Timeline fontSize='small' />}
                            onClick={() => { props.setPriceHistoryOpen(true) }}
                        >{lm.get(lang, Labels.C_PRICE_HISTORY)}</Button> */}

                        {props.tracking ?
                            (<Button className={classes.prodDetailsButton} variant='contained' color='primary' fullWidth endIcon={<Remove />} onClick={props.untrackHandler(props.prodInfo)}>{lm.get(lang, Labels.C_UNTRACK)}</Button>) :
                            (<Button className={classes.prodDetailsButton} variant='contained' color='primary' fullWidth endIcon={<Add />} onClick={props.trackHandler(props.prodInfo)}>{lm.get(lang, Labels.C_TRACK)}</Button>)
                        }
                    </Box>
                    {props.tracking ? (
                        <Box className={classes.prodDetailsPriceHeader}>
                            {lm.get(lang, Labels.C_CURRENT_PRICE)}
                        </Box>
                    ) : null}
                    <Box>
                        <Typography className={classes.prodDetailsCost}>{props.prodInfo.price + ' ' + props.prodInfo.currency}</Typography>
                    </Box>
                    {/* {props.tracking && props.prodInfo.trackInfo.initialPrice > 0 ? (
                        <Box className={classes.prodDetailsPriceHeader}>
                            {lm.get(lang, Labels.C_PRICE_WHEN_START_FOLLOW)}
                        </Box>
                    ) : null} */}
                    {/* {props.tracking && props.prodInfo.trackInfo.initialPrice > 0 ? (
                        <Box>
                            <Typography className={classes.prodDetailsOldCost}>
                                {'$'
                                    + props.prodInfo.trackInfo.initialPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                    + ' '
                                    + props.prodInfo.trackInfo.currency}
                            </Typography>
                        </Box>
                    ) : null} */}
                    {props.tracking && !props.trackingLoad ? (
                        <Box >
                            {!props.editingTargetPrice && props.targetPrice > 0 && (
                                <Box className={classes.prodTargetPriceHeader}>
                                    {lm.get(lang, Labels.C_TARGET_PRICE)}
                                </Box>
                            )}
                            {props.editingTargetPrice ? (
                                <TextField
                                    value={props.targetPrice > 0 ? props.targetPrice : ''}
                                    id={props.prodInfo.asin + '_priceTarget'}
                                    onChange={(event) => {
                                        let n = parseFloat(event.target.value);
                                        props.setTargetPrice(n);
                                    }}
                                    className={classes.prodPriceTargetInput}
                                    variant='outlined'
                                    size='small'
                                    label={(<Typography className={classes.prodPriceTargetLabel}>{lm.get(lang, Labels.C_TARGET_PRICE)}</Typography>)}
                                    InputProps={{
                                        inputComponent: NumberFormatInput as any,
                                    }}

                                >
                                </TextField>
                            ) : props.targetPrice > 0 ? (
                                <Typography className={classes.prodPriceTargetText}>{'$' + props.targetPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                            ) : (
                                <Button
                                    className={classes.prodSetPriceTargetBtn}
                                    onClick={() => { props.setEditingTargetPrice(true); }}
                                    size='small'
                                    variant='outlined'
                                    color='primary'
                                >
                                    {lm.get(lang, Labels.C_SET_TARGET_PRICE)}
                                </Button>
                            )}
                            {props.editingTargetPrice ? (
                                <ButtonBase
                                    className={classes.prodPriceTargetBtn}
                                    onClick={() => {
                                        props.setEditingTargetPrice(false);
                                        props.setTrackPriceHandler(props.prodInfo);
                                    }}
                                >
                                    <Check className={classes.prodPriceTargetBtnIcon} fontSize="default" />
                                </ButtonBase>
                            ) : props.targetPrice > 0 ? (
                                <IconButton className={classes.prodPriceTargetIconBtn} onClick={() => { props.setEditingTargetPrice(true); }}><Edit fontSize='small' /></IconButton>
                            ) : null}
                        </Box>
                    ) : props.tracking && props.trackingLoad ? (
                        <LinearProgress></LinearProgress>
                    ) : null}
                    <Box>
                        <Typography className={classes.prodDetailsAv}>{props.prodInfo.availability}</Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.prodDetailsPrime}>{lm.get(lang, Labels.C_PRIME)}</Typography>
                        {props.prodInfo.prime ? (
                            <CheckBox className={classes.prodDetailsPrimeCheck} color='secondary' />
                        ) : (
                            <CheckBoxOutlineBlank className={classes.prodDetailsPrimeCheck} color='secondary' />
                        )}
                    </Box>

                    {props.prodInfo.features.length > 0 ? (
                        <Box className={classes.propDetailsFeatures}>
                            <Typography className={classes.propDetailsFeaturesTitle}>{lm.get(lang, Labels.C_ABOUT_ITEM)}</Typography>
                            <ul style={{ marginTop: '.5em' }}>
                                {props.prodInfo.features.map((x, ix) => (
                                    <li key={props.prodInfo.asin + '_' + ix + '_feat'}><Typography className={classes.propDetailsFeature}>{x}</Typography></li>
                                ))}
                            </ul>
                        </Box>
                    ) : null}
                </Box>
            </Container>
        </Dialog>
    )
}

export default ProductDetailsDialog
