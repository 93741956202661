import { Box, Button, Container, createStyles, Dialog, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import CloseIcon from '@material-ui/icons/Close';
import { langContext } from '../contexts/LangContext';
import { Languages } from './enums/Languages';
import APIRequests from './APIRequests';
import AResponse from '../dto/AResponse';
import Constants from './Constants';
import ARequest from '../dto/ARequest';
import { authContext } from '../contexts/AuthContext';


interface BITProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    showDontShowAgain: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '1em',
            minWidth: '15rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        iconTitle: {
            marginTop: '.25em',
            fontSize: '4rem',
            color: theme.palette.extra2.main
        },
        closeBtn: {
            position: 'absolute',
            right: '0em',
            top: '0em'
        },
        textBoxTitle: {
            minWidth: '100%',
            textAlign: 'center',
            marginTop: '1em',
        },
        textBox: {
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '1em',
        },
        textBox2: {
            minWidth: '100%',
            textAlign: 'left',
            marginTop: '3em',
        },
        imgBox: {
            marginTop: '1em',
            textAlign: 'center'
        },
        iconInTextA: {
            color: theme.palette.extra2.main
        },
        settingsImg: {
            maxWidth: '70%',
            border: '1px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '.5em'
        },
        settingsImgNb: {
            maxWidth: '70%',            
            borderRadius: '.5em'
        }
    }),
);

function BellIconTutorial(props: BITProps) {
    const classes = useStyles();
    const { auth } = useContext(authContext);
    const { lang } = useContext(langContext);
    const [tutorialComplete, setTutorialComplete] = useState<boolean>(false);

    useEffect(() => {
        const req = new ARequest<string>(auth.token);
        req.data = "";
        APIRequests.post(Constants.BASE_URL + 'api/user/prefs/getBellTutorialComplete', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                setTutorialComplete(false);                
            } else {
                setTutorialComplete(r.data);
            }
        });
    }, []);

    const markTutorialComplete = () => {
        setTutorialComplete(true);
        const req = new ARequest<string>(auth.token);
        req.data = "";
        APIRequests.post(Constants.BASE_URL + 'api/user/prefs/markBellTutorialComplete', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                //do nothing for now                        
            } else {
                setTutorialComplete(r.data);
            }
        });
    };

    const isDialogOpen = () => {
        let isOpen = props.open;

        if(props.showDontShowAgain){
            isOpen = props.open && !tutorialComplete;
        }

        return isOpen;
    }
    
    return (
        <Dialog open={isDialogOpen()} onClose={() => { props.setOpen(false); }}>
            {lang === Languages.ES_MX ?
                <Container className={classes.root}>
                    <IconButton className={classes.closeBtn} onClick={() => { props.setOpen(false) }}><CloseIcon /></IconButton>
                    <NotificationImportantIcon className={classes.iconTitle}></NotificationImportantIcon>
                    <Box className={classes.textBoxTitle}>
                        <Typography variant='h6'>¿Qu&eacute; hace este boton?</Typography>
                    </Box>
                    <Box className={classes.textBox}>
                        <Typography variant='body1'>Cuando este bot&oacute;n est&aacute; activo <NotificationImportantIcon className={classes.iconInTextA}></NotificationImportantIcon> recibir&aacute;s notificaciones por correo inmediatas cada vez que baje de precio este producto.</Typography>
                    </Box>
                    <Box className={classes.imgBox}>
                        <img className={classes.settingsImgNb} src='/img/bellTutorial/priority_es.png'></img>
                    </Box>
                    <Box className={classes.textBox}>
                        <Typography variant='body1'>Esta alerta <NotificationImportantIcon className={classes.iconInTextA}></NotificationImportantIcon> ignora tu configuraci&oacute;n de notificaciones, de manera que recibir&aacute;s correos inmediatos incluso si tu configuraci&oacute;n indica que solo quieres recibirlos una vez al d&iacute;a, o solo si alcanz&oacute; el precio meta.</Typography>
                    </Box>
                    <Box className={classes.imgBox}>
                        <img className={classes.settingsImg} src='/img/bellTutorial/settings_es.png'></img>
                    </Box>
                    <Box className={classes.textBox}>
                        <Typography variant='body1'>Puedes usar esta alerta en productos que te sean muy importantes, para que seas notificado inmediatamente sobre su cambio de precio.</Typography>
                    </Box>
                    <Box className={classes.textBox2}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>Nota</Typography>
                        <Typography variant='body1'>Si deshabilitas las notificaciones por correo, no recibir&aacute;s ning&uacute;n correo, incluso si estas alertas est&aacute;n activadas.</Typography>
                    </Box>
                    <Box className={classes.imgBox}>
                        <img className={classes.settingsImg} src='/img/bellTutorial/settings2_es.png'></img>
                    </Box>
                    
                    {props.showDontShowAgain &&
                        <Box className={classes.imgBox}>
                            <Button variant='contained' color='primary' onClick={markTutorialComplete}>No volver a mostrar este mensaje</Button>
                        </Box>
                    }
                </Container>
                :
                <Container className={classes.root}>
                    <IconButton className={classes.closeBtn} onClick={() => { props.setOpen(false) }}><CloseIcon /></IconButton>
                    <NotificationImportantIcon className={classes.iconTitle}></NotificationImportantIcon>
                    <Box className={classes.textBoxTitle}>
                        <Typography variant='h6'>What does this button do?</Typography>
                    </Box>
                    <Box className={classes.textBox}>
                        <Typography variant='body1'>When this button is active <NotificationImportantIcon className={classes.iconInTextA}></NotificationImportantIcon> you will receive immediate e-mail notifications every time the price drops on this product.</Typography>
                    </Box>
                    <Box className={classes.imgBox}>
                        <img className={classes.settingsImgNb} src='/img/bellTutorial/priority_en.png'></img>
                    </Box>
                    <Box className={classes.textBox}>
                        <Typography variant='body1'>This alert <NotificationImportantIcon className={classes.iconInTextA}></NotificationImportantIcon> will override your notification settings so that you will receive immediate e-mail notifications even if your settings indicate you only want to receive them once per day, or only when the target price is reached.</Typography>
                    </Box>
                    <Box className={classes.imgBox}>
                        <img className={classes.settingsImg} src='/img/bellTutorial/settings_en.png'></img>
                    </Box>
                    <Box className={classes.textBox}>
                        <Typography variant='body1'>You can use this button on products that are very important to you, in order to be notified of all price drops immediately.</Typography>
                    </Box>
                    <Box className={classes.textBox2}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>Note</Typography>
                        <Typography variant='body1'>If you disable e-mail notifications you will not receive any e-mails even if this button is active.</Typography>
                    </Box>
                    <Box className={classes.imgBox}>
                        <img className={classes.settingsImg} src='/img/bellTutorial/settings2_en.png'></img>
                    </Box>                    
                    {props.showDontShowAgain &&
                        <Box className={classes.imgBox}>
                            <Button variant='contained' color='primary' onClick={markTutorialComplete}>Don't show this message again</Button>
                        </Box>
                    }
                </Container>
            }

        </Dialog>
    )
}

export default BellIconTutorial
