import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './contexts/AuthContext';
import { ThemeProvider } from '@material-ui/core';
import theme from './CTheme';
import LangProvider from './contexts/LangContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap" />
      <link href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap" rel="stylesheet" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0"></meta>
      {/* <meta name="_csrf_header" content="csrf-fake"></meta>
      <meta name="_csrf" content="0"></meta>       */}
  
      <LangProvider>
        <AuthProvider>
          <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey="6LdgFYEaAAAAAL_lvZIIyBBIOQRfJlAG9rN_z5Cx"
            scriptProps={{ async: true, defer: true, appendTo: 'head' }}
            useEnterprise
          >
            <App />
          </GoogleReCaptchaProvider>
        </AuthProvider>
      </LangProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
