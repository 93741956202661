import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import APIRequests from '../components/APIRequests';
import Constants from '../components/Constants';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import { authContext } from './AuthContext';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
    children: ReactNode;
}

interface IEncContext {
    getKey:Promise<string>;
}

export const encContext = createContext<IEncContext>({getKey: new Promise<string>((resolve, reject) => {    
    reject('DEFAULT JSK REJECT');
})});

const EncProvider = ({ children }: IProps) => {    
    const { auth } = useContext(authContext);
    
    let key:string = "";   

    
    const gk:Promise<string> = new Promise<string>((resolve, reject) => {
        if(key.length > 0){            
            resolve(key);
        } else {   
            let ekval:string|null = localStorage.getItem(Constants.EK);
            if(ekval == null || ekval == undefined || ekval.length == 0){
                key = uuidv4();
                localStorage.setItem(Constants.EK, key);
                resolve(key);
            } else {
                resolve(ekval);
            }
            
            // if(auth.token == undefined || auth.token.length > 0){
            //     const req = new ARequest<string>(auth.token);
            //     req.data = "";                
            //     APIRequests.post(Constants.BASE_URL + "api/user/jsk", req, function (data: any) {
            //         const r: AResponse<string> = data;
            //         if (r.isError) {                        
            //             reject("JSKEY REQ REJECT. IS ERROR.");
            //         } else {                    
            //             key = r.data;
            //             resolve(key);                    
            //         }
            //     },() => {reject("JSKEY REJECT. PROBLEM LOADING KEY.")});
            // } else {                
            //     reject("JSKEY REJECT. AUTH NOT READY.");
            // }
        }
    });
    

    let encVal:IEncContext = {
        getKey: gk
    }

    return (
        <encContext.Provider value={encVal}>
          {children}
        </encContext.Provider>
      );
}

export default EncProvider;