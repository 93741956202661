import ARequest from "../dto/ARequest";
import $ from 'jquery';

export default class APIRequests {
    public static post(url: string, req:ARequest<any>|undefined , callback:(data:any) => void, failCallback?:((data:any) => void))    {
        let token = $("meta[name='_csrf']").attr("content");
        let headerName = $("meta[name='_csrf_header']").attr("content");
        
        if(token == undefined || headerName == undefined)
            return;

        $.ajaxSetup({
            beforeSend: function(xhr) {
                xhr.setRequestHeader(headerName!, token!);
            }
        });
        
        $.ajax({
            url: url,
            method: 'POST',
            data: req != undefined ? JSON.stringify(req):undefined,
            processData: false,            
            contentType: 'application/json'
        })
        .done(callback)
        .fail(failCallback ? failCallback : () => {});
    }
}
