import { Box, Button, ButtonBase, createStyles, Divider, IconButton, LinearProgress, Link, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Add, Check, CheckBox, CheckBoxOutlineBlank, Edit, Notes, OpenInNew, Remove, Timeline } from '@material-ui/icons';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import ProductInfo from '../dto/response/ProductInfo';
import ProductPriceTarget from '../dto/request/ProductPriceTarget';
import ProductTrack from '../dto/request/ProductTrack';
import APIRequests from './APIRequests';
import Constants from './Constants';
import NumberFormatInput from './NumberFormatInput';
import ProductDetailsDialog from './ProductDetailsDialog';
import ProductPriceHistoryDialog from './ProductPriceHistoryDialog';
import lm from './LabelManager';
import { Labels } from './enums/Labels';
import { langContext } from '../contexts/LangContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import ProductAlwaysNotify from '../dto/request/ProductAlwaysNotify';
import ProductUntrack from '../dto/request/ProductUntrack';
import BuyProduct from './BuyProduct';
import ScaleLoader from "react-spinners/ScaleLoader";
import theme from '../CTheme';

interface ProductCardProps {
    prodInfo: ProductInfo,
    setNewProductsTracked: (newProdsTracked: boolean) => void,
    updateProdCache: (prodInfo: ProductInfo) => void,
    setOpenBellTutorial: (open: boolean) => void,
    showWarning: (text: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prodBox: {
            borderRadius: '.5em',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.light,
            marginTop: '.5em',
            marginBottom: '.5em',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: theme.palette.background.default,
            padding: '1em .5em',
            position: 'relative'
            // maxWidth: '25em'
        },
        prodFlexBox: {
            display: 'flex',
            flexDirection: 'row',
        },
        prodLeftBox: {
            width: '27%',
            marginRight: '3%',
            maxWidth: '27%'
        },
        prodImg: {
            // width: '100%',
            maxWidth: '100%',
            maxHeight: '14em',
            borderRadius: '.25em',
            cursor: 'pointer'
        },
        prodRightBox: {
            maxWidth: '70%',
            width: '70%'
        },
        prodTitleBox: {
            position: 'relative'
        },
        prodTitle: {
            fontSize: '.8em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            marginRight: '2rem',
            cursor: 'pointer'
        },
        importantIconBox: {
            position: 'absolute',
            right: '0rem',
            top: '-.5rem',
        },
        importantIconNone: {
            color: theme.palette.grey[400]
        },
        importantIconAll: {
            color: theme.palette.extra2.main
        },
        prodCost: {
            fontSize: '1em',
            fontWeight: 'bold',
            display: 'inline-block'
        },
        prodOldCost: {
            fontSize: '1em',
            marginBottom: '.5em',
        },
        prodAv: {
            fontSize: '.8em'
        },
        prodBinding: {
            fontSize: '.6em',
            color: theme.palette.text.disabled,
            lineHeight: '1.1'
        },
        prodContributors: {
            fontSize: '.6em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // maxWidth:'22em',
            color: theme.palette.text.disabled,
            lineHeight: '1.1',
            marginBottom: '.2em'
        },
        prodDetailsBox: {
            marginTop: '.1em'
        },
        prodPrime: {
            display: 'inline-block',
            fontSize: '.8em'
        },
        prodPrimeCheck: {
            display: 'inline-block',
            fontSize: '1em',
            position: 'relative',
            top: '.2em',
            marginLeft: '.25em'
        },
        prodButtonsBox: {
            marginTop: '.5em',
            display: 'flex',
        },
        prodButton: {
            marginLeft: '.25em',
            marginRight: '.25em',
            height: '2.1em',
            fontSize: '.8em'
        },
        prodTrackDate: {
            fontSize: '.8em',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '.5em',
            padding: '.1em .5em',
            marginTop: '1em',
            marginBottom: '1em'
        },
        prodPriceHeader: {
            fontSize: '.6em',
            marginTop: '.5em',
            color: theme.palette.text.secondary
        },
        propPriceHistoryBtn: {
            backgroundColor: theme.palette.primary.main,
            padding: '0em .3em',
            borderRadius: '.25em',
            boxShadow: theme.shadows[2],
            marginLeft: '.5em'
        },
        propPriceHistoryBtnIcon: {
            color: '#FFF'
        },
        prodPriceTargetInput: {
            display: 'inline-block',
            marginBottom: '.5em',
            marginTop: '.5em',
            maxWidth: '7em'
        },
        prodPriceTargetText: {
            display: 'inline-block',
            marginBottom: '.5em'
        },
        prodSetPriceTargetText: {
            marginBottom: '.5em',
            fontSize: '.8em'
        },
        prodSetPriceTargetBtn: {
            fontSize: '.7em',
            fontWeight: 'normal',
            padding: '.1em .4em',
            marginBottom: '.5em'
        },
        prodPriceTargetLabel: {
            fontSize: ".8em",
            position: 'relative',
            bottom: '.2em'
        },
        prodPriceTargetBtn: {
            backgroundColor: theme.palette.primary.main,
            padding: '.45em .4em',
            borderRadius: '.25em',
            boxShadow: theme.shadows[2],
            marginLeft: '.5em',
            marginTop: '.55em'
        },
        prodPriceTargetBtnIcon: {
            color: '#FFF'
        },
        prodPriceTargetIconBtn: {
            padding: '0px',
            position: 'relative',
            bottom: '.1em',
            left: '.5em'
        },
        prodTargetPriceHeader: {
            fontSize: '.6em',
            marginTop: '.5em',
            color: theme.palette.primary.main
        },
        footerFlag: {
            position: 'absolute',
            left: '1em',
            bottom: '1em',
            width: '1.1em',
            height: '1.1em',
        },
    }),
);

function ProductCard(props: ProductCardProps) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { setAuthData, auth } = useContext(authContext);
    const { lang } = useContext(langContext);
    const history = useHistory();
    const classes = useStyles();
    const [tracking, setTracking] = useState<boolean>(props.prodInfo.trackInfo.tracking);
    const [trackingLoad, setTrackingLoad] = useState<boolean>(false);
    const [priceHistoryOpen, setPriceHistoryOpen] = useState<boolean>(false);
    const [trackDate, setTrackDate] = useState<Date>(props.prodInfo.trackInfo.dateAdded);
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const [targetPrice, setTargetPrice] = useState<number>(props.prodInfo.trackInfo.targetPrice);
    const [editingTargetPrice, setEditingTargetPrice] = useState<boolean>(false);
    const [alwaysNotify, setAlwaysNotify] = useState<boolean>(props.prodInfo.alwaysNotify);
    const [loadingAlwaysNotify, setLoadingAlwaysNotify] = useState<boolean>(false);
    const [buyLoading, setBuyLoading] = useState<boolean>(false);

    const logout = () => {
        setAuthData(new AuthType(false));
        history.push("/");
    }

    const handleError = (res: AResponse<any>) => {
        if (res.intCode == 503) {
            logout();
        } else {
            if(res.message.trim().length > 0)
                props.showWarning(res.message.trim());
        }
    };

    const trackHandler = (prodInfo: ProductInfo) => async () => {
        setTracking(true);
        setTrackingLoad(true);
        setTrackDate(new Date());

        let captchaRes: string = "";
        try {
            captchaRes = await executeRecaptcha('track');
        } catch { };

        const req = new ARequest<ProductTrack>(auth.token);
        const pt = new ProductTrack();
        pt.asin = prodInfo.asin;
        pt.currency = prodInfo.currency;
        pt.marketplaceKey = prodInfo.marketplaceKey;
        pt.priceAmount = prodInfo.priceAmount;
        pt.captchaToken = captchaRes;
        pt.prime = prodInfo.prime;
        req.data = pt;

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/track', req, function (data) {
            setTrackingLoad(false);
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                setTracking(false);
            } else {
                props.setNewProductsTracked(true);
                setTracking(r.data);
            }
        });
    };

    const untrackHandler = (prodInfo: ProductInfo) => () => {
        setTracking(false);
        const req = new ARequest<ProductUntrack>(auth.token);
        const pu = new ProductUntrack();
        pu.asin = prodInfo.asin;
        pu.marketplaceKey = prodInfo.marketplaceKey;
        req.data = pu;

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/untrack', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);                
                setTracking(true);
            } else {
                props.setNewProductsTracked(true);
                setTracking(r.data);
            }
        });
    };

    const setTrackPriceHandler = (prodInfo: ProductInfo) => {
        const req = new ARequest<ProductPriceTarget>(auth.token);

        const ppt = new ProductPriceTarget();
        ppt.asin = prodInfo.asin;
        ppt.marketplaceKey = prodInfo.marketplaceKey;
        ppt.targetPrice = targetPrice;
        req.data = ppt;
        if (prodInfo.trackInfo) {
            prodInfo.trackInfo.targetPrice = targetPrice;
            props.updateProdCache(prodInfo);
        }

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/setTargetPrice', req, function (data) {
            const r: AResponse<boolean> = data;
            if (r.isError) {
                handleError(r);
                if (prodInfo.trackInfo) {
                    prodInfo.trackInfo.targetPrice = 0;
                    props.updateProdCache(prodInfo);
                }
            } else {
                if (!r.data) {
                    setTargetPrice(0);
                    setEditingTargetPrice(true);
                    if (prodInfo.trackInfo) {
                        prodInfo.trackInfo.targetPrice = 0;
                        props.updateProdCache(prodInfo);
                    }
                }
            }
        });
    }

    const alwaysNotifyHandler = (prodInfo: ProductInfo, an: boolean) => {
        if (loadingAlwaysNotify) return;
        setLoadingAlwaysNotify(true);
        props.setOpenBellTutorial(true);
        const req = new ARequest<ProductAlwaysNotify>(auth.token);
        const pt = new ProductAlwaysNotify();
        pt.asin = prodInfo.asin;
        pt.marketplaceKey = prodInfo.marketplaceKey;
        pt.alwaysNotify = an;
        req.data = pt;
        const prev = alwaysNotify;
        setAlwaysNotify(an);
        prodInfo.alwaysNotify = an;
        props.updateProdCache(prodInfo);

        APIRequests.post(Constants.BASE_URL + 'api/tracker/products/setAlwaysNotify', req, function (data) {
            const r: AResponse<boolean> = data;
            setLoadingAlwaysNotify(false);
            if (r.isError) {
                handleError(r);
                setAlwaysNotify(prev);
                prodInfo.alwaysNotify = prev;
                props.updateProdCache(prodInfo);
            }
        });
    };

    const getFlagName = (mpKey: string): string | undefined => {
        switch (mpKey) {
            case 'MEXICO':
                return 'mx';
            case 'USA':
                return 'us';
            case 'SPAIN':
                return 'sp';
            case 'UK':
                return 'uk';
            default:
                return undefined;
        }
    };

    return (
        <Box boxShadow={0} className={classes.prodBox}>
            <Box className={classes.prodFlexBox}>
                <Box className={classes.prodLeftBox}>
                    <img onClick={() => { setDetailsOpen(true) }} className={classes.prodImg} src={ProductInfo.getLargestPrimary(props.prodInfo).url} />
                </Box>
                <Box className={classes.prodRightBox}>
                    <Box className={classes.prodTitleBox} >
                        <Typography onClick={() => { setDetailsOpen(true) }} className={classes.prodTitle}>{props.prodInfo.title}</Typography>
                        {tracking &&
                            <Box className={classes.importantIconBox}>
                                {alwaysNotify ?
                                    <IconButton onClick={() => { alwaysNotifyHandler(props.prodInfo, false) }} style={{ padding: '0' }}>
                                        <NotificationImportantIcon className={classes.importantIconAll} />
                                    </IconButton>
                                    :
                                    <IconButton onClick={() => { alwaysNotifyHandler(props.prodInfo, true) }} style={{ padding: '0' }}>
                                        <NotificationImportantIcon className={classes.importantIconNone} />
                                    </IconButton>
                                }
                            </Box>
                        }
                    </Box>
                    <Divider />
                    <Box className={classes.prodDetailsBox}>
                        <Box>
                            <Typography className={classes.prodBinding}>{props.prodInfo.binding}</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.prodContributors}>{ProductInfo.getFormattedContributors(props.prodInfo)}</Typography>
                        </Box>
                        {tracking && (
                            <Box className={classes.prodPriceHeader}>
                                {lm.get(lang, Labels.C_CURRENT_PRICE)}
                            </Box>
                        )}
                        <Box>
                            <Typography className={classes.prodCost}>{props.prodInfo.price + ' ' + props.prodInfo.currency}</Typography>

                            {/* <ButtonBase
                                focusRipple
                                className={classes.propPriceHistoryBtn}
                                onClick={() => {
                                    setPriceHistoryOpen(true);
                                }}
                            >
                                <Timeline className={classes.propPriceHistoryBtnIcon} fontSize="small" />
                            </ButtonBase> */}

                        </Box>
                        {/* {tracking && props.prodInfo.trackInfo.initialPrice > 0 && (
                            <Box className={classes.prodPriceHeader}>
                                {lm.get(lang, Labels.C_PRICE_WHEN_START_FOLLOW)}
                            </Box>
                        )}
                        {tracking && props.prodInfo.trackInfo.initialPrice > 0 && (
                            <Box>
                                <Typography className={classes.prodOldCost}>
                                    {'$'
                                        + props.prodInfo.trackInfo.initialPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                        + ' '
                                        + props.prodInfo.trackInfo.currency}
                                </Typography>
                            </Box>
                        )} */}
                        {tracking && !trackingLoad ? (
                            <Box >
                                {!editingTargetPrice && targetPrice > 0 && (
                                    <Box className={classes.prodTargetPriceHeader}>
                                        {lm.get(lang, Labels.C_TARGET_PRICE)}
                                    </Box>
                                )}
                                {editingTargetPrice ? (
                                    <TextField
                                        value={targetPrice > 0 ? targetPrice : ''}
                                        id={props.prodInfo.asin + '_priceTarget'}
                                        onChange={(event) => {
                                            let n = parseFloat(event.target.value);
                                            setTargetPrice(n);
                                        }}
                                        className={classes.prodPriceTargetInput}
                                        variant='outlined'
                                        size='small'
                                        label={(<Typography className={classes.prodPriceTargetLabel}>{lm.get(lang, Labels.C_TARGET_PRICE)}</Typography>)}
                                        InputProps={{
                                            inputComponent: NumberFormatInput as any,
                                        }}

                                    >
                                    </TextField>
                                ) : targetPrice > 0 ? (
                                    <Typography className={classes.prodPriceTargetText}>{'$' + targetPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                ) : (
                                    <Button
                                        className={classes.prodSetPriceTargetBtn}
                                        onClick={() => { setEditingTargetPrice(true); }}
                                        size='small'
                                        variant='outlined'
                                        color='primary'
                                    >
                                        {lm.get(lang, Labels.C_SET_TARGET_PRICE)}
                                    </Button>
                                )}
                                {editingTargetPrice ? (
                                    <ButtonBase
                                        className={classes.prodPriceTargetBtn}
                                        onClick={() => {
                                            setEditingTargetPrice(false);
                                            setTrackPriceHandler(props.prodInfo);
                                        }}
                                    >
                                        <Check className={classes.prodPriceTargetBtnIcon} fontSize="default" />
                                    </ButtonBase>
                                ) : targetPrice > 0 ? (
                                    <IconButton className={classes.prodPriceTargetIconBtn} onClick={() => { setEditingTargetPrice(true); }}><Edit fontSize='small' /></IconButton>
                                ) : null}
                            </Box>
                        ) : tracking && trackingLoad ? (
                            <LinearProgress></LinearProgress>
                        ) : null}
                        <Box>
                            <Typography className={classes.prodAv}>{props.prodInfo.availability}</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.prodPrime}>{lm.get(lang, Labels.C_PRIME)}</Typography>
                            {props.prodInfo.prime ? (
                                <CheckBox className={classes.prodPrimeCheck} color='secondary' />
                            ) : (
                                <CheckBoxOutlineBlank className={classes.prodPrimeCheck} color='secondary' />
                            )}
                        </Box>
                        {tracking && (
                            <Box className={classes.prodTrackDate}>
                                {lm.get(lang, Labels.C_TRACKING_SINCE)} {moment(trackDate).format('DD/MMM/YYYY')}
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.prodButtonsBox}>
                        {tracking ?
                            (<Button className={classes.prodButton} variant='contained' color='primary' fullWidth endIcon={<Remove />} onClick={untrackHandler(props.prodInfo)}>{lm.get(lang, Labels.C_UNTRACK)}</Button>) :
                            (<Button className={classes.prodButton} variant='contained' color='primary' fullWidth endIcon={<Add />} onClick={trackHandler(props.prodInfo)}>{lm.get(lang, Labels.C_TRACK)}</Button>)
                        }

                        <Button className={classes.prodButton} variant='contained' color='primary' fullWidth endIcon={<Notes />} onClick={() => { setDetailsOpen(true); }}>{lm.get(lang, Labels.C_DETAILS)}</Button>
                    </Box>
                    <Box className={classes.prodButtonsBox}>
                        <Button className={classes.prodButton} variant='contained' color='secondary' fullWidth
                            endIcon={!buyLoading && <OpenInNew />}
                            disabled={buyLoading}
                            onClick={() => {
                                BuyProduct.click(buyLoading, setBuyLoading, props.prodInfo, auth.token, lang);
                            }}
                        >
                            {buyLoading ? <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} /> : lm.get(lang, Labels.C_BUY_ON_AMZ)}
                        </Button>
                    </Box>
                </Box>
            </Box>
            {getFlagName(props.prodInfo.marketplaceKey) && <img className={classes.footerFlag} src={'/svg/' + getFlagName(props.prodInfo.marketplaceKey) + '.svg'} />}

            {priceHistoryOpen &&
                <ProductPriceHistoryDialog
                    open={priceHistoryOpen}
                    setPriceHistoryOpen={setPriceHistoryOpen}
                    prodInfo={props.prodInfo}
                    key={props.prodInfo.asin + '_pph'}
                />
            }
            {detailsOpen &&
                <ProductDetailsDialog
                    open={detailsOpen}
                    prodInfo={props.prodInfo}
                    setDetailsOpen={setDetailsOpen}
                    setPriceHistoryOpen={setPriceHistoryOpen}
                    tracking={tracking}
                    key={props.prodInfo.asin + '_pdd'}
                    trackHandler={trackHandler}
                    untrackHandler={untrackHandler}
                    trackDate={trackDate}
                    editingTargetPrice={editingTargetPrice}
                    setEditingTargetPrice={setEditingTargetPrice}
                    setTargetPrice={setTargetPrice}
                    setTrackPriceHandler={setTrackPriceHandler}
                    targetPrice={targetPrice}
                    trackingLoad={trackingLoad}
                />
            }
        </Box>
    )
}
export default ProductCard

