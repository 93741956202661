import { Box, Container, createStyles, makeStyles, Theme, Typography, Link } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import APIRequests from '../components/APIRequests';
import Constants from '../components/Constants';
import { authContext } from '../contexts/AuthContext';
import { langContext } from '../contexts/LangContext';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import PurchaseInfo from '../dto/PurchaseInfo';
import BounceLoader from "react-spinners/BounceLoader";
import theme from '../CTheme';
import lm from '../components/LabelManager';
import { Labels } from '../components/enums/Labels';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,            
            border: '3px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '1em',
            // justifyContent:'space-around'
        },
        headerBox: {
            marginTop: '0em'
        },
        header: {
            // color: theme.palette.background.paper,
            textAlign: 'center'
        },
        counterBox: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center'
        },
        counter: {
            // color: theme.palette.background.paper,
            textAlign: 'center'
        },
        disclaimerBox: {
            marginTop: '3em',            
        },
        disclaimer: {
            // color: theme.palette.background.paper,
            textAlign: 'justify',
            textJustify: 'inter-word'
        },
        imgBox: {
            textAlign: 'center',
        },
        image: {
            maxWidth: '10em',
            maxHeight: '10em',
        },
        linkBox: {
            marginTop: '1em',
            textAlign: 'center'
        },
        link: {
            border:'solid 1px',
            borderColor:theme.palette.primary.main,
            borderRadius:'.5em',
            padding:'.5em',
        },
        alienBox:{
            // position:'absolute',
            // top:'7em',
            marginTop:'1em',
            marginLeft:'auto',
            marginRight:'auto',
        },
        alien:{
            maxWidth:'7em',
            maxHeight:'7em'
        },
    }),
);

function AmzDisclaimerRedirect() {
    const history = useHistory();
    const classes = useStyles();
    const { auth } = useContext(authContext);
    const { lang } = useContext(langContext);
    const [counter, setCounter] = useState<number>(5);
    const search = useLocation().search;
    const piid = new URLSearchParams(search).get('piid');
    const [prl, setPrl] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [irl, setIrl] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [alien, setAlien] = useState<number>(Math.round(Math.random() * 3));
    // const pUrl = new URLSearchParams(search).get('prl');
    // const pName = new URLSearchParams(search).get('name');
    // const pImgUrl = new URLSearchParams(search).get('irl');

    useEffect(() => {
        if (piid) {
            let req = new ARequest<string>(auth.token);
            req.data = piid;
            req.lang = lang;
            APIRequests.post(Constants.BASE_URL + "api/tracker/products/getBuyInfo", req, function (data) {
                const r: AResponse<PurchaseInfo> = data;
                if (r.isError) {
                } else {
                    setPrl(r.data.prl);
                    setIrl(r.data.irl);
                    setName(r.data.title);
                }

                setLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            if (counter > 0) {
                setTimeout(() => { setCounter((c) => c - 1); }, 1000);
            }

            if (counter === 1 && prl) {
                window.open(decodeURIComponent(prl), '_self');
            }
        }
    }, [counter, setCounter, loading]);

    const getAlien = () => {        
        switch(alien){
            case 0: return 'curious3-150px';
            case 1: return 'excited2-150px';
            case 2: return 'silly5-150px';
            case 3: return 'beatsme3-150px';
            default: return 'happy5-150px';
        }
    };

    return (
        <Container className={classes.root}>
            <Box className={classes.alienBox}>
                <img className={classes.alien} src={'/img/aliens/150/'+ getAlien() + '.png'}></img>
            </Box>
            <Box className={classes.headerBox}>
                <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.AZD_TAKING_YOU_TO_AMAZON)}</Typography>
            </Box>
            <Box className={classes.counterBox}>
                {
                    loading ?
                        <BounceLoader color={theme.palette.primary.main}></BounceLoader> :
                        <Typography className={classes.counter} variant='h4'>{counter}</Typography>
                }
            </Box>
            <Box className={classes.imgBox}>
                {irl != null && <img className={classes.image} src={decodeURIComponent(irl)}></img>}
            </Box>
            {!loading &&
                <Box className={classes.linkBox}>
                    {prl != null && <Link className={classes.link} href={decodeURIComponent(prl)} rel="noopener" target='_self'>{lm.get(lang, Labels.AZD_CLICK_TO_GO_NOW)}</Link>}
                </Box>
            }
            <Box className={classes.disclaimerBox}>
                <Typography className={classes.disclaimer} variant='body2'>Alien Price Tracker is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. Your purchases via our Amazon affiliate links support this website at no additional cost to you.</Typography>
            </Box>
        </Container>
    )
}

export default AmzDisclaimerRedirect
