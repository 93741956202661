import { AppBar, Box, Button, ButtonBase, createStyles, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Snackbar, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import React from 'react'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { SportsRugbySharp } from '@material-ui/icons';

interface BroSnackProps {
    open: boolean,
    autoHideDuration: number,
    snackType: 'info' | 'success' | 'warning' | 'error',
    message: string | undefined,
    onSnackClose: () => void,
    showButton?: boolean,
    buttonText?: string,
    buttonAction?: () => void
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warn: {
            backgroundColor: theme.palette.warning.main,
            borderColor: theme.palette.warning.dark,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '.5em',
            padding: '.5em',
            color: theme.palette.common.white,
            display: 'flex'
        },
        error: {
            backgroundColor: theme.palette.error.main,
            borderColor: theme.palette.error.dark,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '.5em',
            padding: '.5em',
            color: theme.palette.common.white,
            display: 'flex'
        },
        success: {
            backgroundColor: theme.palette.success.main,
            borderColor: theme.palette.success.dark,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '.5em',
            padding: '.5em',
            color: theme.palette.common.white,
            display: 'flex'
        },
        info: {
            backgroundColor: theme.palette.info.main,
            borderColor: theme.palette.info.dark,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '.5em',
            padding: '.5em',
            color: theme.palette.common.white,
            display: 'flex',
        },
        icon: {
            color: theme.palette.common.white,
            marginLeft: '.25em',
            marginRight: '.5em'
        },
        contentBox:{
            display:'flex',
            flexDirection:'column',
        },        
        button: {
            border: 'solid 1px',
            borderColor: theme.palette.common.white,
            borderRadius: '.5em',
            padding: '.25em 1em',
            marginTop:'1em',
            backgroundColor:'rgba(255,255,255,0.1)',
            marginLeft:'auto',
            marginRight:'auto'
        },
        buttonText: {
            fontSize: '1em',            
        }
    }),
);

function BroSnack(props: BroSnackProps) {
    const classes = useStyles();
    return (
        <Snackbar open={props.open} autoHideDuration={props.autoHideDuration} onClose={props.onSnackClose}>
            <Box className={props.snackType === 'info' ? classes.info : props.snackType === 'success' ? classes.success : props.snackType === 'warning' ? classes.warn : classes.error}>
                {props.snackType === 'info' && <InfoIcon className={classes.icon}></InfoIcon>}
                {props.snackType === 'success' && <ThumbUpIcon className={classes.icon}></ThumbUpIcon>}
                {props.snackType === 'warning' && <WarningIcon className={classes.icon}></WarningIcon>}
                {props.snackType === 'error' && <ErrorIcon className={classes.icon}></ErrorIcon>}
                <Box className={classes.contentBox}>
                    <Typography variant="body2">{props.message}</Typography>
                    {props.showButton &&
                        <ButtonBase
                            className={classes.button}
                            onClick={props.buttonAction}
                        >
                            <Typography className={classes.buttonText}>{props.buttonText}</Typography>
                        </ButtonBase>
                    }
                </Box>
            </Box>
        </Snackbar>
    );
}

export default BroSnack
