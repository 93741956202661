import { AppBar, Box, Button, colors, createStyles, Divider, IconButton, makeStyles, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { setegid } from 'process';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import APIRequests from '../components/APIRequests';
import BroSnack from '../components/BroSnack';
import Constants from '../components/Constants';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import { authContext } from '../contexts/AuthContext';
import { langContext } from '../contexts/LangContext';
import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import HelpEmail from '../dto/request/HelpEmail';
import ScaleLoader from "react-spinners/ScaleLoader";
import theme from '../CTheme';
import BellIconTutorial from '../components/BellIconTutorial';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import GenericHelpDialog from '../components/GenericHelpDialog';
import { HelpSections } from '../components/enums/HelpSections';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootBox: {
            padding: '1em'
        },
        sendBtn: {
            marginTop: '1em'
        },
        divider:{
            margin:'1em',
            backgroundColor:'transparent'
        },
        bellIcon:{
            color: theme.palette.extra2.main,
            position:'relative',
            top: '.1em'
        }
    }),
);


const Help = () => {
    const classes = useStyles();
    const { lang } = useContext(langContext);
    const history = useHistory();
    const [msg, setMsg] = useState<string>("");
    const [sending, setSending] = useState<boolean>(false);
    const { auth } = useContext(authContext);
    const [sbMsg, setSbMsg] = useState<string | undefined>("");
    const [sbOpen, setSbOpen] = useState<boolean>(false);
    const [sbType, setSbType] = useState<'success' | 'warning'>('success');
    const [bellTutorialOpen, setBellTutorialOpen] = React.useState<boolean>(false);
    const [helpOpen, setHelpOpen] = useState<boolean>(false);
    const [helpSection, setHelpSection] = useState<HelpSections>(HelpSections.EMPTY);
    
    const sendEmail = () => {
        if (sending) return;
        setSending(true);
        const req = new ARequest<HelpEmail>(auth.token);
        req.data = new HelpEmail();
        req.data.msg = msg;
        APIRequests.post(Constants.BASE_URL + 'api/user/help/send', req, function (data) {
            setSending(false);
            const r: AResponse<string> = data;
            if (r.isError) {
                if (r.intCode == 500) {
                    setSbType('warning');
                    setSbOpen(true);
                    setSbMsg(r.message);
                }
            } else {
                setSbType('success');
                setSbOpen(true);
                setSbMsg(lm.get(lang, Labels.HLP_SENT_SUCCESS));
            }
        });
    };

    const helpClickHandler = (section:HelpSections) => {
        setHelpSection(section);
        setHelpOpen(true);
    };

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar variant='dense'>
                    <IconButton edge="start" color="inherit" onClick={() => { history.push("/home") }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif" }} variant="h6">
                        {lm.get(lang, Labels.HLP_TITLE)}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box className={classes.rootBox}>
                <Typography variant="h6">{lm.get(lang, Labels.HLP_BELL_BUTTON_TITLE)} <NotificationImportantIcon className={classes.bellIcon}></NotificationImportantIcon></Typography>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { setBellTutorialOpen(true) }}
                >
                    {lm.get(lang, Labels.HLP_BELL_BUTTON_BTN_TEXT)}
                </Button>
                <BellIconTutorial
                    open={bellTutorialOpen}
                    setOpen={setBellTutorialOpen}
                    showDontShowAgain={false}
                ></BellIconTutorial>

                <Divider className={classes.divider}></Divider>

                <Typography variant="h6">{lm.get(lang, Labels.HLP_SEARCH_TITLE)}</Typography>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SEARCH_STORE) }}
                >
                    {lm.get(lang, Labels.HLP_SEARCH_STORE)}
                </Button>

                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SEARCH_CATEGORY) }}
                >
                    {lm.get(lang, Labels.HLP_SEARCH_CATEGORY)}
                </Button>

                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SEARCH_FILTERS) }}
                >
                    {lm.get(lang, Labels.HLP_SEARCH_FILTERS)}
                </Button>

                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SEARCH_SEARCH) }}
                >
                    {lm.get(lang, Labels.HLP_SEARCH_SEARCH)}
                </Button>

                <Divider className={classes.divider}></Divider>
                
                <Typography variant="h6">{lm.get(lang, Labels.HLP_SETTS_TITLE)}</Typography>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SETTS_LANGUAGE) }}
                >
                    {lm.get(lang, Labels.HLP_SETTS_LANG)}
                </Button>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SETTS_NOTIF_TYPES) }}
                >
                    {lm.get(lang, Labels.HLP_SETTS_NOTIF_TYPES)}
                </Button>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SETTS_EMAIL_NOTIFS) }}
                >
                    {lm.get(lang, Labels.HLP_SETTS_EMAIL_NOTIFS)}
                </Button>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SETTS_EMAIL_FREQ) }}
                >
                    {lm.get(lang, Labels.HLP_SETTS_EMAIL_FREQ)}
                </Button>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SETTS_WHEN_EMAIL)}}
                >
                    {lm.get(lang, Labels.HLP_SETTS_EMAIL_WHEN)}
                </Button>
                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { helpClickHandler(HelpSections.SETTS_OTHER_OPTS) }}
                >
                    {lm.get(lang, Labels.HLP_SETTS_OTHER)}
                </Button>

                <Divider className={classes.divider}></Divider>

                <Typography variant="h6">{lm.get(lang, Labels.HLP_CONTACT_US)}</Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="msg"
                    label={lm.get(lang, Labels.HLP_MSG)}
                    name="msg"
                    autoComplete="msg"
                    autoFocus
                    multiline
                    rows={12}
                    rowsMax={12}
                    onChange={e => {
                        setMsg(e.target.value);
                    }}
                />

                <Button
                    className={classes.sendBtn}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => { sendEmail() }}
                    disabled={sending}
                >
                    {(!sending ? lm.get(lang, Labels.HLP_SEND) : <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} />)}
                </Button>
            </Box>
            <GenericHelpDialog open={helpOpen} setOpen={setHelpOpen} section={helpSection}></GenericHelpDialog>
            <BroSnack
                autoHideDuration={10000}
                open={sbOpen}
                message={sbMsg}
                snackType={sbType}
                onSnackClose={() => { setSbOpen(false) }}
            />
        </div>
    )
}

export default Help
