import { createMuiTheme } from '@material-ui/core/styles';

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    extra1: Palette['primary'];
    extra2: Palette['primary'];
  }
  interface PaletteOptions {
    extra1: PaletteOptions['primary'];
    extra2: PaletteOptions['primary'];
  }
}

const theme = createMuiTheme({  
  palette: {
    
    primary: {
      main: '#26A250',
      // main:'#2c6aa0'
    },
    secondary: {
      main: '#F2Ac34',
    },
    extra1:{
      dark:'#10283c',
      main: '#1E486C',
      light:'#2c6aa0'
    },
    extra2:{
      dark:'#e44d01',
      main:' #fe7634',
      light:'#feaa80'
    }
  },
});

export default theme;