export enum Labels{
    EMPTY,

    APP_NEW_VERSION_AVAILABLE,
    APP_GET_NEW_VERSION,

    HOME_FIRST_LOAD1,
    HOME_FIRST_LOAD2,
    HOME_FIRST_LOAD3,
    HOME_FIRST_LOAD4,
    HOME_NO_NOTIFS_NO_TRACK,
    HOME_NO_NOTIFS_TRACK,
    HOME_NO_MORE_NOTIFS,
    HOME_TRACK_MORE_PRODS,
    HOME_CHECK_FOR_NEW_NOTIFS,
    HOME_SELECT_YOUR_AMZ_STORES,
    HOME_SET_NOTIF_SETTS,
    HOME_START_TRK_PRODS,    

    MYPR_STORE,
    MYPR_CATEGORY,
    MYPR_ALL_ST,
    MYPR_FIRST_LOAD,
    MYPR_NO_TRACKED,
    MYPR_NO_TRACKED_BTN,
    MYPR_NO_STORE_SELECTED,
    MYPR_NO_STORE_SELECTED_BTN,
    MYPR_MY_PRODUCTS,
    MYPR_SEARCH,
    MYPR_SEARCHBOX_PLACEHOLDER,
    MYPR_NEXT_P,
    MYPR_PREV_P,
    MYPR_PRIME,
    MYPR_FREE_SHIPPING,
    MYPR_FILTERS,
    MYPR_MORE_OPTIONS,
    MYPR_SORTBY,
    MYPR_SORTBY_RELEVANCE,
    MYPR_SORTBY_PRICE_HTL,
    MYPR_SORTBY_PRICE_LTH,
    MYPR_SORTBY_NEWEST,
    MYPR_SORTBY_FEATURED,

    MYST_MY_STORES,

    NSETTS_NOTIF_SETTINGS,
    NSETTS_NOTIF_TYPES,
    NSETTS_NOTIF_EVENTS,
    NSETTS_EMAIL_NOTIFS,
    NSETTS_SMS_NOTIFS,
    NSETTS_PRICE_DROP_EV,
    NSETTS_TARGET_REACHED_EV,
    NSETTS_NOTIF_FREQUENCY,
    NSETTS_EVERY_TIME_PRICE_CHANGES,
    NSETTS_ONCE_A_DAY,
    NSETTS_WHEN_TO_GET_EMAIL,
    NSETTS_TIME_ZONE,
    NSETTS_TIME_OF_DAY,
    NSETTS_OTHER_OPTS,
    NSETTS_CLEAR_IMMEDIATE_NOTIFS,
    NSETTS_SUCCESS_CLEARED_IMM_NOTIFS,
    NSETTS_ERROR_CLEARED_IMM_NOTIFS,

    LOGIN_EMAIL,
    LOGIN_PASSWORD,
    LOGIN_REMEMBER,
    LOGIN_SIGNIN,
    LOGIN_SIGNING_IN,
    LOGIN_FORGOT_PASS,
    LOGIN_SIGNUP,
    LOGIN_OR,

    SIGNUP_EMAIL,
    SIGNUP_PASSWORD,
    SIGNUP_CPASSWORD, 
    SIGNUP_SIGNIN,    
    SIGNUP_SIGNUP,

    MENU_SEARCH,
    MENU_MY_PRODUCTS,
    MENU_MY_STORES,
    MENU_NOTIF_SETTS,
    MENU_LOGOUT,
    MENU_MYACCOUNT,
    MENU_ABOUT,
    MENU_HELP,
    MENU_PRIVACY,
    MENU_TERMS,

    NC_PRICE_DROP, //NOTIFICACTION CARD
    NC_NEW_PRICE,
    NC_TARGET_REACHED,
    NC_YOUR_TARGET_PRICE,
    NC_NO_TARGET_SET,
    
    C_PRIME, //CARD / PRODUCT DETAILS
    C_BUY_ON_AMZ,
    C_BUY_ON_AMZ_SH,
    C_VIEW_DETAILS,
    C_CURRENT_PRICE,
    C_PRICE_WHEN_START_FOLLOW,
    C_TARGET_PRICE,
    C_SET_TARGET_PRICE,
    C_TRACKING_SINCE,
    C_TRACK,
    C_UNTRACK,
    C_DETAILS,
    C_PRICE_HISTORY,
    C_ABOUT_ITEM,
    C_YOUR_TARGET_PRICE,
    C_NO_TARGET_SET,

    PH_DATE, //PRICE HISTORY
    PH_PRICE,
    PH_PRICE_HISTORY,
    PH_MORE,
    PH_NO_HISTORY_YET,
    PH_LOW_30D,
    PH_HIGH_30D,
    PH_LOW_YEAR,
    PH_HIGH_YEAR,

    RP_RESET_PASSWORD, //RESET PASWORD
    RP_EMAIL,
    RP_RESET_BTN,
    RP_RESETTING_PASS,
    RP_PASS_HAS_BEEN_RESET,
    RP_PROBLEM_RESETTING,

    NOTICE_RESET_PASSWORD,
    NOTICE_VERIFY,
    NOTICE_OR,
    NOTICE_GO_BACK,

    VERIFY_VERIFYING,
    VERIFY_VERIFIED,
    VERIFY_PROBLEM,

    MACC_MY_ACCOUNT,
    MACC_EMAIL,
    MACC_CHANGE_PASS,
    MACC_CURRENT_PASS,
    MACC_NEW_PASS,
    MACC_NEW_PASS_CONFIRM,
    MACC_SUBMIT_PASS,    
    MACC_PASS_UPDATED,
    MACC_CURR_PASS_INCORRECT,
    MACC_NEW_PASS_DONT_MATCH,
    MACC_PROBLEM_UPDATING_PASS,
    MACC_LANGUAGE,
    MACC_SELECT_LANGUAGE,
    MACC_ENGLISH,
    MACC_SPANISH,
    MACC_DELETE_ACCT,
    MACC_CLICK_TO_DELETE_ACCT,
    MACC_ACCOUNT_DELETE_WARNING,
    MACC_ACCOUNT_DELETED,

    OM_ONE_MOMENT_PLEASE,

    AZD_TAKING_YOU_TO_AMAZON,
    AZD_CLICK_TO_GO_NOW,

    ABT_TITLE,

    PP_TITLE,

    TOS_TITLE,

    DEL_TITLE,

    HLP_TITLE,
    HLP_CONTACT_US,
    HLP_EMAIL,
    HLP_MSG,
    HLP_SEND,
    HLP_SENT_SUCCESS,
    HLP_BELL_BUTTON_TITLE,
    HLP_BELL_BUTTON_BTN_TEXT,
    HLP_SEARCH_TITLE,
    HLP_SEARCH_STORE,
    HLP_SEARCH_CATEGORY,
    HLP_SEARCH_FILTERS,
    HLP_SEARCH_SEARCH,
    HLP_SETTS_TITLE,
    HLP_SETTS_LANG,
    HLP_SETTS_NOTIF_TYPES,
    HLP_SETTS_EMAIL_NOTIFS,
    HLP_SETTS_EMAIL_FREQ,
    HLP_SETTS_EMAIL_WHEN,
    HLP_SETTS_OTHER,

    LAND_SUBTITLE_1,
    LAND_CREATE_ACCT,
    LAND_SIGN_IN,
    LAND_FAQ,

    FAQ_SUBTITLE,
    FAQ_BACK,

    COOKIE_CONSENT_WARN_TEXT,
    COOKIE_CONSENT_INFO_TEXT,
    COOKIE_CONSENT_BUTTON
}