import { AppBar, Box, colors, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import { langContext } from '../contexts/LangContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootBox: {
            padding: '1em'
        },
        discBox: {
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            marginBottom: '1em'
        },
        h6: {
            marginTop: '1em'
        },
        img:{
            maxWidth:'90%',
            maxHeight:'800px',
            border:'1px solid',
            borderRadius:'.5em',
            borderColor:theme.palette.primary.light,
            marginBottom:'1em'
        }
    }),
);


const DeleteInstructions = () => {
    const classes = useStyles();
    const { lang } = useContext(langContext);
    const history = useHistory();

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar variant='dense'>
                    <IconButton edge="start" color="inherit" onClick={() => { history.push("/") }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif" }} variant="h6">
                        {lm.get(lang, Labels.DEL_TITLE)}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box className={classes.rootBox}>
                <Box className={classes.discBox}>
                    <img className={classes.img} src="/img/htd/HTD_01.jpg"></img>
                    <img className={classes.img} src="/img/htd/HTD_02.jpg"></img>
                    <img className={classes.img} src="/img/htd/HTD_03.jpg"></img>
                    <img className={classes.img} src="/img/htd/HTD_04.jpg"></img>
                    <img className={classes.img} src="/img/htd/HTD_05.jpg"></img>
                </Box>
            </Box>
        </div>
    )
}

export default DeleteInstructions
