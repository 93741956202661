import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';
import EncProvider from '../contexts/EncContext';
import Constants from './Constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(authContext);
  const { loading } = auth;

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p>Loading...</p>;
        }}
      />
    );
  }
  
  if (auth.token == undefined || auth.token.trim() == "") {
    Constants.clearCaches();
    return (<Redirect to="/" />);
  }

  return (
    <EncProvider>
      <Route
        {...rest}
        render={routeProps => {
          return (<Component {...routeProps} />);
        }}
      />
    </EncProvider>
  );
};

export default PrivateRoute;