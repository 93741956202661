import MyProds from "../dto/cache/MyProds";
import Constants from "./Constants";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
import MarketplaceTrackCount from "../dto/response/MarketplaceTrackCount";
import ProductInfo from "../dto/response/ProductInfo";
import HomeNotifs from "../dto/cache/HomeNotifs";
import NotificationInfo from "../dto/response/NotificationInfo";

export default class CacheManager{

    static notifsCacheValidityInMinutes = 10;
    static myProdsCacheValidityInMinutes = 20;

    static async initDB():Promise<IDBDatabase>{
        let dbp = new Promise<IDBDatabase>((resolve, reject) => {
            if (window.indexedDB) {            
                let dbOpenReq: IDBOpenDBRequest = indexedDB.open(Constants.DB_NAME);
                dbOpenReq.onupgradeneeded = (event) => {
                    if (event.target != null) {
                        let t = event.target as IDBOpenDBRequest;
                        let db = t.result;
                        let cacheStore = db.createObjectStore("caches");
                    }
                };
    
                dbOpenReq.onsuccess = (event) => {
                    if (event.target != null) {
                        let t = event.target as IDBOpenDBRequest;
                        let db = t.result;
                        resolve(db);
                    }
                };       
                
                dbOpenReq.onerror = () => {
                    reject();
                };
            } else {
                reject();
            }
        });

        let db = await dbp;

        return db;
    }

    static async getMyProdsCache(idb:IDBDatabase|undefined, getKey:Promise<string>):Promise<MyProds | undefined>{
        if (idb) {
            let getReq = idb!.transaction(["caches"], "readwrite").objectStore("caches").get(Constants.MY_PRODS_CACHE);
            let done: Promise<MyProds | undefined> = new Promise<MyProds | undefined>(
                (resolve, reject) => {
                    getReq.onsuccess = (event) => {
                        if (event.target != null) {
                            getKey.then(jsk => {
                                try {
                                    let t = event.target as IDBRequest<string>;
                                    let enc = t.result;

                                    let bytes = AES.decrypt(enc, jsk);
                                    let jsonS = bytes.toString(CryptoJS.enc.Utf8);
                                    if (jsonS != null) {
                                        let mp = JSON.parse(jsonS) as MyProds;

                                        if (((new Date()).getTime() - mp.lastUpdate) / 1000 / 60 < CacheManager.myProdsCacheValidityInMinutes) {
                                            resolve(mp);
                                        } else {
                                            CacheManager.deleteNotifCache(idb).then(() => {
                                                reject();
                                            });
                                        }
                                    }
                                } catch { reject(); }
                            }).catch(() => { reject(); });
                        } else { reject(); }
                    };

                    getReq.onerror = () => { reject(); }
                });

            let res = await done;
            return res;
        }
    }

    static async deleteMyProdsCache(idb:IDBDatabase|undefined){
        let done: Promise<void> = new Promise((resolve, reject) => {
            if (idb) {
                let req = idb!.transaction(["caches"], "readwrite")
                    .objectStore("caches").delete(Constants.MY_PRODS_CACHE);

                req.onsuccess = () => { resolve() };
                req.onerror = () => { reject() };
            } else {
                resolve();
            }
        });

        await done;
        return;
    };

    static async updateMyProdsCache (
            idb:IDBDatabase|undefined,
            getKey:Promise<string>, 
            loadingProducts:boolean,
            currentMyProds: MyProds | undefined, 
            trackedProducts: ProductInfo[], 
            selectedMP_MyPr: string, 
            trackedProdsPage: number, 
            mpTrackCount: MarketplaceTrackCount[]
        ) {

        if (idb && !loadingProducts) {
            let mp = new MyProds();
            mp.trackedProds = trackedProducts;
            mp.selectedMP = selectedMP_MyPr;
            mp.trackedProdsPage = trackedProdsPage;
            mp.mpTrackCount = mpTrackCount;
            mp.lastUpdate = currentMyProds ? currentMyProds.lastUpdate : (new Date()).getTime();

            getKey.then(jsk => {
                let enc = AES.encrypt(JSON.stringify(mp), jsk).toString();

                if (currentMyProds) {
                    idb!.transaction(["caches"], "readwrite")
                        .objectStore("caches").put(enc, Constants.MY_PRODS_CACHE);
                } else {
                    idb!.transaction(["caches"], "readwrite")
                        .objectStore("caches").add(enc, Constants.MY_PRODS_CACHE);
                }
            }).catch(() => { });
        }
    };

    static async updateProdInfoCacheInMyProds(
        idb:IDBDatabase|undefined, 
        getKey:Promise<string>, 
        loadingProducts:boolean, 
        pi: ProductInfo
        ) {
        CacheManager.getMyProdsCache(idb, getKey).then(myProds => {
            if (myProds) {
                myProds.trackedProds.filter(tp => tp.asin === pi.asin && tp.marketplaceKey === pi.marketplaceKey)
                    .forEach(tp => {
                        tp.alwaysNotify = pi.alwaysNotify;
                        if (tp.trackInfo && pi.trackInfo) {
                            tp.trackInfo.targetPrice = pi.trackInfo.targetPrice;
                        }
                    });

                CacheManager.updateMyProdsCache(idb, getKey, loadingProducts, myProds, myProds.trackedProds, myProds.selectedMP, myProds.trackedProdsPage, myProds.mpTrackCount);                
            }
        });        
    }


    static async getNotifCache(idb:IDBDatabase|undefined, getKey:Promise<string>): Promise<HomeNotifs | undefined>{
        if (idb) {
            let getReq = idb!.transaction(["caches"], "readwrite").objectStore("caches").get(Constants.HOME_NOTIFS_CACHE);
            let done: Promise<HomeNotifs | undefined> = new Promise<HomeNotifs | undefined>(
                (resolve, reject) => {
                    getReq.onsuccess = (event) => {
                        if (event.target != null) {
                            getKey.then(jsk => {
                                try {
                                    let t = event.target as IDBRequest<string>;
                                    let enc = t.result;
                                    let bytes = AES.decrypt(enc, jsk);
                                    let jsonS = bytes.toString(CryptoJS.enc.Utf8);
                                    if (jsonS != null) {
                                        let homeNotifs = JSON.parse(jsonS) as HomeNotifs;
                                        if (((new Date()).getTime() - homeNotifs.lastUpdate) / 1000 / 60 < CacheManager.notifsCacheValidityInMinutes) {
                                            resolve(homeNotifs);
                                        } else {
                                            CacheManager.deleteNotifCache(idb).then(() => {
                                                reject();
                                            });
                                        }
                                    }
                                } catch (x) { reject(x); }
                            }).catch((x) => { reject(x); });
                        } else { reject("EVENT TARGET NULL"); }
                    };

                    getReq.onerror = () => { reject("IDB ERROR"); }
                });

            let res = await done;
            return res;
        }
    };

    static async deleteNotifCache(idb:IDBDatabase|undefined){
        let done: Promise<void> = new Promise((resolve, reject) => {
            if (idb) {
                let req = idb!.transaction(["caches"], "readwrite")
                    .objectStore("caches").delete(Constants.HOME_NOTIFS_CACHE);

                req.onsuccess = () => { resolve() };
                req.onerror = () => { reject() };
            } else {
                resolve();
            }
        });

        await done;
        return;
    };

    static updateNotifCache(
        idb:IDBDatabase|undefined,
        getKey:Promise<string>,
        currentHN: HomeNotifs | undefined,
        notifs: NotificationInfo[],
        notifsPage: number,
        trackingAnything: boolean
        ) {
        if (idb) {
            let lastUpdate = (new Date()).getTime();
            if (currentHN) {
                lastUpdate = currentHN.lastUpdate;
            }

            let hn = new HomeNotifs();
            hn.lastUpdate = lastUpdate;
            hn.notifications = notifs;
            hn.notifsPage = notifsPage;
            hn.trackingAnything = trackingAnything;
            getKey.then(jsk => {
                let enc2 = AES.encrypt(JSON.stringify(hn), jsk).toString();

                if (currentHN) {
                    idb!.transaction(["caches"], "readwrite")
                        .objectStore("caches").put(enc2, Constants.HOME_NOTIFS_CACHE);
                } else {
                    idb!.transaction(["caches"], "readwrite")
                        .objectStore("caches").add(enc2, Constants.HOME_NOTIFS_CACHE);
                }
            }).catch(() => { });
        }
    };

    static async deleteNotifFromCache(
        idb:IDBDatabase|undefined,
        getKey:Promise<string>,        
        notif: NotificationInfo
        ) {
        if (idb) {            
            let notifs = await this.getNotifCache(idb, getKey);
            
            if(notifs){                
                let newNotifs = notifs.notifications.filter(x => x.id != notif.id);                
                this.updateNotifCache(idb, getKey, notifs, newNotifs, notifs.notifsPage, notifs.trackingAnything);
            }
        }
    };

    static async updateProdInfoCacheInNotifs(
        idb:IDBDatabase|undefined, 
        getKey:Promise<string>,
        pi: ProductInfo
        ) {
        CacheManager.getNotifCache(idb, getKey).then(myNotifs => {
            if (myNotifs) {
                myNotifs.notifications.filter(n => n.productInfo.asin === pi.asin && n.productInfo.marketplaceKey === pi.marketplaceKey)
                    .forEach(n => {
                        n.productInfo.alwaysNotify = pi.alwaysNotify;         
                        n.productInfo.trackInfo = pi.trackInfo;               
                    });

                CacheManager.updateNotifCache(idb, getKey, myNotifs, myNotifs.notifications, myNotifs.notifsPage, myNotifs.trackingAnything);                
            }
        }).catch(x => {});
    };

    static async clearImmediateNotifsInCache(idb:IDBDatabase|undefined, getKey:Promise<string>){
        CacheManager.getNotifCache(idb, getKey).then(myNotifs => {
            if (myNotifs) {
                myNotifs.notifications.filter(n => n.productInfo.alwaysNotify === true)
                    .forEach(n => {
                        n.productInfo.alwaysNotify = false;
                    });

                CacheManager.updateNotifCache(idb, getKey, myNotifs, myNotifs.notifications, myNotifs.notifsPage, myNotifs.trackingAnything);                
            }
        }).catch((x) => {});

        CacheManager.getMyProdsCache(idb, getKey).then(myProds => {
            if (myProds) {
                myProds.trackedProds.filter(tp => tp.alwaysNotify === true)
                    .forEach(tp => {
                        tp.alwaysNotify = false;                        
                    });

                CacheManager.updateMyProdsCache(idb, getKey, false, myProds, myProds.trackedProds, myProds.selectedMP, myProds.trackedProdsPage, myProds.mpTrackCount);                
            }
        }).catch((x) => {}); 
    }

    // static setSearchPrefs(mpKey:string, prime:boolean, freeShipping:boolean, category:string){
    //     let obj = {
    //         mpKey
    //     };
    // }
}