import ARequest from '../dto/ARequest';
import AResponse from '../dto/AResponse';
import PurchaseInfo from '../dto/PurchaseInfo';
import ProductInfo from '../dto/response/ProductInfo';
import APIRequests from './APIRequests';
import Constants from './Constants';

export default class BuyProduct {
    public static go(prodInfo: ProductInfo, token: string, lang: number): Promise<string> {
        let p = new Promise<string>((resolve, reject) => {
            let pi = new PurchaseInfo();
            pi.asin = prodInfo.asin;
            pi.marketplaceKey = prodInfo.marketplaceKey;
            pi.prl = encodeURIComponent(prodInfo.url);
            pi.title = prodInfo.title;
            pi.irl = encodeURIComponent(ProductInfo.getLargestPrimary(prodInfo).url);

            let req = new ARequest<PurchaseInfo>(token);
            req.data = pi;
            req.lang = lang;
            APIRequests.post(Constants.BASE_URL + "api/tracker/products/buyClick", req, function (data) {
                const r: AResponse<string> = data;
                if (r.isError) {
                    reject();
                } else {
                    // window.open('/buy?piid='+r.data, '_blank');
                    resolve('/buy?piid=' + r.data);
                }
            },
                function () {
                    reject();
                });
        });

        return p;
    }

    public static click(loading: boolean, setLoading: (loading: boolean) => void, prodInfo: ProductInfo, token: string, lang: number) {
        if (loading) return;
        setLoading(true);
        console.log(navigator.userAgent);
        if (navigator.userAgent.toLowerCase().indexOf('android') == -1 && navigator.userAgent.toLowerCase().indexOf('safari') != -1) {
            let windowReference: Window | null = window.open('/one-moment', '_blank');
            BuyProduct.go(prodInfo, token, lang).then((url) => {
                setLoading(false);
                if (windowReference) {
                    windowReference.location.href = url;
                }
            });
        } else {
            BuyProduct.go(prodInfo, token, lang).then((url) => {
                setLoading(false);
                window.open(url, '_blank');
            });
        }

    }
}