import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import $ from "jquery";
import React, { createContext, ReactNode, useState } from 'react';
import Constants from '../components/Constants';
import { Languages } from '../components/enums/Languages';
import RingLoader from "react-spinners/RingLoader";
import theme from "../CTheme";

interface IProps {
    children: ReactNode;
}

interface ILangContext {
    lang: number;
    setLang: (lang: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10em',
        },
        title:{
            fontFamily: "'Comfortaa', sans-serif",
            fontSize:'1.8em'
        },
        spinnerBox: {
            display: 'flex',
            justifyContent: 'center',
            marginTop:'4em'
        },
    }),
);

export const langContext = createContext<ILangContext>({
    lang: Languages.EN_US,
    setLang: (lang: number) => { }

});

const LangProvider = ({ children }: IProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true);
    const [language, setLanguage] = useState<number>(Languages.EN_US);

    const gl: Promise<number> = new Promise<number>((resolve, reject) => {
        let lang: string | null = localStorage.getItem(Constants.LANGUAGE_CACHE);
        if (lang != null) {
            resolve(parseInt(lang, 10));
        } else {                        
            $.ajax({
                url: Constants.BASE_URL + 'api/user/guessLang',
                method: 'GET'
            }).done(function (data) {
                let l: number = data as number;                    
                localStorage.setItem(Constants.LANGUAGE_CACHE, l.toString());
                resolve(l);
            }).fail(() => { resolve(Languages.EN_US) });
        }
    });

    gl.then(lang => {
        setLanguage(lang);
        setLoading(false);
    });

    const setLang = (lang: number) => {
        localStorage.setItem(Constants.LANGUAGE_CACHE, lang.toString());
        setLanguage(lang);
    };

    let langVal: ILangContext = {
        lang: language,
        setLang: setLang
    }

    return (
        <langContext.Provider value={langVal}>
            {loading ?
                <Box className={classes.root}>
                    <Box>
                        <Typography className={classes.title}>Alien Price Tracker</Typography>
                        <Box className={classes.spinnerBox}>
                            <RingLoader color={theme.palette.primary.main}></RingLoader>
                        </Box>
                    </Box>
                </Box>
                : children}
        </langContext.Provider>
    );
}

export default LangProvider;