
import { Languages } from "../components/enums/Languages";

export default class AuthType{
    token:string;
    loading:boolean = true;    
    thirdParty:boolean = false;
    email:string;

    constructor(loading?:boolean){
        if(loading != undefined){
            this.loading = loading;
        }     
    }
}