import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    titleLogoBox: {
        position: 'relative',
        width: '100%',
        marginBottom: '0em',
        cursor:'pointer'
    },  
    imgBox: {
        maxWidth: '21%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },  
    imgBoxFW: {
        maxWidth: '30%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },    
    logoImg: {
        maxWidth: '100%'
    },
    titleImg: {
        maxWidth: '70%'
    },
    titleImgFW: {
        maxWidth: '100%'
    },
    titleBox: {
        textAlign: 'center',
        width: '100%',
        marginTop: '.5em'
    },
    title: {
        fontFamily: "'Comfortaa', sans-serif",
        fontSize: '1.8em',
    },
}));

interface TitleBoxProps {
    color: 'white' | 'black';
    className?: string;
    fullWidth?: boolean;
    logoSize?: 'small' | 'normal';
}

function TitleBox(props: TitleBoxProps) {
    const history = useHistory();
    const classes = useStyles();
    return (
        <div className={props.className}>
            <Box className={classes.titleLogoBox} onClick={() => {history.push("/");}}>
                <Box className={props.fullWidth === true ? classes.imgBoxFW : classes.imgBox}>
                    <img className={classes.logoImg} src={props.logoSize === 'small' ? '/logo192.png' : '/logo512.png'} />
                </Box>
                <Box className={classes.titleBox}>
                    {
                        props.color === 'white' ? 
                        <img className={props.fullWidth === true ? classes.titleImgFW : classes.titleImg} src='/img/TitleWhite.png' />
                        : 
                        <img className={props.fullWidth === true ? classes.titleImgFW : classes.titleImg} src='/img/TitleBlack.png' />
                    }
                </Box>
            </Box>
        </div>
    )
}

export default TitleBox