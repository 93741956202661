import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import $ from "jquery";
import React, { useContext, useState } from 'react';
import Constants from '../components/Constants';
import Copyright from '../components/Copyright';
import { authContext } from '../contexts/AuthContext';
import AResponse from '../dto/AResponse';
import lm from '../components/LabelManager';
import { Labels } from '../components/enums/Labels';
import { useHistory } from 'react-router-dom';
import { langContext } from '../contexts/LangContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TitleBox from '../components/TitleBox';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  recaptchaBox: {
    marginTop: "2em"
  },
  titleBox:{
    marginBottom:'2em'
  }
}));

export default function ResetPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { lang } = useContext(langContext);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();

  const onFormSubmit = async (e: any) => {
    if (submitted) return;
    e.preventDefault();

    setSubmitted(true);

    let token = $("meta[name='_csrf']").attr("content");
    let headerName = $("meta[name='_csrf_header']").attr("content");

    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader(headerName!, token!);
      }
    });

    let captchaRes: string = "x";
    try {
      captchaRes = await executeRecaptcha('resetPassword');
    } catch { };

    $.ajax({
      url: Constants.BASE_URL + 'api/user/resetPasswordRequest/' + captchaRes + '/' + email,
      method: 'POST',
      processData: false,
      contentType: 'application/json'
    }).done(function (data) {
      const r: AResponse<String> = data;
      if (r.isError) {
        alert(r.message);
      } else {
        history.replace('/password-reset-notice?n=prn');
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <TitleBox className={classes.titleBox} color='black'/>
        <Typography style={{fontFamily:"'Comfortaa', Roboto, Helvetica, Arial, sans-serif"}} component="h1" variant="h5">
          {lm.get(lang, Labels.RP_RESET_PASSWORD)}
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={lm.get(lang, Labels.RP_EMAIL)}
                name="email"
                autoComplete="email"
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onFormSubmit}
            disabled={submitted}
          >
            {lm.get(lang, Labels.RP_RESET_BTN)}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/sign-in" variant="body2">
                {lm.get(lang, Labels.SIGNUP_SIGNIN)}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box className={classes.recaptchaBox}>
        <Typography variant='body2' color='textSecondary'>
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </Typography>
      </Box>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}