import { Box, Button, Container, Link, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import TitleBox from '../components/TitleBox';
import { authContext } from '../contexts/AuthContext';
import { langContext } from '../contexts/LangContext';
import lm from '../components/LabelManager';
import { Labels } from '../components/enums/Labels';
import Constants from '../components/Constants';
import AResponse from '../dto/AResponse';
import BroSnack from '../components/BroSnack';
import AuthType from '../contexts/AuthType';
import AcctBasicDetails from '../dto/response/AcctBasicDetails';
import { GoogleLogin } from 'react-google-login';
import GoogleLogo from '../img/google/GoogleLogo.svg';
import $ from "jquery";
import RingLoader from "react-spinners/RingLoader";
import theme from '../CTheme';
import CookieConsent, { Cookies, getCookieConsentValue }  from 'react-cookie-consent';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '.5em 0em',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        height: '100%',
        position: 'relative'
    },
    spBpx: {
        marginTop: '1em',
        textAlign: 'center',
        fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif",
        fontSize: '1.25em',
        padding: '0 1em'
    },
    descBox: {
        marginTop: '1em',
        textAlign: 'justify',
        textJustify: 'inter-word',
        padding: '0 1em',
        fontSize: '1em'
    },
    acctButtons: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '450px',
        marginTop: '10%'
    },
    otherButtons: {
        display: 'flex',
        width: '100%',
        maxWidth: '450px',
        justifyContent: 'center',
        marginTop: '1em'
    },
    btnBoxSmall: {
        margin: '0 1em 2em 1em',
        width: '45%',
        maxWidth: '200px'
    },
    btnBoxLarge: {
        margin: '.25em 1em',
    },
    footerBox: {
        textAlign: 'center',
        fontSize: '.5em',
        width: '100%',
        position: 'absolute',
        bottom: '1.5em',

    },
    fbSignIn: {
        backgroundColor: 'white',
        maxHeight: '4em',
        marginBottom:'1em'
    },
    fbLogo: {
        height: '2em',
        marginRight: '1em'
    },
    googleSignIn: {
        backgroundColor: 'white',
        maxHeight: '4em'
    },
    googleLogo: {
        height: '2em',
        marginRight: '1em'
    },
    signingIn: {
        margin: '8em 0em'
    },
    whiteButton:{
        backgroundColor: theme.palette.common.white,    
    }
}));

function Landing() {
    const classes = useStyles();
    const { lang, setLang } = useContext(langContext);
    const history = useHistory();

    const [sbMsg, setSbMsg] = useState<string | undefined>("");
    const [sbOpen, setSbOpen] = useState<boolean>(false);
    const [fbReRequest, setFbReRequest] = useState<boolean>(false);
    const search = useLocation().search;
    const state = new URLSearchParams(search).get('state');
    const code = new URLSearchParams(search).get('code');
    const { setAuthData, auth } = useContext(authContext);
    const [googleCode, setGoogleCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (auth.email && auth.token && auth.email.trim().length > 0 && auth.token.trim().length > 0) {
            history.push('/home');
        }
    }, [auth, history]);

    useEffect(() => {
        if (state && code) {
            if (state.length > 0 && state.substr(0, 8) === "fbsignin") {
                signinClickHandler();
            }
        }
    }, [state, code]);

    const responseGoogle = (response: any) => {        
        setGoogleCode(response.code);
    }

    useEffect(() => {
        if (googleCode && googleCode.length > 0) {
            signinClickHandler();
        }
    }, [googleCode])

    const checkCookieConsent = () => {
        let val = getCookieConsentValue();
        console.log(val);
        if(!val || val == "false"){
            setSbMsg(lm.get(lang, Labels.COOKIE_CONSENT_WARN_TEXT));
            setSbOpen(true);
            return false;    
        } else {
            return true;
        }

    }

    const signinClickHandler = async () => {        
        if(!checkCookieConsent()){
            return;
        }

        let loadingAuth = new AuthType();
        loadingAuth.loading = true;
        setAuthData(loadingAuth);

        let captchaRes: string = "";

        let t = state?.substring(9, state.length);

        const loginData = {
            email: "",
            password: "",
            rememberMe: false,
            lang: lang,
            captchaToken: captchaRes,
            googleCode: googleCode,
            fbCode: code,
            redirectToken: t
        };

        setLoading(true);

        let token = $("meta[name='_csrf']").attr("content");
        let headerName = $("meta[name='_csrf_header']").attr("content");
        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader(headerName!, token!);
            }
        });

        $.ajax({
            url: Constants.BASE_URL + 'api/user/login',
            method: 'POST',
            data: JSON.stringify(loginData),
            processData: false,
            contentType: 'application/json'
        }).done(function (data) {
            setLoading(false);
            const r: AResponse<AcctBasicDetails> = data;
            if (r.isError) {
                setSbMsg(r.message);
                setSbOpen(true);
                if (r.intCode === 514) {
                    setFbReRequest(true);
                }
            } else {
                let authData = new AuthType();
                authData.token = r.data.token;
                authData.email = r.data.email;
                authData.thirdParty = true;
                setLang(r.data.lang);
                authData.loading = false;

                setAuthData(authData);
                history.push('/home');
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {

        });
    };

    const fbSignInClickHandler = () => {
        let token = $("meta[name='_csrf']").attr("content");
        let headerName = $("meta[name='_csrf_header']").attr("content");
        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader(headerName!, token!);
            }
        });

        $.ajax({
            url: Constants.BASE_URL + 'api/user/redirectToken/get',
            method: 'POST',
            processData: false,
            contentType: 'application/json'
        }).done(function (data) {
            const r: AResponse<string> = data;
            if (r.isError) {
                setSbMsg(r.message);
                setSbOpen(true);
            } else {
                let redirecturi = encodeURIComponent(Constants.EXTERNAL_BASE_URL);
                // let redirecturi = encodeURIComponent("http://localhost:3000/");
                let fburi = "https://www.facebook.com/v10.0/dialog/oauth?client_id=315533690084476&redirect_uri=" + redirecturi + "&state=fbsignin," + r.data + "&scope=email";
                if (fbReRequest) {
                    fburi += "&auth_type=rerequest";
                }
                window.open(fburi, "_self");
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {

        });
    };

    return (
        <Container className={classes.root}>
            <TitleBox color='black' />
            <Box className={classes.spBpx}>
                {lm.get(lang, Labels.LAND_SUBTITLE_1)}
            </Box>
            {loading && 
                <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif", marginTop:'2em' }} component="h1" variant="h5">
                    {lm.get(lang, Labels.LOGIN_SIGNING_IN)}
                </Typography>
            }
            {!loading &&
                <Box className={classes.acctButtons}>
                    <Box className={classes.btnBoxLarge}>
                        <GoogleLogin
                            clientId="746179338704-usu9fihb28n8j4pgl0g8n0ck6tar3cbb.apps.googleusercontent.com"
                            isSignedIn={true}
                            responseType='code'
                            render={renderProps => (
                                <Button
                                    className={classes.googleSignIn}
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    fullWidth
                                    variant='contained'
                                    startIcon={<img className={classes.googleLogo} src={GoogleLogo} />}

                                >
                                    Sign in with Google
                                </Button>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </Box>
                    <Box className={classes.btnBoxLarge}>
                        <Button
                            className={classes.fbSignIn}
                            fullWidth
                            variant='contained'
                            startIcon={<img className={classes.fbLogo} src="../img/facebook/f_logo_RGB-Blue_58.png" />}
                            onClick={fbSignInClickHandler}
                        >
                            Login with Facebook
                        </Button>
                    </Box>
                    <Box className={classes.btnBoxLarge}>
                        <Button
                            className={classes.whiteButton}
                            variant='contained'                            
                            fullWidth
                            onClick={() => { history.push('/sign-up'); }}
                        >
                            {lm.get(lang, Labels.LAND_CREATE_ACCT)}
                        </Button>
                    </Box>
                    <Box className={classes.btnBoxLarge}>
                        <Button
                            className={classes.whiteButton}
                            variant='contained'                            
                            fullWidth
                            onClick={() => { history.push('/sign-in'); }}
                        >
                            {lm.get(lang, Labels.LAND_SIGN_IN)}
                        </Button>
                    </Box>
                </Box>
            }
            {loading &&
                <Box className={classes.signingIn}>
                    <RingLoader color={theme.palette.primary.main}></RingLoader>
                </Box>
            }
            <Box className={classes.otherButtons}>
                <Box className={classes.btnBoxSmall}>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        fullWidth
                        onClick={() => { history.push('/faq'); }}
                    >
                        {lm.get(lang, Labels.LAND_FAQ)}
                    </Button>
                </Box>
                {/* <Box className={classes.btnBoxSmall}>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        fullWidth
                    >
                        Contact Us
                </Button>
                </Box> */}
            </Box>
            <BroSnack
                autoHideDuration={6000}
                open={sbOpen}
                message={sbMsg}
                snackType='warning'
                onSnackClose={() => { setSbOpen(false) }}
            />
            <CookieConsent
                style={{ backgroundColor:theme.palette.primary.dark }}
                buttonStyle={{ borderRadius:'.5em', backgroundColor: theme.palette.secondary.main }}
                buttonText={lm.get(lang, Labels.COOKIE_CONSENT_BUTTON)}
                >{lm.get(lang, Labels.COOKIE_CONSENT_INFO_TEXT)}</CookieConsent>
            <Box className={classes.footerBox}>
                Copyright Alien Price Tracker 2021
            </Box>
        </Container>
    )
}

export default Landing
