import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import $ from "jquery";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory, useLocation } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";
import BroSnack from '../components/BroSnack';
import Constants from '../components/Constants';
import Copyright from '../components/Copyright';
import { Labels } from '../components/enums/Labels';
import lm from '../components/LabelManager';
import TitleBox from '../components/TitleBox';
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import { langContext } from '../contexts/LangContext';
import theme from '../CTheme';
import AResponse from '../dto/AResponse';
import AcctBasicDetails from '../dto/response/AcctBasicDetails';
import RingLoader from "react-spinners/RingLoader";
import CookieConsent, {getCookieConsentValue} from 'react-cookie-consent';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  recaptchaBox: {
    marginTop: "2em"
  },
  titleBox: {
    marginBottom: '2em'
  },
  signingIn: {
    margin: '8em 0em'
  }

}));

export default function SignIn() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [remember, setRemember] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { setAuthData, auth } = useContext(authContext);
  const { setLang, lang } = useContext(langContext);
  const [sbMsg, setSbMsg] = useState<string | undefined>("");
  const [sbOpen, setSbOpen] = useState<boolean>(false);
  useEffect(() => {
    if (auth.email && auth.token && auth.email.trim().length > 0 && auth.token.trim().length > 0) {
      history.push('/home');
    }
  }, [auth, history]);

  const checkCookieConsent = useCallback(() => {
    let val = getCookieConsentValue();
    console.log(val);
    if(!val || val == "false"){
        setTimeout(() => {
          setSbMsg(lm.get(lang, Labels.COOKIE_CONSENT_WARN_TEXT));
          setSbOpen(true);
        },0);        
        return false;    
    } else {
        return true;
    }
  },[getCookieConsentValue, setSbMsg, setSbOpen])

  const signinClickHandler = async () => {
    if(!checkCookieConsent()){
      return;
    }

    let loadingAuth = new AuthType();
    loadingAuth.loading = true;
    setAuthData(loadingAuth);


    let captchaRes: string = "";
    try {      
        captchaRes = await executeRecaptcha('login');
    } catch { };

    const loginData = {
      email: email,
      password: password,
      rememberMe: remember,
      lang: lang,
      captchaToken: captchaRes,
      googleCode: "",
      fbCode: "",
      redirectToken: ""
    };

    setLoading(true);

    let token = $("meta[name='_csrf']").attr("content");
    let headerName = $("meta[name='_csrf_header']").attr("content");
    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader(headerName!, token!);
      }
    });

    $.ajax({
      url: Constants.BASE_URL + 'api/user/login',
      method: 'POST',
      data: JSON.stringify(loginData),
      processData: false,
      contentType: 'application/json'
    }).done(function (data) {
      setLoading(false);
      const r: AResponse<AcctBasicDetails> = data;
      if (r.isError) {
        setSbMsg(r.message);
        setSbOpen(true);        
      } else {
        let authData = new AuthType();
        authData.token = r.data.token;
        authData.email = r.data.email;
        setLang(r.data.lang);
        authData.loading = false;

        setAuthData(authData);
        history.push('/home');
      }
    }).fail(function (jqXHR, textStatus, errorThrown) {

    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <TitleBox className={classes.titleBox} color='black' />
        {!loading ?
          <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif" }} component="h1" variant="h5">
            {lm.get(lang, Labels.LOGIN_SIGNIN)}
          </Typography>
          :
          <Typography style={{ fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif" }} component="h1" variant="h5">
            {lm.get(lang, Labels.LOGIN_SIGNING_IN)}
          </Typography>
        }

        {!loading &&
          <div className={classes.paper}>            
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={lm.get(lang, Labels.LOGIN_EMAIL)}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={lm.get(lang, Labels.LOGIN_PASSWORD)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => {
                  setPassword(e.target.value)
                }}
                onKeyPress={e => {
                  if (e.key.toLocaleLowerCase() == "enter") {
                    signinClickHandler();
                  }
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => { setRemember(!remember) }}
                    onKeyPress={e => {
                      if (e.key.toLocaleLowerCase() == "enter") {
                        signinClickHandler();
                      }
                    }}
                    checked={remember}
                    value="remember"
                    color="primary"
                  />
                }
                label={lm.get(lang, Labels.LOGIN_REMEMBER)}
              />
              <Button
                disabled={loading}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={signinClickHandler}
              >
                {(!loading ? lm.get(lang, Labels.LOGIN_SIGNIN) : <ScaleLoader css="position:relative;top:.3em;" height={15} color={theme.palette.text.disabled} />)}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/reset-password" variant="body2">
                    {lm.get(lang, Labels.LOGIN_FORGOT_PASS)}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/sign-up" variant="body2">
                    {lm.get(lang, Labels.LOGIN_SIGNUP)}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        }

        {loading &&
          <Box className={classes.signingIn}>
            <RingLoader color={theme.palette.primary.main}></RingLoader>
          </Box>
        }
      </div>
      <BroSnack
        autoHideDuration={6000}
        open={sbOpen}
        message={sbMsg}
        snackType='warning'
        onSnackClose={() => { setSbOpen(false) }}
      />
      <Box className={classes.recaptchaBox}>
        <Typography variant='body2' color='textSecondary'>
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </Typography>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>      
      <CookieConsent
        style={{ backgroundColor:theme.palette.primary.dark }}
        buttonStyle={{ borderRadius:'.5em', backgroundColor: theme.palette.secondary.main }}
        buttonText={lm.get(lang, Labels.COOKIE_CONSENT_BUTTON)}
        >{lm.get(lang, Labels.COOKIE_CONSENT_INFO_TEXT)}</CookieConsent>
    </Container>
  );
}