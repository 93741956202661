import { Box, Button, Container, Link, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import TitleBox from '../components/TitleBox';
import { authContext } from '../contexts/AuthContext';
import { langContext } from '../contexts/LangContext';
import lm from '../components/LabelManager';
import { Labels } from '../components/enums/Labels';
import { Languages } from '../components/enums/Languages';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '.5em 0em',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        height: '100%',
        position: 'relative'
    },
    spBpx: {
        marginTop: '1em',
        marginBottom: '1em',
        textAlign: 'center',
        fontFamily: "'Comfortaa', Roboto, Helvetica, Arial, sans-serif",
        fontSize: '1.25em',
        padding: '0 1em'
    },
    descBox: {
        marginTop: '1em',
        textAlign: 'justify',
        textJustify: 'inter-word',
        padding: '0 1em',
        fontSize: '1em'
    },
    acctButtons: {
        display: 'block',
        width: '100%',
        // maxWidth: '450px',
        marginTop: '10%',
        marginBottom: '2em'
    },
    otherButtons: {
        display: 'flex',
        width: '100%',
        // maxWidth: '450px',
        justifyContent: 'center',
        marginTop: '1em'
    },
    btnBoxSmall: {
        margin: '0 1em 2em 1em',
        width: '45%',
        maxWidth: '200px'
    },
    btnBoxLarge: {
        margin: 'auto',
        maxWidth: '400px'
    },
    footerBox: {
        textAlign: 'center',
        fontSize: '.5em',
        width: '100%',
        position: 'absolute',
        bottom: '1.5em',

    }
}));

function Landing() {
    const classes = useStyles();
    const { lang } = useContext(langContext);
    const history = useHistory();

    return (
        <Container className={classes.root}>
            <TitleBox color='black' />
            <Box className={classes.spBpx}>
                {lm.get(lang, Labels.FAQ_SUBTITLE)}
            </Box>
            {lang === Languages.EN_US &&
                <Box className={classes.descBox}>
                    <Typography variant='h6'>What is Alien Price Tracker?</Typography>
                    <p>
                        APT is a tool to help you find the best deals on Amazon and helps you save money by buying when prices are low. Amazon's algorithms change the prices for a lot of products constantly, by tracking them, you can see when the best time to buy is.
                    </p>
                    <p>
                        Simply search for products you are interested in buying and start tracking them, then see how price varies through time and decide when it's the best time to buy.
                    </p>
                    <p>
                        APT is a free service supported by Amazon affiliate links, this means we receive small commissions for products purchased through our service without having any effect on the price for you. Thank you for using our website!
                    </p>
                </Box>
            }

            {lang === Languages.ES_MX &&
                <Box className={classes.descBox}>
                    <Typography variant='h6'>¿Qu&eacute; es Alien Price Tracker?</Typography>
                    <p>
                        APT es una herramienta para ayudarte a encontrar las mejores ofertas en Amazon y te ayuda a ahorrar dinero comprando cuando los precios son bajos. Los algoritmos de Amazon cambian constantemente el precio de muchos productos y al estarlos rastreando, podrás ver cuándo es el mejor momento de comprarlos.
                    </p>
                    <p>
                        Simplemente busca productos que te interese comprar y comienza a rastrearlos, después observa cómo cambia el precio a lo largo de los días y decide cuándo es el mejor momento de comprarlos.
                    </p>
                    <p>
                        APT es un servicio gratuito que se sostiene mediante las ligas de afiliado de Amazon, esto quiere decir que recibimos pequeñas comisiones cuando haces las compras a través de nuestro servicio sin esto tener efecto alguno en el precio para ti. ¡Muchas gracias por utilizar nuestro sitio!
                    </p>
                </Box>
            }

            {lang === Languages.EN_US &&
                <Box className={classes.descBox}>
                    <Typography variant='h6'>What is your privacy policy?</Typography>
                    <Link href="/privacy-policy">Click here to view our privacy policy.</Link>
                </Box>
            }

            {lang === Languages.ES_MX &&
                <Box className={classes.descBox}>
                    <Typography variant='h6'>¿Cu&aacute;l es su pol&iacute;tica de privacidad?</Typography>
                    <Link href="/privacy-policy">Clic aqu&iacute; para ver nuestra política de privacidad.</Link>
                </Box>
            }

            {lang === Languages.EN_US &&
                <Box className={classes.descBox}>
                    <Typography variant='h6'>How can I delete my account?</Typography>
                    <Link href="/how-to-delete-account">Click here to see instructions on how to delete your account.</Link>
                </Box>
            }

            {lang === Languages.ES_MX &&
                <Box className={classes.descBox}>
                    <Typography variant='h6'>¿C&oacute;mo puedo borrar mi cuenta?</Typography>
                    <Link href="/how-to-delete-account">Clic aqu&iacute; para ver instrucciones de c&oacute;mo borrar tu cuenta.</Link>
                </Box>
            }
            <Box className={classes.acctButtons}>
                <Box className={classes.btnBoxLarge}>
                    <Button
                        variant='contained'
                        color='secondary'
                        fullWidth
                        onClick={() => { history.push('/'); }}
                    >
                        {lm.get(lang, Labels.FAQ_BACK)}
                    </Button>
                </Box>
            </Box>
            <Box className={classes.footerBox}>
                Copyright Alien Price Tracker 2021
            </Box>
        </Container>
    )
}

export default Landing
