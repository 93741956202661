import { Box, Button, Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Labels } from '../components/enums/Labels'
import lm from '../components/LabelManager';
import { authContext } from '../contexts/AuthContext';
import { langContext } from '../contexts/LangContext';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,            
            border: '3px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '1em',
            paddingTop:'3em'
        },
        headerBox: {
            marginTop: '0em'
        },
        header: {
            textAlign: 'center'
        },
        alienBox:{            
            marginLeft:'auto',
            marginRight:'auto',
        },
        alien:{
            maxWidth:'7em',
            maxHeight:'7em'
        },
        buttonBox:{
            marginTop:'1em',
            textAlign:'center'
        },
        orBox:{
            marginTop:'3em',
            textAlign:'center'
        }
    }),
);

function SimpleNotice() {    
    const {lang} = useContext(langContext);
    const classes = useStyles();
    const history = useHistory();
    const search = useLocation().search;
    const n = new URLSearchParams(search).get('n');
    const [notice, setNotice] = useState<Labels>(Labels.EMPTY);

    useEffect(() => {
        switch(n){
            case "prn":
                setNotice(Labels.NOTICE_RESET_PASSWORD);
                break;
            case "vn":
                setNotice(Labels.NOTICE_VERIFY);
                break;
            default:
                setNotice(Labels.EMPTY);
                history.replace('/');
                break;
        }        
    }, [n]);        

    return (
        <Container className={classes.root}>
            <Box className={classes.alienBox}>
                <img className={classes.alien} src={'/img/aliens/150/happy4-150px.png'}></img>
            </Box>
            <Box className={classes.headerBox}>
                <Typography className={classes.header} variant='h5'>{lm.get(lang, notice)}</Typography>
            </Box>
            <Box className={classes.orBox}>
                <Typography className={classes.header} variant='h5'>{lm.get(lang, Labels.NOTICE_OR)}</Typography>
            </Box>
            <Box className={classes.buttonBox}>
                <Button variant='outlined' color='primary' onClick={() => {history.push("/")}}>{lm.get(lang, Labels.NOTICE_GO_BACK)}</Button>
            </Box>
        </Container>        
    )
}

export default SimpleNotice
