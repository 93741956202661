import { Box, createStyles, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import StoreIcon from '@material-ui/icons/Store';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { Component, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { authContext } from '../contexts/AuthContext';
import AuthType from '../contexts/AuthType';
import lm from '../components/LabelManager';
import { Labels } from './enums/Labels';
import APIRequests from './APIRequests';
import ARequest from '../dto/ARequest';
import Constants from './Constants';
import { langContext } from '../contexts/LangContext';
import TitleBox from './TitleBox';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import LockIcon from '@material-ui/icons/Lock';
import DescriptionIcon from '@material-ui/icons/Description';

interface IProps {
    open: boolean;
    toggleDrawer: (toggleVal: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        emailBox: {
            position: 'absolute',
            textAlign: 'center',
            width: '100%',
            bottom: '-1em',
        },
        email: {
            fontSize: '.7em',
            fontWeight:'bold',
            textTransform: 'uppercase',
            color:theme.palette.extra1.main
        },
        titleLi: {
            paddingTop: '0'
        },
        recaptchaBox: {
            marginTop: "2em",
            marginLeft:'1em',
            position:'absolute',
            bottom:'1em'
        },
        recaptchaText:{
            maxWidth:'22em',
            fontSize:'.7em',
            color: theme.palette.text.secondary,            
        },
        headerBox:{
            textAlign:'center'
        },
        titleBox:{
            marginTop:'.5em',
            maxWidth:'12em',
            margin:'auto'
        },
        headerList:{
            position:'absolute',
            width:'100%'
        },
        menuList:{
            marginTop:'10rem',
            minWidth:'15rem'
        }
    }),
);

const Menu = ({ open, toggleDrawer }: IProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { setAuthData, auth } = useContext(authContext);
    const { lang } = useContext(langContext);

    const logout = () => {
        const req = new ARequest<string>(auth.token);
        req.data = "";
        APIRequests.post(Constants.BASE_URL + 'api/user/logout', req, () => { });
        setAuthData(new AuthType(false));
        Constants.clearCaches();
        history.push("/");
    }

    const menuClickHandler = (ix: number) => () => {
        switch (ix) {
            case 0: history.push("/my-stores"); break;
            case 1: history.push("/my-products"); break;
            case 2: history.push("/settings"); break;
            case 3: history.push("/my-products?a=search"); break;
            case 4: logout(); break;
            case 5: history.push("/my-account"); break;
            case 6: history.push("/about"); break;
            case 7: history.push("/help"); break;
            case 8: history.push("/privacy-policy"); break;
            case 9: history.push("/terms-of-use"); break;
            default: break;
        }
    };
    return (
        <Drawer style={{position:'relative'}} anchor='left' open={open} onClose={toggleDrawer(false)}>
            <List className={classes.headerList}>
                <ListItem className={classes.titleLi} key="title">
                    <ListItemText primary={
                        <Box className={classes.headerBox}>
                            <TitleBox fullWidth logoSize='small' className={classes.titleBox} color='black'/>
                            <Typography className={classes.email}>{auth.email}</Typography>
                        </Box>
                    } />
                </ListItem>
            </List>
            {/* <Divider /> */}
            <List className={classes.menuList}>
                <ListItem button key="search" onClick={menuClickHandler(3)}>
                    <ListItemIcon><SearchIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_SEARCH)} />
                </ListItem>
                <ListItem button key="products" onClick={menuClickHandler(1)}>
                    <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_MY_PRODUCTS)} />
                </ListItem>
                {/* <ListItem button key="stores" onClick={menuClickHandler(0)}>
                    <ListItemIcon><StoreIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_MY_STORES)} />
                </ListItem> */}
                <ListItem button key="settings" onClick={menuClickHandler(2)}>
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_NOTIF_SETTS)} />
                </ListItem>
                <ListItem button key="myAccount" onClick={menuClickHandler(5)}>
                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_MYACCOUNT)} />
                </ListItem>                                
                <ListItem button key="logout" onClick={menuClickHandler(4)}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_LOGOUT)} />
                </ListItem>

                <Divider></Divider>
                
                <ListItem button key="help" onClick={menuClickHandler(7)}>
                    <ListItemIcon><HelpIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_HELP)} />
                </ListItem>
                <ListItem button key="about" onClick={menuClickHandler(6)}>
                    <ListItemIcon><InfoIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_ABOUT)} />
                </ListItem>
                <ListItem button key="privacy" onClick={menuClickHandler(9)}>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_TERMS)} />
                </ListItem>
                <ListItem button key="privacy" onClick={menuClickHandler(8)}>
                    <ListItemIcon><LockIcon /></ListItemIcon>
                    <ListItemText primary={lm.get(lang, Labels.MENU_PRIVACY)} />
                </ListItem>
            </List>
            <Box className={classes.recaptchaBox}>
                <Typography className={classes.recaptchaText}>
                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </Typography>
            </Box>
        </Drawer>
    );
}

export default Menu;