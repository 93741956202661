import { keys } from "@material-ui/core/styles/createBreakpoints";
export default class Constants {
    // static BASE_URL:string = 'http://192.168.15.25:8080/';
    // static BASE_URL: string = 'http://localhost:8080/';
    static BASE_URL:string = '/';
    static EXTERNAL_BASE_URL:string = 'https://alienpricetracker.com/';
    static UI_VERSION: number = 48;

    static MY_PRODS_CACHE = "7382ab20ebcc4bf984b55fc067c9b5d7";
    static HOME_NOTIFS_CACHE = "1a7ba401ef704559b672a4bdcfbc2d1e";
    static TKN_CACHE = "30eb833815f94ad596820d533c1f7508";
    static SEARCH_CACHE = "d1f7e34486c54e1da81510f9fcc1e717";
    static LANGUAGE_CACHE = "ed5af4e83cae4e658d724855158673c3";    
    static DB_NAME = "3e9e0ed93116490cadaaf170a6d854e2";
    static EK = "fe543ec174de44dabd29580fa3df45d3";        

    static clearCaches = () => {                
        localStorage.removeItem(Constants.TKN_CACHE);
        localStorage.removeItem(Constants.SEARCH_CACHE);
        localStorage.removeItem(Constants.LANGUAGE_CACHE);
        localStorage.removeItem(Constants.EK);
    };
}